import React from "react";

const ColissimoSvgUnicolor = (height: number, color:string) => {

  return (
    <svg viewBox="0 0 688 176" style={{height: height}}>
      <path style={{fill: color}} d="M104.7,78.4c-0.8,0.4-1.7,0.4-2.5,0l-4-2.4c-0.4-0.2-0.7-0.6-0.9-0.9V75c-0.3-0.4-0.4-0.8-0.4-1.3l-0.1-4.8
	c0-0.9,0.5-1.7,1.2-2.2l49.1-28.6L78.2,0.6C77.3,0.2,76.4,0,75.4,0s-1.9,0.2-2.7,0.6L3.8,38.2L81,83.1c0.7,0.4,1.3,1.3,1.3,2.2v65.5
	c0,0.9-0.5,1.7-1.3,2.2l-4.2,2.3c-0.4,0.2-0.8,0.3-1.2,0.3h-0.1c-0.5,0-0.9-0.1-1.3-0.3L70,153c-0.8-0.5-1.2-1.3-1.3-2.2V92.3
	c-0.1-0.5-0.3-0.9-0.7-1.1L0,51.6v77.5c0,1.7,1.2,3.9,2.7,4.7l70,41.3c0.8,0.4,1.8,0.7,2.7,0.7c0.9,0,1.9-0.2,2.7-0.7l70-41.3
	c1.6-1.1,2.6-2.8,2.7-4.7V51.6L104.7,78.4z"/>
      <path style={{fill: color}} d="M327,38.7v68c0,18.5,10.4,24.6,26.5,24.7v-15.2h-0.1c-6.2-0.5-9.4-4.1-9.4-10.6V38.7L327,38.7z M369.9,42
	c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5S374.6,42,369.9,42 M501.8,42c-4.7,0-8.5,3.8-8.5,8.5s3.8,8.5,8.5,8.5
	s8.5-3.8,8.5-8.5S506.5,42,501.8,42 M412.1,65.3c-13.6,0-24.1,6.4-24.1,20.3c0,11.5,6,17.1,20.3,19.7c7,1.4,9.6,3,9.6,6.8
	c0,4-3.5,5.5-8.8,5.5c-6.2,0-14.2-2.7-20.1-7.1v15.7c5.1,3.3,12.9,5.1,20.5,5.1c14.7,0,25.1-6.2,25.1-20c0-11.3-5.5-17.6-21.8-20.6
	c-6-1.4-8.2-3.1-8.2-6.4c0-3,2.4-5.4,8.1-5.4c6.5,0,12,2.1,18,6.1V70C425.1,66.9,419.6,65.3,412.1,65.3 M463.4,65.3
	c-13.6,0-24.1,6.4-24.1,20.3c0,11.5,6,17.1,20.3,19.7c7,1.4,9.6,3,9.6,6.8c0,4-3.5,5.5-8.8,5.5c-6.2,0-14.2-2.7-20.1-7.1v15.7
	c5.1,3.3,12.9,5.1,20.5,5.1c14.7,0,25.1-6.2,25.1-20c0-11.3-5.5-17.6-21.8-20.6c-6-1.4-8.2-3.1-8.2-6.4c0-3,2.4-5.4,8.1-5.4
	c6.5,0,12,2.1,18,6.1V70C476.4,66.9,470.9,65.3,463.4,65.3 M231.3,66C212,66,198,79.2,198,99.2c0,19.8,13,32.9,33.5,32.9
	c6.5,0,12.8-1.3,18.4-4.7v-16c-4.8,3.7-10.1,5.7-16,5.7c-11.5,0-18.7-7.4-18.7-18.2c0-11.3,7.5-17.9,18-17.9
	c5.8,0,10.9,1.6,16.2,5.8V70.7C243.9,67.5,238.6,66,231.3,66 M287.4,66c-18.9,0-33,13.3-33,33s14.2,33,33,33
	c18.9,0,33.2-13.3,33.2-33S306.2,66,287.4,66 M654.6,66c-18.9,0-33,13.3-33,33s14.2,33,33,33c18.9,0,33.2-13.3,33.2-33
	S673.4,66,654.6,66 M551.3,66.1c-9.4,0-19.7,2.1-28.5,5.5v59.1h17v-48c3.4-1,6.5-1.6,10.6-1.6c6.5,0,9.6,3.4,9.6,10.1v39.5h17.1V90
	c0-3-0.7-5.1-1.3-6.5c2.8-1.4,7.4-2.4,11.8-2.4c6.7,0,9.6,3.4,9.6,10.1v39.5h17.1V90.5c0-18.6-9.5-24.4-24.5-24.4
	c-9.2,0-15.7,2.4-21.4,5C564.8,67.9,558.6,66.1,551.3,66.1 M361.4,67.3v63.4h17.1V67.3H361.4z M493.3,67.3v63.4h17.1V67.3H493.3z
	 M287.4,80.8c9.2,0,15.9,7,15.9,18.3s-6.7,18.3-15.9,18.3s-15.9-7-15.9-18.3C271.5,87.7,278.2,80.8,287.4,80.8 M654.6,80.8
	c9.2,0,15.9,7,15.9,18.3s-6.7,18.3-15.9,18.3s-15.9-7-15.9-18.3C638.7,87.7,645.4,80.8,654.6,80.8"/>
    </svg>
  );
}


export default ColissimoSvgUnicolor;

