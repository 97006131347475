import React, {useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormControl, FormHelperText, InputAdornment, OutlinedInput} from "@mui/material";
import {
  markingsHistorySelector,
  productionStateSelector,
  searchStockRef,
  SearchStockRefSelector,
  selectOrder
} from "./productionSlice";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import {useAppSelector} from "../../../app/hooks";

export function SearchStockProduct() {

  const searchedRef = useAppSelector(SearchStockRefSelector)
  const dispatch = useDispatch();
  const searchProductRef = useRef();

  const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const search = event.target.value;

    // console.log("search: " + search);

    if (search !== null && (search.length >= 3 || search.length === 0)) {
      dispatch(searchStockRef(search))
    }
  };

  const handleClearSearch = (e:any) => {
    if (searchProductRef && searchProductRef.current) {
      const input: HTMLInputElement = searchProductRef.current as HTMLInputElement;
      input.value = ''
    }

    dispatch(searchStockRef(''))
  }

  return (
    <FormControl sx={{ ml: 1, mr: 1, display: 'flex' }} size="small">
      <OutlinedInput size="small" sx={{width: '200px'}}
                     autoFocus defaultValue={searchedRef}
                 onChange={handleSearch}
                 inputRef={searchProductRef}
                 endAdornment={
                   <InputAdornment position="end">
                     <IconButton onClick={handleClearSearch} edge="end">
                       <CloseIcon fontSize='small' />
                     </IconButton>
                   </InputAdornment>
                 } />
    </FormControl>
  )
}
