import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {Button, CircularProgress, FormControl, FormHelperText} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {addStockForVariant, FoundProductsSelector, ProductsLoadingSelector, savingSelector} from "./productionSlice";

export interface IFoundProduct {
  id: number;
  sku: string;
  title: string;
  colors: IFoundProductColor[];
}

export interface IFoundProductColor {
  key: string;
  color: string;
  sizes: IFoundProductColorSize[];
}

export interface IFoundProductColorSize {
  id: number;
  size: string;
}

export function SelectStockVariant() {

  const productsLoading = useSelector(ProductsLoadingSelector);
  const foundProducts = useSelector(FoundProductsSelector);
  const dispatch = useDispatch();
  const [selectedProductId, setSelectedProductId] = useState(0)

  const [selectedProductColors, setSelectedProductColors] = useState<IFoundProductColor[]>([])
  const [selectedColor, setSelectedColor] = useState('')

  const [selectedProductSizes, setSelectedProductSizes] = useState<IFoundProductColorSize[]>([])
  const [selectedSize, setSelectedSize] = useState(0)

  // No need to display the select if there is only one workshop
  // if (!productsLoading && foundProducts.length === 0) return (null);

  const handleSelectProduct = (event: SelectChangeEvent) => {
    const productId = parseInt(event.target.value)
    setSelectedProductId(productId)
    setSelectedProductColors(foundProducts.find(p => p.id === productId)?.colors || [])
    setSelectedColor('')
    setSelectedProductSizes([])
    setSelectedSize(0)
  };

  const handleSelectColor = (event: SelectChangeEvent) => {
    setSelectedColor(event.target.value)
    setSelectedProductSizes(selectedProductColors.find(c => c.key === event.target.value)?.sizes || [])
    setSelectedSize(0)
  };

  const handleSelectSize = (event: SelectChangeEvent) => {
    const variantId = parseInt(event.target.value)
    setSelectedSize(variantId)
  };

  return (
    <>
    {productsLoading && <CircularProgress color="inherit" sx={{ml:4}} size={40}/> ||
    <>
    {foundProducts && foundProducts.length > 0 && <>
      <FormControl sx={{ mr: 1, minWidth: 150 }} size="small" className='select-tool search-workshop'>
        <Select value={selectedProductId.toString()} onChange={handleSelectProduct}>
          {foundProducts.map((product, i) => (
            <MenuItem key={`sel-fnd-product${i+1}`} value={product.id}>{product.sku} - {product.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedProductColors.length > 0 &&
        <FormControl sx={{ mr: 1, minWidth: 100 }} size="small" className='select-tool search-workshop'>
          <Select value={selectedColor || ''} onChange={handleSelectColor}>
            {selectedProductColors.map((color, i) => (
              <MenuItem key={`sel-fnd-${color.key}`} value={color.key}>{color.color}</MenuItem>
            ))}
          </Select>
        </FormControl>
      }
      {selectedProductSizes.length > 0 &&
          <FormControl sx={{ mr: 1, minWidth: 50 }} size="small" className='select-tool search-workshop'>
            <Select value={selectedSize.toString()} onChange={handleSelectSize}>
              {selectedProductSizes.map((size, i) => (
                <MenuItem key={`sel-size-${size.id}`} value={size.id}>{size.size}</MenuItem>
              ))}
            </Select>
          </FormControl>
      }
      {selectedSize > 0 &&
        <Button variant="contained" color="success" sx={{ height: 42 }}
                onClick={() => { dispatch(addStockForVariant(selectedSize))}} >
          Ajouter
        </Button>
      }
      </>}
    </>
    }
  </>)
}
