import React from 'react';
import {logosSelector} from "../logos/logosSlice";
import ErrorIcon from '@mui/icons-material/Error';
import {useSelector} from "react-redux";
import {useDroppable} from "@dnd-kit/core";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import {CustomButton} from "../utils/CustomButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import {FormattedMessage} from "react-intl";

export const BoutiqueLogo = (props: {
  logoId: Number | null,
  editMode: boolean,
}) => {

  const {logoId, editMode} = props;

  const logos = useSelector(logosSelector);
  const logo = logoId && logos && logos.length > 0 ? logos.find((l) => l.id === logoId) : null;

  const logoError = editMode && !logo;

  const {isOver: isOverLogo, setNodeRef: setNodeRefDropLogo} = useDroppable({
    id: 'boutique-logo',
    data: {
      accepts: 'logo'
    }
  });

  const styleDropLogo = {
    border: isOverLogo ? '1px #80A080 solid' : logoError ? '2px #FF0000 solid' : '',
  };

  return (
    <div className={`logo ${logo && logo.forDarkBg >= 1 ? 'dark-bg' : ''}`}
         ref={setNodeRefDropLogo} style={styleDropLogo}>
      {logo && logo.logoUrl !== null && logo.logoUrl !== '' &&
        <img src={logo.logoUrl} alt={logo.name} width='64'/>
      }

      {logoError &&
        <BoudaToolTip title={<FormattedMessage id={"boutique.need-logo"} />}>
          <div className="no-logo">
            <div className="icon">
              <ErrorIcon sx={{color: 'red'}}/>
            </div>
          </div>
        </BoudaToolTip>
      }
    </div>
  );

}

export default BoutiqueLogo;