import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import {useEffect, useState} from "react";

interface DatepickerProperties {
	onChange: (value: Dayjs | null) => void;
	defaultValue: Dayjs | null;
	disableFuture?: boolean;
	minValue?: Dayjs;
	locale?: 'fr' | 'en';
}

export const CustomDatepicker = (
	{
		onChange,
		defaultValue,
		disableFuture = false,
		minValue,
		locale = 'fr'
}: DatepickerProperties) => {

	const [cleared, setCleared] = useState<boolean>(false);

	useEffect(() => {
		if (cleared) {
			const timeout = setTimeout(() => {
				setCleared(false);
			}, 1500);

			return () => clearTimeout(timeout);
		}
		return () => {};
	}, [cleared]);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
			<DatePicker className='custom-date-picker'
									slotProps={{
										field: { clearable: true, onClear: () => setCleared(true) },
										textField: {
											size: "small",
											error: false,
										},
									}}

									disableFuture={disableFuture}
									defaultValue={defaultValue}
									onChange={onChange}
									minDate={minValue}
			/>
		</LocalizationProvider>
	);
}