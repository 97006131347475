import {BoudaToolTip} from "../utils/BoudaToolTip";
import ColorPicker from "../utils/ColorPicker";
import {useState} from "react";

const LIGHT_COLORS = ['white', 'grey', 'yellow', 'pink', 'orange'];

function removeColorTag(color_range:string | undefined) {
  return(
    <span className={`remove ${LIGHT_COLORS.includes(color_range || '') ? 'white-bg' : ''}`}>&times;</span>
  )
}

interface editedColor {
  colorName: string,
  colorValue: string,
}

export const ColorTag = (props: {
  color_range?:string,
  color1: string | undefined,
  color2: string | undefined,
  friendlyColor?: string,
  size: string,
  active?: boolean,
  removeable?: boolean,
  defineHexColors?: (color1: string, color2: string) => void,
  name?: string}
) => {

  const {color_range, color1, color2, friendlyColor, size, active, removeable, defineHexColors,name} = props;

  const [editedColor, setEditedColor] = useState<editedColor>({colorName: '', colorValue: ''});

  
  const editable = defineHexColors !== undefined;

  const colorTagDiv = () => {
    if (!color1) {
      return(
        <div className={`puce ${size} ${active ? 'active' : ''}`} style={{background: 'white'}}>
          <>
            {
              removeable && removeColorTag(color_range)
            }
          </>
        </div>
      )
    } else {

      const gradient_color2 = !color2 ? color1 : color2;
      const gradient = `linear-gradient( -45deg, ${gradient_color2}, ${gradient_color2} 38%, ${color1} 38% )`

      return (
        // this div is needed to have the tooltip on the whole color tag
        <div>
          <div className={`color-tag ${size}`}>
            <div className={`puce ${size} ${active ? 'active' : ''}`}
                 style={{background: gradient}} title={friendlyColor}>
              <>
                {
                  removeable && removeColorTag(color_range)
                }
              </>
            </div>
            {editable && <div className="color-top" onClick={() => {
              setEditedColor({colorName: 'color1', colorValue: color1 || ''})
            }} >
            </div>}
            {editable && color2 && <div className="color-bottom" onClick={() => {
              setEditedColor({colorName: 'color2', colorValue: color2 || ''})
            }} >
            </div>}

          </div>

          {editable && editedColor.colorName !== '' &&
            // in this case a new color picker is created each time
		        <ColorPicker propertyName={editedColor.colorName} displayColor={false} whiteAllowed hideBrush
                         color={editedColor.colorValue} setColor={setColorHex} opened={true} />
	        }

        </div>
    )

    }
  }

  const setColorHex = (colorCode: string, hexColor: string) => {
    setEditedColor({colorName: '', colorValue: ''})
    if (defineHexColors) {
      if (colorCode === 'color1') {
        defineHexColors(hexColor, color2 || '');
      } else {
        defineHexColors(color1 || '', hexColor);
      }
    }
  }

  if (name === undefined) {
    return colorTagDiv();
  } else {
    return(
      <BoudaToolTip placement="top" title={name} arrow disableInteractive={true} sx={{textAlign: 'center'}}>
        {colorTagDiv()}
      </BoudaToolTip>
    )
  }
}

export default ColorTag;