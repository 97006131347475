import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
  filterShippedMarkingsSelector,
  FilterStockNoBoxSelector,
  MARKINGS_TYPES,
  markingsTypeSelector,
  selectMarkingsProductionDay,
  selectMarkingType,
  toggleFilterShippedMarkings,
  toggleFilterStockNoBox,
} from "./productionSlice";
import {useAppSelector} from "../../../app/hooks";
import TextField from "@mui/material/TextField";
import {RootState} from "../../../app/store";

export function FiltertStockArticles() {

  const currentUser = useSelector((state : RootState) => state.session.currentUser);

  const dispatch = useDispatch();
  const filterStockNoBox = useAppSelector(FilterStockNoBoxSelector);

  const changeFilter = (event: any) => {
    dispatch(toggleFilterStockNoBox())
  };

  return (
    <FormControl sx={{ mr: 1}} size="small" className='select-tool search-marking'>
      <FormControlLabel control={
        <Checkbox checked={filterStockNoBox} onChange={changeFilter}
                  inputProps={{ 'aria-label': 'controlled' }} />
      } label="Non rangés" />
    </FormControl>
  )

}
