import React, {useState, useEffect } from 'react';
import {Grid, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {
  currentWorkshopSelector,
  IDeliveryItem,
  IWorkshopBox,
  PRODUCTION_IN_BOX,
  productionOrdersSelector
} from "./productionSlice";
import {openInFullScreen} from "../../global/globalSlice";
import {AppDispatch} from "../../../app/store";

function WorkshopBoxes() {

  const dispatch = useDispatch<AppDispatch>();
  const currentWorkshop = useSelector(currentWorkshopSelector);
  const productionOrders = useSelector(productionOrdersSelector);

  const openWorkshopBox = (boxId: number) => {
    dispatch(openInFullScreen({name: 'WorkshopBoxDetails', displayedId: boxId}))
  }

  const itemPhoto = (item: IDeliveryItem) => (
    <Box className='photo'>
      <img src={item.photo} />
    </Box>
  );

  const workshopBoxCard = (box: IWorkshopBox) => {

    const orderNumber = (box.items && box.items[0]) ? box.items[0].orderNumber : null;
    const order = orderNumber ? productionOrders?.find((o) => o.orderNumber === orderNumber) : null;
    const orderNbItems = order?.items.reduce((nb, item) =>
      nb + item.singleItems.length, 0) || 0;
    const orderMissingItems = order?.items.reduce((nb, item) =>
      nb + item.singleItems.filter((si) => si.orderStatus < PRODUCTION_IN_BOX).length, 0) || 0;

    const highPriority = box.items?.some((item) => item.priority !== 0);

    return(
      <Box className={`workshop-box ${orderNumber ? 'used' : ''} ${box.complete ? 'complete' : ''} ${highPriority ? 'priority' : ''}`}
           onClick={() => openWorkshopBox(box.id)}>
        <Box className='box-header'>
          <Box className='box-name'>
            {box.name}
          </Box>
          <Box className='box-order' onClick={(e) => {
            e.stopPropagation();
            dispatch(openInFullScreen({name: 'ProductionOrder', displayedId: order?.orderNumber, param: 'fromWorkshopBox'}))
          }}>
            {order && <Box className='nb-items'>
              ({orderNbItems}{orderMissingItems > 0 && <span className='missing-items'>-{orderMissingItems}</span>})
            </Box>}
            {orderNumber}
          </Box>
        </Box>
        <Box className='box-items'>
          {box.items?.map((item) => itemPhoto(item))}
        </Box>
        <Box className='box-qr-code'>
          <img src={box.qrCode} />
        </Box>
      </Box>
    )
  }

  document.title = "Bacs de production";

  return (
    <Box className='workshop-boxes'>
      <Grid container spacing={2}>
        {currentWorkshop?.boxes //.slice(79,-1)
          // .filter((b) => b.items.length > 0)
          .map((workshopBox) =>
            <Grid item xs={12} sm={6} md={4} lg={3} key={`box-${workshopBox.id}`} className='box-container'>
              {workshopBoxCard(workshopBox)}
            </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default WorkshopBoxes;