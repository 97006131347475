import React, {useEffect} from 'react';
import Box from "@mui/material/Box";
import {
  currentWorkshopSelector,
  dateRangeSelector,
  getMarkingSeries,
  markingSeriesLoadedSelector,
  markingSeriesSelector,
  nameForMarkingTechnique,
  shippingsSelector,

} from "./productionSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../../app/store";
import {CircularProgress} from "@mui/material";
import {frFR, GridColDef, GridToolbarContainer, GridToolbarExport, GridRowId, DataGrid} from "@mui/x-data-grid";
import {PriceFormatted} from "../../../app/product";
import {StripedDataGrid} from "../../utils/StrippedDataGrid";
import dayjs from "dayjs";

export function ProductionReporting() {

  const dispatch = useDispatch<AppDispatch>();
  const currentWorkshop = useSelector(currentWorkshopSelector);
  const markingSeriesLoaded = useSelector(markingSeriesLoadedSelector);
  const markingSeries = useSelector(markingSeriesSelector);
  const shippings = useSelector(shippingsSelector);
  const dateRange = useSelector(dateRangeSelector);

  const dateRangeCSV = dateRange && dateRange.startDate && dateRange.endDate ?
    `${dateRange?.startDate?.substring(0, 10)} au ${dateRange?.endDate?.substring(0, 10)}` : '';

  useEffect(() => {
    if (currentWorkshop && !markingSeriesLoaded) {
      console.log('%cgetMarkings', 'color: red')
      dispatch(getMarkingSeries())
    }

  }, [dispatch, currentWorkshop, markingSeriesLoaded]);

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: 'Code',
      headerClassName: 'catalog-header',
      headerAlign: 'center',
      align: 'center',
      // width: 100,
      flex: 1,
      sortable: true,
    },
    {
      field: 'marquage',
      headerName: 'Marquage',
      headerClassName: 'catalog-header',
      // width: 150,
      flex: 2,
      sortable: true,
    },
    {
      field: 'quantity',
      headerName: 'Quantité',
      headerClassName: 'catalog-header',
      headerAlign: 'center',
      align: 'center',
      // width: 100,
      flex: 1,
      sortable: false,
    },
    {
      field: 'price',
      headerName: 'Prix',
      headerClassName: 'catalog-header',
      headerAlign: 'center',
      align: 'center',
      // width: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) =>
        params.value === 0 ? '' : PriceFormatted(params.value * 100),
    },
    {
      field: 'total',
      headerName: 'Total',
      headerClassName: 'catalog-header',
      headerAlign: 'center',
      align: 'center',
      // width: 100,
      flex: 2,
      sortable: false,
      disableExport: true
    }

  ];


  const rows = () => {

    const allRows = markingSeries?.map((serie, index) => {
      const quantity = serie.items.reduce((total, item) => total + item.quantity, 0);
      return(
        {
          id: `M${index}`,
          code: serie.code,
          marquage: nameForMarkingTechnique(serie.technique),
          quantity: quantity,
          price: serie.price / 100,
          total: PriceFormatted(serie.price * quantity),
          // items: serie.items,
        })
    }) || [];

    // add a row for the total
    const totalNumber = allRows.reduce((total, row) => total + row.quantity, 0);
    const totalPrice = allRows.reduce((total, row) =>
      total + row.quantity * row.price * 100, 0);

    allRows.push({
      id: 'N',
      code: '',
      marquage: 'NOMBRE DE MARQUAGES',
      quantity: totalNumber,
      price: 0,
      total: PriceFormatted(totalPrice),
      // items: '',
    })

    allRows.push({
      id: `S-separator`,
      code: '---',
      marquage: '-------------------',
      quantity: 0,
      price: 0,
      total: '---',
    })


    shippings?.forEach((shipping, index) => {
      allRows.push({
        id: `S${index}`,
        code: shipping.orderNumber.toString(),
        marquage: dayjs(shipping.shippingDate, { utc: true}).format("DD/MM/YYYY") + ' - TN: ' + shipping.trackingNumber,
        quantity: 1,
        price: shipping.price / 100,
        total: PriceFormatted(shipping.price),
      })
    })

    return allRows;
  }

  // const columnsForCSV: GridColDef[] = [
  //   {
  //     field: 'client',
  //     headerName: 'CodeClient',
  //   },
  //   {
  //     field: 'reference',
  //     headerName: 'Reference',
  //   },
  //   {
  //     field: 'comment',
  //     headerName: 'N° Cde client',
  //   },
  //   {
  //     field: 'range',
  //     headerName: 'Dateinfolibre',
  //   },
  //   {
  //     field: 'expedition',
  //     headerName: 'Modeexpedition',
  //   },
  //   {
  //     field: 'code',
  //     headerName: 'CodeArticle',
  //   },
  //   {
  //     field: 'quantity',
  //     headerName: 'Quantite',
  //   },
  //   {
  //     field: 'price',
  //     headerName: 'Prix',
  //   },
  // ];

  // const rowsForCSV = () => markingSeries?.map((serie, index) => {
  //     const quantity = serie.items.reduce((total, item) => total + item.quantity, 0);
  //     return(
  //       {
  //         id: `C${index}`,
  //         client: 'DAGOBA',
  //         reference: serie.comment,
  //         comment: serie.comment,
  //         range: dateRangeCSV,
  //         expedition: 'COLISSIMO',
  //         code: serie.code,
  //         quantity: quantity,
  //         price: serie.price / 100
  //       })
  //   })
  //
  // function CustomToolbar() {
  //   return (
  //     <GridToolbarContainer>
  //       <GridToolbarExport />
  //     </GridToolbarContainer>
  //   );
  // }

  let docTitle = 'Production';
  if (dateRange?.startDate) {
    docTitle += `-du-${dateRange?.startDate.substring(0, 10)}`;
  }
  if (dateRange?.endDate) {
    docTitle += `-au-${dateRange?.endDate.substring(0, 10)}`;
  }

  // // const ts = Time.now.strftime('%Y%m%d%H%M%S')
  // const ts = dayjs().format('YYMMDDHHmmss')
  // const csv_file = `IN_PROD${ts}`
  //
  document.title = docTitle;


  return(
    <Box className='production-marking-series'>
      {markingSeriesLoaded && <>
        {/*<ProductionExports />*/}
        {/*{dateRangeCSV && <DataGrid className='csv-data-grid'*/}
        {/*                           rows={rowsForCSV()} columns={columnsForCSV} loading={false}*/}
        {/*                           slots={{toolbar: CustomToolbar}}*/}
        {/*                           localeText={frFR.components.MuiDataGrid.defaultProps.localeText}*/}
        {/*/>}*/}
        <Box
          sx={{
            display: 'flex', flexDirection: 'column', pl: 0, pr:0, m:0,
            height: 'calc(100vh - 200px)',
            overflowY: 'auto',
            width: '100%',
            '& .catalog-header': {
              backgroundColor: '#808080',
              color: 'white',
              fontWeight: 'bold',
              border: '1px solid #FFFFFF',
            },
          }}>
          <StripedDataGrid
            rows={rows()} columns={columns} loading={false} density='compact'
            disableRowSelectionOnClick disableColumnFilter disableColumnMenu
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
          />
        </Box>

      </> ||
      <Box className='loading-production'>
        <Box>
          Récupération des marquages réalisés ...
        </Box>
        <CircularProgress color="inherit" size={100} />
      </Box>
      }
    </Box>
  )
}
