import React, {useState, useEffect } from 'react';
import {Button, Grid, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {
  currentWorkshopBoxesSelector,
  currentWorkshopSelector, DeliveryItemsFromProductionItem,
  IDeliveryItem,
  IWorkshopBox,
  PRODUCTION_IN_BOX, productionOrdersSelector, savingSelector, setItemsProductionStatus
} from "./productionSlice";
import {closeFullScreen, openConfirmDialog, openInFullScreen} from "../../global/globalSlice";
import {AppDispatch} from "../../../app/store";
import Typography from "@mui/material/Typography";

function WorkshopBoxDetails(props: {
  workshopBoxId?: number,
  box?: IWorkshopBox
}) {

  const {workshopBoxId} = props;
  const dispatch = useDispatch<AppDispatch>();

  const currentWorkshop = useSelector(currentWorkshopSelector);
  const workShopBoxes = useSelector(currentWorkshopBoxesSelector);
  const productionOrders = useSelector(productionOrdersSelector);
  const saving = useSelector(savingSelector);

  const box = props.box || workShopBoxes?.find((box) => box.id === workshopBoxId);

  const itemPhoto = (item: IDeliveryItem, present:boolean) => (
    <Box className={`photo ${present ? '' : 'missing'}`} onClick={() =>
      dispatch(openInFullScreen({name: 'ProductionItem', displayedId: item.prodItemId, param: 'fromWorkshopBox'}))
    }>
      <img src={item.photo} />
    </Box>
  );

  const orderNumber = (box && box.items && box.items[0]) ? box.items[0].orderNumber : null;
  // console.log("%cFrom box items" + box?.items?.length + " => orderNumber = " + orderNumber, "color: orange")

  const order = orderNumber ? productionOrders?.find((o) =>
    o.orderNumber === orderNumber) : null;

  // console.log("%cFrom orderNumber, order = " + JSON.stringify(order), 'color: red');

  // const nbTotalItems = order?.items.reduce((nb, item) =>
  //   nb + item.singleItems.length, 0) || 0;
  // const orderMissingItems = order?.items.reduce((nb, item) =>
  //   nb + item.singleItems.filter((si) => si.orderStatus < PRODUCTION_IN_BOX).length, 0) || 0;

  const itemsInBox = currentWorkshop?.prodItems?.
  filter((productionItem) => productionItem.orderNumber === orderNumber)
    .flatMap((item) => DeliveryItemsFromProductionItem(item))
    .filter((item) => item.workshopBoxId && item.workshopBoxId === box?.id) || []
  const nbItemsInBox = itemsInBox.length || 0;

  const missingItems = currentWorkshop?.prodItems?.
    filter((productionItem) => productionItem.orderNumber === orderNumber)
    .flatMap((item) => DeliveryItemsFromProductionItem(item))
    .filter((item) => item.orderStatus < PRODUCTION_IN_BOX) || []
  const nbMissingItems = missingItems.length || 0;

  let productionPrompt = ''
  let confirmPrompt = ''

  switch (nbItemsInBox) {
    case 0:
      break;

    case 1:
      productionPrompt = "Passer l'article au statut Production"
      confirmPrompt = "Confirmez-vous le passage de l'article déjà dans le bac au statut Production ?"
      break;

    default:
      productionPrompt = `Passer les ${nbItemsInBox} articles au statut Production`
      confirmPrompt = `Confirmez-vous le passage de ${nbItemsInBox} articles déjà dans le bac au statut Production ?`
  }

  let warningPrompt = ''

  switch (nbMissingItems) {
    case 0:
      break;

    case 1:
      warningPrompt = "Attention il manque 1 article à cette commande."
      break;

    default:
      warningPrompt = `Attention il manque ${nbMissingItems} articles à cette commande`
  }

  // const fullPrompt = [warningPrompt, confirmPrompt].filter((o) => o).join(' ')
  // const moveToProduction = () => {
  //   const updateStatus = {
  //     newOrderStatus: PRODUCTION_ONGOING,
  //     newWorkshopBoxId: -1,
  //     prodItemIds: box?.items?.map((item) => item.prodItemId) || []
  //   }
  //   dispatch(setItemsProductionStatus(updateStatus));
  // }
  //

  document.title = "Bac de production " + box?.name;

  // console.log("Rendering WorkshopBoxDetails with order = " + JSON.stringify(order));
  return (
    <Box className='box-details'>
      {box && <>
        <Box className='box-header'>
          <Box className='box-name'>
            {box.name}
          </Box>
          {order && <Box className='box-count'>
            {nbItemsInBox}{nbMissingItems > 0 && <span className='missing-items'>-{nbMissingItems}</span>}
          </Box>}
          <Box className='box-order' onClick={(e) => {
             dispatch(openInFullScreen({name: 'ProductionOrder', displayedId: order?.orderNumber, param: 'fromWorkshopBox'}))
          }}>
            {orderNumber}
          </Box>
        </Box>
        {/*{false && nbItemsInBox > 0 && <Box className='workshop-box-actions'>*/}
        {/*  <Button variant="contained" color="warning" size='large' disabled={saving}*/}
        {/*          onClick={() => {*/}
        {/*            dispatch(openConfirmDialog({*/}
        {/*              title: "Passage en Production !",*/}
        {/*              message: fullPrompt,*/}
        {/*              confirm: "Oui, passer en Prod !",*/}
        {/*              confirmColor: 'warning',*/}
        {/*              action: moveToProduction*/}
        {/*            }));*/}
        {/*          }}>*/}
        {/*    <Typography sx={{fontSize: '1.5em'}} >*/}
        {/*      {productionPrompt}*/}
        {/*    </Typography>*/}
        {/*  </Button>*/}
        {/*</Box>}*/}
        {nbItemsInBox > 0 && <Box className='box-items'>
          {
            // box.items && box.items.length > 0 &&
            // (box.items?.map((item) => itemPhoto(item, true)))
            itemsInBox && itemsInBox.length > 0 && (itemsInBox.map((item) => itemPhoto(item, true)))
            ||
            <Box className='box-empty'>Bac vide</Box>
          }
          {
            missingItems && missingItems.length > 0 &&
            (missingItems.map((item) => itemPhoto(item, false)))
          }
        </Box>}
      </> || <Box className='box-header'>
        <Box className='box-name'>
          QR CODE NON VALIDE
          <br/>
          VEUILLEZ RAFRAICHIR LA PAGE
        </Box>
      </Box>
      }
    </Box>
  )
}

export default WorkshopBoxDetails;