import React from 'react';
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useIntl} from "react-intl";
import {PriceFormatted, IProduct} from "../../app/product";

// const MAX_MARGIN_PERCENTS = 15;

const maxMarginPercent = (productPrice: number) => {
  if (productPrice <= 699) {
    return 30;
  } else if (productPrice < 1000) {
    return 25;
  } else if (productPrice < 1500) {
    return 20;
  } else if (productPrice < 2200) {
    return 17.50;
  } else if (productPrice < 2700) {
    return 16;
  } else if (productPrice < 3350) {
    return 15;
  } else if (productPrice < 3750) {
    return 13.5;
  } else if (productPrice < 5000) {
    return 12;
  } else if (productPrice < 6800) {
    return 11;
  } else if (productPrice < 7600) {
    return 10.5;
  } else {
    return 10;
  }
}


export const SelectProductMargin = (props: {
  product: IProduct,
  margin: number,
  setMargin: (margin: number) => void,
  editMode: boolean
}) => {

  const intl = useIntl();

  const {product, margin, setMargin, editMode} = props;
  const productMaxPrice = product.prices[product.prices.length - 1];
  const maxMargin = productMaxPrice * maxMarginPercent(productMaxPrice) / 100;
  const possibleMargins = [];

  for (let i = 0; i <= maxMargin; i += 50) {
    possibleMargins.push(i);
  }

  const handleSetMargin = (event: SelectChangeEvent<string>) => {
    event.stopPropagation();   // prevent the click on the content selection to trigger the activation of the addon
    setMargin(parseInt(event.target.value));
  }

  return (
    <Select className={'select-contentss'} size="small" autoWidth disabled={!editMode}
            value={margin.toString()} onChange={handleSetMargin}
            onClick={(e) => { e.stopPropagation(); }}>
      {possibleMargins.map((m: number) => {
        return (
          <MenuItem key={`${product.id}-${m}`} value={m.toString()}>
            {PriceFormatted(m) || intl.formatMessage({ id: "product.margin-none"})}
          </MenuItem>
        );
      })}
    </Select>
  )

}
