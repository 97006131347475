import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormControl, FormHelperText} from "@mui/material";
import {productionStateSelector, selectEndDate, selectStartDate} from "./productionSlice";
import dayjs, { Dayjs } from 'dayjs';
import {CustomDatepicker} from "../../utils/CustomDatePicker";

export function SelectEndDate() {

  const productionState = useSelector(productionStateSelector);
  const dispatch = useDispatch();

  const handleSelectEndDate = (date: Dayjs | null) => {
    const dateStr = date?.format("YYYY-MM-DD HH:mm:ss");
    dispatch(selectEndDate(dateStr))
  };

  const endDateTitle = productionState?.endDate ? `Jusqu'au ${dayjs(productionState?.endDate).format("DD/MM/YYYY")}` : "Jusqu'au ...";

  return (
    <FormControl sx={{ ml: 1, mr: 1, minWidth: 120 }} size="small" className='select-tool search-end-date'>
      <FormHelperText sx={{ml:0}}>{endDateTitle}</FormHelperText>
      <CustomDatepicker
        defaultValue={dayjs(productionState?.endDate)}
        onChange={handleSelectEndDate}
        disableFuture={true}
      />
    </FormControl>
  )
}
