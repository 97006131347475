import React, {useEffect, useState} from 'react'
import reactCSS from 'reactcss'
import {ColorResult, SketchPicker} from 'react-color'
import BrushIcon from "@mui/icons-material/Brush";
import {CustomButton} from "./CustomButton";
import {FormattedMessage} from "react-intl";
import {Button} from "@mui/material";

const colorSet = [
    '#D0021B',
    '#F5A623',
    '#F8E71C',
    '#8B572A',
    '#7ED321',
    '#417505',
    '#ff7aed',
    '#9013FE',
    '#084090',
    '#4A90E2',
    '#50E3C2',
    '#000000',
    '#A0A0A0'
  ]

const ColorPicker = (props:{
  propertyName: string,
  opened: boolean,
  color: string,
  displayColor: boolean,
  hideBrush?: boolean,
  setColor: (propertyName:string, newHexColor: string) => void,
  whiteAllowed: boolean,
  editable?: boolean
}) => {

  const {propertyName, opened, color, displayColor, hideBrush, setColor, whiteAllowed} = props
  const editable = props.editable || props.editable === undefined;

  const [displayColorPicker, setDisplayColorPicker] = useState(opened)
  const [displayedColor, setDisplayedColor] = useState(color)

  // console.log("%cRender ColorPicker with propertyName " + propertyName + " opened=" + opened + " color=" + color + ' displayColor=' + displayColor + ' displayColorPicker=' + displayColorPicker + ' displayedColor=' + displayedColor, 'color: purple')

  const styles = reactCSS({
    'default': {
      color: {
        width: '50px',
        height: '20px',
        borderRadius: '2px',
        background: displayedColor
      },
      swatch: {
        padding: '3px',
        background: '#fff',
        borderRadius: '1px',
        // boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        marginTop: '2px',
        display: 'inline-block',
        cursor: editable ? 'pointer' : 'default',
      },
      popover: {
        position: 'absolute',
        zIndex: 3,
        marginLeft: '-325px',
        backgroundColor: '#EEE',
        borderRadius: '4px',
        border: '1px solid rgba(0,0,0,.5)',
        padding: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 0px 1px, rgba(0, 0, 0, 0.35) 0px 4px 6px 5px',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
      modal: {
        position: 'fixed',
        zIndex: 10,
        top: '50%',
        left: '50%',
        marginTop: '-190px',
        marginLeft: '-190px',
        backgroundColor: '#EEE',
        borderRadius: '4px',
        border: '1px solid rgba(0,0,0,.5)',
        padding: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.45) 0px 4px 6px 5px',
      },
      picker: {
        // boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 0px 2px, rgb(0 0 0 / 35%) 0px 8px 16px'
      }
    },
  });

  useEffect(() => {
    setDisplayedColor(color)
  }, [color, opened]);

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === 'Escape') {
        handleCancel()
      }
    }

    document.addEventListener('keydown', handleEscapeKey)
    return () => document.removeEventListener('keydown', handleEscapeKey)
  }, [])

  const openColorPicker = (event: React.MouseEvent<HTMLElement>) => {
    // console.log("open color picker, stop propagation")
    event.stopPropagation();   // prevent the click on the content selection to trigger the activation of the addon
    setDisplayColorPicker(true)
  };

  const clickOnPicker = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();   // prevent the click on the picker to trigger menu selection in SelectPersoColor
  }

  const handleChange = (color: ColorResult) => {
    // console.log("color changed to " + color.hex)
    setDisplayedColor(color.hex)
  };

  const handleHoverColor = (color: ColorResult) => {
    // console.log("color hovered " + color.hex)
    setDisplayedColor(color.hex)
  };

  const handleConfirm = () => {
    // console.log('confirm color change to ' + displayedColor)
    setColor(propertyName, displayedColor)
    setDisplayColorPicker(false)
  };

  const handleCancel = () => {
    setDisplayedColor(color)
    setDisplayColorPicker(false)
  }

  const proposedColors = () => {
    let baseColors = [...colorSet];

    if (whiteAllowed) {
      baseColors.push('#FFFFFF')
    }

    return baseColors
  }

  const colorDisplay = () => {
    if (displayColor) {
      return (
        <div style={styles.swatch} onClick={(e) => {
          if (editable) openColorPicker(e)
        }}>
          <div style={styles.color}/>
        </div>
      )
    } else if (!opened && !hideBrush) {
      return (
        <div id={`edc-${propertyName}`} onClick={(e) => {
          if (editable) openColorPicker(e)
        }} style={{display: "flex", alignItems: "center"}}>
          <BrushIcon htmlColor={displayedColor}/>
        </div>
      )
    }
  }

  const colorPickerStyle = (displayColor ? styles.popover : styles.modal) as React.CSSProperties;

  const colorPicker = () => <div key={`dskpi-${propertyName}-${color}`} className='color-picker'
                                 style={colorPickerStyle} onClick={clickOnPicker}>
    <div style={styles.cover as React.CSSProperties}/>
    <div className='summary'>
      <div className='show-color' style={{backgroundColor: displayedColor}}></div>
      <div className='buttons'>
        <CustomButton onClick={handleCancel}><FormattedMessage id="global.cancel"/></CustomButton>
        <Button variant="contained" color="success" onClick={handleConfirm}>
          <FormattedMessage id="global.choose"/>
        </Button>
      </div>
    </div>
    <SketchPicker key={`skpi-${propertyName}-${color}`} color={displayedColor} disableAlpha={true} width='360px'
                  presetColors={proposedColors()}
                  onChange={handleChange} onSwatchHover={handleHoverColor} />
  </div>

  return (<>
          {colorDisplay()}
          {displayColorPicker && colorPicker()}
        </>)
}

export default ColorPicker