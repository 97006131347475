import React, {useState } from 'react';
import {ILogoInfos, ILogoVersion, SIZE_CODE_CAP} from "./logosSlice";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import {ICollectionProduct, IImageAddon} from "../dashboard/boutiqueSlice";
import {MAX_HEAWEAR_LOGO_HEIGHT, MAX_HEAWEAR_LOGO_WIDTH} from "../../app/catalog";


function LogoVersionSelect(props: {
  collectionProduct: ICollectionProduct,
  imageAddon: IImageAddon,
  logo: ILogoInfos,
  versionId: number,
  setVersionId: (logoId: number, logoVersionId: number) => void
}) {

  const {collectionProduct, logo, versionId, setVersionId} = props;

  const [selectedVersion, setSelectedVersion] = useState<number>(versionId);

  const handleVersionChange = (event: SelectChangeEvent<number>) => {
    event.stopPropagation();   // prevent the click on the version to trigger also a logo choice with old default version
    setSelectedVersion(event.target.value as number);
    setVersionId(logo.id, event.target.value as number);
  }

  // console.log("Render LogoVersionSelect in product " + collectionProduct.product.title + " for logo " + logo.id + " with version " + selectedVersion);

  const logoClass = logo.forDarkBg >= 1 ? 'dark-bg' : '';

  const productCategory = collectionProduct.product.category;

  const allowedLogoVersions = logo.versions.filter((lv) => (
      (productCategory === 'headwear' && lv.height <= MAX_HEAWEAR_LOGO_HEIGHT && lv.width <= MAX_HEAWEAR_LOGO_WIDTH) ||
      (productCategory !== 'headwear' && lv.sizeCode !== SIZE_CODE_CAP)
  ))

  return (
    <div className='select-logo'>
      <div className={logoClass}>
        <BoudaToolTip arrow placement="top" title={logo.name}>
          <img src={logo.logoUrl} className='select-logo' />
        </BoudaToolTip>
      </div>
      <div>
        <Select SelectDisplayProps={{
          style: { paddingTop: 4, paddingBottom: 4, paddingLeft: 4, paddingRight: 20, fontSize: '0.8em' } }}

                className={'select-logo-version'}
                size="small" value={selectedVersion} onChange={handleVersionChange}
                onClick={(e) => { e.stopPropagation(); }}
                renderValue={value => {
                  // console.log("renderValue => ", value);
                  const version = logo.versions.find((version) => version.id === value) as ILogoVersion;
                  return version ? version.name : '';
                }}>
          {allowedLogoVersions.map((version: ILogoVersion) => {
            return (
              <MenuItem key={`${version.id}`} value={version.id}>
                {version.name} ({version.priceCents/100} €)
              </MenuItem>
            );
          })}
        </Select>
      </div>

    </div>
  )

}

export default LogoVersionSelect;