import React from 'react';
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {FormControl, FormHelperText, ListSubheader} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";


const FONT_NAMES = [
  'Allstar',
  // 'Amsterdam Graffiti',
  'Arial Black',
  'Arial Bold',
  'Bebas Neue',
  'Calibri',
  'Comic Sans MS',
  'Directive Four',
  'Forte',
  'Gang-of-Three',
  'Hiragino Mincho Pro',
  'HoboStd',
  'Impact',
  'JerseyM54',
  'Karate',
  'Lobster',
  'Lucida Handwriting',
  'Mistral',
  'Norwester',
  'OpenSans',
  'PaybAck',
  'Philosopher Bold',
  'PrnStarAcademy',
  'Primetime',
  'Quartzo',
  'Roboto Condensed',
  'Shanghai',
  'TeleNeo'];

export const SelectFont = (props: {
  title: string;
  currentFont: string;
  focusFont: (font: string) => void;
  selectFont: (font: string) => void;
}) => {

  const {title, currentFont, focusFont, selectFont} = props;

  return (
    <FormControl sx={{ fontSize: 13 }} size="small">
      <FormHelperText sx={{ml:0, fontSize: 20}}>{title}</FormHelperText>
      <Select defaultValue={currentFont} onChange={(event: SelectChangeEvent) => selectFont(event.target.value)}>
        {FONT_NAMES.map((font,i) =>
          <MenuItem key={`font${i+1}`} value={font} onFocus={() => focusFont(font)} >{font}</MenuItem>
          )}
      </Select>
    </FormControl>
  )

}
