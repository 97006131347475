import React, {useState} from 'react';
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {FormattedMessage} from "react-intl";
import ColorTag from "../catalog/ColorTag";
import ColorPicker from "../utils/ColorPicker";
import {BoutiqueAllColors, BoutiqueColorToHex} from "./boutiqueSlice";
import Box from "@mui/material/Box";

const BASE_COLORS = ['white', 'black']

export const colorNameTranslated = (colorCode: string) => {
  if (colorCode.startsWith('color')) {
    return <FormattedMessage id="color.color-number" values={{number: parseInt(colorCode.substring(5))}} />;
  } else if (colorCode === 'new-color') {
    return <FormattedMessage id={`color.new-color`} />;
  } else {
    return <FormattedMessage id={`color.${colorCode}`} />;
  }
}

export const SelectPersoColor = (props: {
  colorIndex: number,
  color: string,
  setColor: (colorIndex: number, color: string) => void,
  boutiqueColors: string[],
  setBoutiqueColors: (colors: string[]) => void,
  editMode: boolean
}) => {

  const {colorIndex, color, setColor, boutiqueColors, setBoutiqueColors, editMode} = props;

  // we manage the open/closed state because we want to be able to manually close the select
  // after creating a new color
  const [selectExpanded, setSelectExpanded] = useState(false);

  const allColors = BASE_COLORS.concat(boutiqueColors.map((cl, index) => {
    return 'color' + (index + 1);
  })).concat('new-color');

  // console.log("%cRender SelectPersoColor for color " + color + " with index " + colorIndex, "color: blue");
  // console.log("allColors", JSON.stringify(allColors));

  // updating a color with the brush
  const setColorHex = (colorCode: string, hexColor: string) => {
    console.log('setColorHex of ', colorCode, ' to ', hexColor);
    let newBoutiqueColors = boutiqueColors.slice();

    if (colorCode === 'new-color') {
      newBoutiqueColors = newBoutiqueColors.concat(hexColor);
    } else {
      newBoutiqueColors[parseInt(colorCode.substring(5)) - 1] = hexColor;
    }
    // console.log("%cIn SelectPersoColor, setting boutique colors to " + newBoutiqueColors, "color: green");
    setBoutiqueColors(newBoutiqueColors)

    if (colorCode === 'new-color') {
      setColor(colorIndex, 'color' + (boutiqueColors.length + 1));
      setSelectExpanded(false);
    }
  }

  const colorValue = (colorCode: string) => {

    const colorHex = BoutiqueColorToHex(boutiqueColors, colorCode);
    const editableColor = (colorCode.startsWith('color') && parseInt(colorCode.substring(5)) > 2)
      || colorCode === 'new-color';

    return(
      <Box className='perso-color-value'>
        <Box className='perso-color-name'
             sx={{opacity: colorCode === 'new-color' ? 0.3 : 1, cursor: 'new-color' ? 'default' : 'pointer' }}>
          <>
            {colorCode !== 'new-color' && <ColorTag color1={colorHex} color2='' active={false} size='small' />}
            {colorNameTranslated(colorCode)}
          </>
        </Box>
        <Box className='perso-color-brush'>
          {editableColor  &&
            <ColorPicker key={`ed-${colorCode}${colorIndex}`} color={colorHex} whiteAllowed={false} displayColor={false}
                         propertyName={colorCode} setColor={setColorHex} opened={false} />
          }
        </Box>
      </Box>
    );
  }

  const handleColorChange = (event: SelectChangeEvent<string>) => {
    event.stopPropagation();   // prevent the click on the content selection to trigger the activation of the addon
    if (event.target.value !== 'new-color') {
      setColor(colorIndex, event.target.value as string);
      setSelectExpanded(false);
    }
  }

  if (color.startsWith('color')) {
    const colorNumber = parseInt(color.substring(5));
    if (colorNumber > boutiqueColors.length) {
      return null;
    }
  }

  return (
    <>
      <div className='perso-color'>
        <Select SelectDisplayProps={{style: {paddingTop: 2, paddingBottom: 2, paddingLeft: 4, paddingRight: 20,
                                      fontSize: '0.8em' } }}
                className={'select-color'}
                size="medium"
                value={color}
                disabled={!editMode}
                onChange={handleColorChange}
                onClick={(e) => { e.stopPropagation(); }}
                renderValue={value => colorValue(value)}
                // we manage the open/closed state because we want to be able to manually close the select
                // after creating a new color
                onOpen={() => setSelectExpanded(true)}
                onClose={() => setSelectExpanded(false)}
                open={selectExpanded}
                >
        {allColors.map((colorCode: string) => {
            return (
              <MenuItem key={`persocolor ${colorIndex}-${colorCode}`} value={colorCode}>
                {colorValue(colorCode)}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </>
  )

}
