import React from 'react';
import {Grid, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {
  IStockBox, IStockItem, StockBoxesSelector, AllStockItemsSelector,
} from "./productionSlice";
import {openInFullScreen} from "../../global/globalSlice";
import {AppDispatch} from "../../../app/store";

function StockBoxes() {

  const dispatch = useDispatch<AppDispatch>();
  const stockBoxes = useSelector(StockBoxesSelector);
  const stockItems = useSelector(AllStockItemsSelector);

  const stockBoxCard = (box: IStockBox) => {
    return(
      <Box className='workshop-box'>
        <Box className='box-header'>
          <Box className='box-name'>
            {box.name}
          </Box>
        </Box>
        <Box className='box-items'>
          {stockItems?.filter((item) => item.stockBoxId === box.id).map((item) =>
            <Box key={`stk-it-${item.id}`} className='photo'
                 onClick={() => dispatch(openInFullScreen({name: 'StockItemDetails', displayedId: item.id}))}>
              <img src={item.supplierPhoto}/>
            </Box>
          )}
        </Box>
        <Box className='box-qr-code'>
          <img src={box.qrCode} />
        </Box>
      </Box>
    )
  }

  return (
    <Box className='workshop-boxes'>
      <Grid container spacing={2}>
        {stockBoxes?.map((stockBox) =>
          <Grid item xs={12} sm={6} key={`box-${stockBox.id}`} className='box-container'>
            {stockBoxCard(stockBox)}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}


export default StockBoxes;