import {IProduct, IVariantInfos} from "../../app/product";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {
  addProductToSelection,
  getSelectedProducts,
  removeProductFromSelection, requestEraseSelection
} from "../../app/selectionAPI";
import {openSnackBar} from "../global/globalSlice";
import {FormattedMessage} from "react-intl";
import {IProductColorImages} from "../dashboard/boutiqueSlice";

export interface SelectionState {
  products: ISelectedProduct[];
  uniqueProducts: ISelectedProduct[];
  loaded: boolean;
  saving: boolean;
  error: boolean;
}

const initialState: SelectionState = {
  products: [],
  uniqueProducts: [],
  loaded: false,
  saving: false,
  error: false,
};

export interface ISelectedProduct {
  product: IProduct;
  colors: IProductColorImages[];
  obsolete?: boolean;
  otherVersions?: ISelectedProduct[];
}

export const productVariantFromColor = (product: IProduct, color: string) => {
  // remove all spaces and special characters from color before comparing
  // return product.variants.find(v => v.color === color);
  const simplifiedColor = color?.replace(/[^a-zA-Z0-9]/g, '');
  return product.variants.find(v => v.color.replace(/[^a-zA-Z0-9]/g, '') === simplifiedColor);
}

export interface ISelectionPayloadItem {
  id: number;
  colors: IProductColorImages[]
}

// merges all products into product + their connected products (other versions female/child)
const updateUniqueProducts = (state: SelectionState) => {
  if (state.products === undefined) {
    return;
  } else {
    let uniqueProducts: ISelectedProduct[] = [];

    state.products.forEach((p) => {

      const existingAsMainProduct = uniqueProducts
          .findIndex((up) => up.product === p.product)

      const existingAsOtherVersion = uniqueProducts
          .findIndex((up) => up.otherVersions && up.otherVersions
              .some((p2) => p2.product === p.product));

      // Add the product is not already listed as main or other version
      if (existingAsMainProduct === -1 && existingAsOtherVersion === -1) {

        // console.log("%cAdding product " +  p.product.title + "with connection code " + p.product.connection, 'color: #800000');

        // find all other versions of this product
        const otherVersions = state.products.filter((other) =>
            (p.product?.connection && other.product?.connection === p.product?.connection &&
                other.product !== p.product));

        // console.log("Other versions are ", otherVersions.map(p => p.product.title).join(", "));

        // we want to add into uniqueProducts the product + its other versions,
        // but if this product is the female or child version, we want to add the male product instead
        // in the precedence man, woman, child
        const productAndOtherVersions = [p, ...otherVersions]
            .sort((p1, p2) => (p1.product.gender - p2.product.gender));

        // console.log("Sorted productAndOtherVersions", productAndOtherVersions.map(p => p.product.title).join(", "));

        uniqueProducts.push({...productAndOtherVersions[0], otherVersions: productAndOtherVersions.slice(1)});
      }
    });

    state.uniqueProducts = uniqueProducts;
  }
}

export const getSelection = createAsyncThunk(
  "selection/getSelection",
  async (_, thunkApi) => {

    // console.log("%cgetSelection.createAsyncThunk...", 'color: blue');

    const response = await getSelectedProducts();
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return thunkApi.rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export interface ISelectedProductPayload {
  id: number;
  colors: IProductColorImages[];
  otherVersions: boolean;
}

export const addProduct = createAsyncThunk(
  "selection/addProduct",
  async (payload: ISelectedProductPayload, thunkAPI) => {

    const selectionPayload = {
      id: payload.id,
      colors: payload.colors,
      otherVersions: payload.otherVersions,
    } as ISelectionPayloadItem;

    const response = await addProductToSelection(selectionPayload);
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const removeProduct = createAsyncThunk(
  "selection/removeProduct",
  async (payload: ISelectedProductPayload, thunkAPI) => {

    const response = await removeProductFromSelection(payload.id, payload.otherVersions);
    if (response.error) {
      thunkAPI.dispatch(openSnackBar({
        severity: 'error',
        message: <FormattedMessage id="selection.didnot-remove" />,
        noAutoClose: true}));

      return thunkAPI.rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const eraseSelection = createAsyncThunk(
  "selection/eraseSelection",
  async (_, thunkAPI) => {

    const response = await requestEraseSelection();
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);



export const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    // this one is used to update the selection from cable broadcasting
    setSelectedProducts:(state, action) => {
      state.products = action.payload.selection;
      updateUniqueProducts(state);
    },
    clearSelection: (state) => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      // GET SELECTION
      .addCase(getSelection.pending, (state) => {
        state.loaded = false;
      })
      .addCase(getSelection.fulfilled, (state, action: any) => {
        // console.log("getSelection.fulfilled")
        state.products = action.payload.selection;
        updateUniqueProducts(state);
        state.loaded = true;
        state.error = false;
      })
      .addCase(getSelection.rejected, (state, action: any) => {
        state.loaded = false;
        state.error = true;
      })

      // ADD PRODUCT
      .addCase(addProduct.pending, (state) => {
        state.saving = true;
      })
      .addCase(addProduct.fulfilled, (state, action: any) => {
        state.saving = false;
        state.products = action.payload.selection;
        updateUniqueProducts(state);
        state.error = false;
      })
      .addCase(addProduct.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
      })

      // REMOVE PRODUCT
      .addCase(removeProduct.pending, (state, action: any) => {
        state.saving = true;
      })
      .addCase(removeProduct.fulfilled, (state, action: any) => {
        // console.log("removeProduct.fulfilled, new selection is ", action.payload.selection);
        state.saving = false;
        state.products = action.payload.selection;
        updateUniqueProducts(state);
        // console.log("updateUniqueProducts done");
        state.error = false;
      })
      .addCase(removeProduct.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
      })

      // ERASE SELECTION
      .addCase(eraseSelection.pending, (state) => {
        state.saving = true;
      })
      .addCase(eraseSelection.fulfilled, (state, action: any) => {
        state.saving = false;
        state.products = action.payload.selection;
        updateUniqueProducts(state);
        state.error = false;
      })
      .addCase(eraseSelection.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
      })
    }
});

export const {
  setSelectedProducts,
  clearSelection
} = selectionSlice.actions;


export const SelectedProducts = (state: RootState) => state.selection.products;
export const UniqueProducts = (state: RootState) => state.selection.uniqueProducts;
export const UniqueProductsCount = (state: RootState) => state.selection.uniqueProducts.length;
export const SavingSelection = (state: RootState) => state.selection.saving;
export const LoadedSelection = (state: RootState) => state.selection.loaded;


export default selectionSlice.reducer;