import {createSlice} from "@reduxjs/toolkit";
import produce from "immer";
import {RootState} from "../../app/store";
import {IProduct, IVariantInfos} from "../../app/product";

export interface ProductDetailsState {
  shownProduct: IProduct | null;
}

const initialState: ProductDetailsState = {
  shownProduct: null,
};


export const productDetailsSlice = createSlice({
  name: "product_details",
  initialState,
  reducers: {
    openProduct: (state, action) => {
      state.shownProduct = action.payload;
    },
    closeProduct: (state) => {
      state.shownProduct = null;
    }
  }
});

export const {
  openProduct,
  closeProduct
} = productDetailsSlice.actions;

export const productDetailsStateSelector = (state: RootState) => state.product_details;
export const productShownSelector = (state: RootState) => state.product_details.shownProduct;

export default productDetailsSlice.reducer;