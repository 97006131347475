import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { QrReader } from 'react-qr-reader';
import Box from "@mui/material/Box";
import Result from "@zxing/library/esm/core/Result";
import {BrowserQRCodeReader} from "@zxing/browser";
import IconButton from "@mui/material/IconButton";
import {closeFullScreen, openConfirmDialog, openInFullScreen, openSnackBar} from "../../global/globalSlice";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import {Button, CardMedia} from "@mui/material";
import {
  currentWorkshopBoxesSelector,
  PRODUCTION_IN_BOX, recordStockItem, selectOrder,
  setItemsProductionStatus, StockBoxesSelector, SelectedStockItemsSelector
} from "./productionSlice";
import Typography from "@mui/material/Typography";
import {CustomButton} from "../../utils/CustomButton";
import {RootState} from "../../../app/store";

const DEFAULT_PROMPT = 'Scanner un QR code'

export const ReadQrCode = (props: {
    targetType?: string,
    targetId?: number,
    objectId?: number
}) => {

  console.log("ReadQrCode with targetType " + props.targetType + " and objectId " + props.objectId + " and targetId " + props.targetId)

  const {targetType, targetId, objectId} = props;
  const [cameraSide, setCameraSide] = useState(localStorage.getItem('camera') || 'environment')
  const dispatch = useDispatch();
  const workShopBoxes = useSelector(currentWorkshopBoxesSelector);
  const stockBoxes = useSelector(StockBoxesSelector);
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const cannotUpdateOrders = !currentUser || !currentUser.update_orders

  let requestedWorkshopBox = (targetType === 'workshop_box' && targetId) ?
    workShopBoxes?.find((box) => box.id === targetId) : null;

  const prompt = () => {
    switch (targetType) {
      case 'workshop_box':
        if (targetId === undefined) {
          return "Scanner un bac libre pour cet article"
        } else {
          const boxName = requestedWorkshopBox?.name || 'inconnu'
          return (
            <>
              <Box>
                {`Ranger l'article dans le bac ${boxName}, puis scanner le bac pour enregistrer`}
              </Box>
              <br/>
              <Box>
                Ou simplement confirmer la mise en bac
              </Box>
            </>
          )
        }
        break;

      case 'item':
        return "Scanner le QR code de l'article"
        break;

      default:
        return DEFAULT_PROMPT
    }
  }

  const reStartScan = () => {
    dispatch(openInFullScreen({name: 'ReadQrCode',
      targetType: targetType,
      targetId: targetId,
      displayedId: objectId}))
  }

  const registerItemInWorkshopBox = () => {

    if (cannotUpdateOrders) return;

    if (targetId && objectId) {
      const updateStatus = {
        newOrderStatus: PRODUCTION_IN_BOX,
        newWorkshopBoxId: targetId,
        prodItemIds: [objectId]
      }
      dispatch(setItemsProductionStatus(updateStatus));
    }
  }

  const scanResult = (result?: Result | undefined | null,
                      error?: Error | undefined | null,
                      codeReader?: BrowserQRCodeReader) => {

    // console.log("Scan result : " + result + " error : " + error + " codeReader : " + codeReader)

    if (!!result) {
      console.log("Detected QR code : " + result.getText());
      dispatch(closeFullScreen())

      const targetURL = result.getText()
      // get the idParam from the target url which ends with /idParam
      const idParam = parseInt(targetURL.substring(targetURL.lastIndexOf('/') + 1))

      console.log("%cResult of scan for targetType : " + targetType + " => URL : >> " + targetURL + " << idParam : " + idParam, "color: red")

      switch(targetType) {

        // we want the user to scan a workshop box
        case 'workshop_box':

          if (cannotUpdateOrders) return;

          // the user actually scanned a workshop box
          if (targetURL.includes('workshop_box')) {

            console.log("%cScanned workshop box id " + idParam, "color: green")

            // are we looking for a new box or requesting a specific box ?
            if (targetId === undefined) {
              console.log("Looking for any box...")

              const chosenBox = workShopBoxes?.find((box) => box.id === idParam);

              if (chosenBox) {
                console.log("Chosen box : " + chosenBox.name)
                const boxOrderNumber = (chosenBox.items && chosenBox.items[0]) ? chosenBox.items[0].orderNumber : null;

                if (boxOrderNumber) {
                  dispatch(openConfirmDialog({
                    title: "Attention : bac " + chosenBox.name + " déjà utilisé par la commande " + boxOrderNumber + " !",
                    message: "Vous avez choisi le bac " + chosenBox.name + " mais il est déjà utilisé par la commande " + boxOrderNumber + ". Voulez-vous choisir un autre bac ?",
                    confirm: "Oui, scanner un autre bac !",
                    confirmColor: 'warning',
                    action: reStartScan
                  }));

                } else {

                  console.log("%cBox empty, so confirming the box with the user for article " + objectId, "color: blue")

                  // confimer auprès de l'utilisateur le choix de ce bac
                  dispatch(openConfirmDialog({
                    title: "Rangement dans le bac " + chosenBox.name,
                    message: "Avez-vous bien rangé l'article dans le bac " + chosenBox.name + " ?",
                    confirm: "Oui, enregistrer l'article dans ce bac !",
                    confirmColor: 'success',
                    action: () => {
                      const updateStatus = {
                        newOrderStatus: PRODUCTION_IN_BOX,
                        newWorkshopBoxId: idParam,
                        prodItemIds: [objectId || 0]
                      }
                      dispatch(setItemsProductionStatus(updateStatus));
                    }
                  }));
                }
              }

            } else {
              const requestedBoxId = targetId

              if (idParam === requestedBoxId) {
                registerItemInWorkshopBox()
              } else {
                // the scanned box is not the one we are looking for
                const chosenBox = workShopBoxes?.find((box) => box.id === idParam);

                dispatch(openConfirmDialog({
                  title: "Attention, vous avez scanné le bac " + chosenBox?.name + " (au lieu du bac " + requestedWorkshopBox?.name + ") !",
                  message: "Vous avez scanné un autre bac (" + chosenBox?.name + "). Voulez-vous rescanner le bon bac " + requestedWorkshopBox?.name + " ?",
                  confirm: "Oui, rescanner le bac !",
                  confirmColor: 'warning',
                  action: reStartScan
                }));
              }
            }
          }
          //
          // if (targetId === undefined && objectId) {
          //   console.log("targetId === undefined, objectId=" + objectId + ", scanned box id " + idParam + " reopening ReadQrCode")
          //   dispatch(openInFullScreen({name: 'ReadQrCode', targetType: targetType, targetId: idParam, objectId: objectId}))
          // }
          break;

        // we want the user to scan a stock box
        case 'stock_box':
          if (targetURL.includes('stock_box')) {
            console.log("Scanned stock box id " + idParam)
            if (idParam && targetId) {
              const chosenStockBox = stockBoxes?.find((box) => box.id === idParam);

              if (chosenStockBox) {

                // confimer auprès de l'utilisateur le choix de cette boite de stockage
                dispatch(openConfirmDialog({
                  title: "Rangement dans la boite de stockage " + chosenStockBox.name,
                  message: "Avez-vous bien stocké l'article dans la boîte " + chosenStockBox.name + " ?",
                  confirm: "Oui, enregistrer l'article dans cette boîte !",
                  confirmColor: 'success',
                  action: () => {
                    console.log("Enregistrer l'article " + targetId + " dans la boite de stockage " + idParam)
                    const update = {
                      id: targetId,
                      stock_box_id: idParam
                    }
                    dispatch(recordStockItem(update));
                  }
                }));

              } else {
                console.log("Ceci n'est pas une boite de stockage")
              }
            }
          } else {
            console.log("Ceci n'est pas une boite de stockage")
          }
          break;

        // we are scanning anything
        default:

          // scanning a workshop box
          if (targetURL.includes('workshop_box')) {
            dispatch(openInFullScreen({name: 'WorkshopBoxDetails', displayedId: idParam}))

          } else {

            // scanning an item from the stock
            if (targetURL.includes('stock_item')) {

              dispatch(selectOrder(''))
              dispatch(openInFullScreen({name: 'StockItemDetails', displayedId: idParam}))

              // scanning a production item
            } else if (targetURL.includes('item')) {
              // console.log("Dispatching openInFullScreen ProductionItem with displayedId " + idParam)
              dispatch(selectOrder(''))
              dispatch(openInFullScreen({name: 'ProductionItem', displayedId: idParam, param: 'fromQRCode'}))
            }
          }
      }
    }

    // when nothing is found
    // if (!!error) {
    //   // console.log("Error => " + error)
    // }
  }

  const switchCamera = () => {
    const newCamera = cameraSide === 'user' ? 'environment' : 'user'
    setCameraSide(newCamera)
    localStorage.setItem('camera', newCamera) // save for next time

    // window.location.reload()
    // console.log("Switched camera from " + camera + " to " + newCamera)
  }

  console.log("%cRendering ReadQrCode with targetType " + targetType + " and objectId " + objectId + " and targetId " + targetId, 'color: blue');

  return (
    <Box className='read-qr-code'>
      <Box className='title'>{prompt()}</Box>
      <IconButton size='large' className='camera-switch' onClick={switchCamera}>
        <CameraswitchIcon fontSize='large' />
      </IconButton>

      {targetType === 'stock_box' &&
          <Typography variant='h6' sx={{mb: 2}}>Veuillez scanner une boite de stockage</Typography>
      }

      {requestedWorkshopBox &&
        <Box className='requested-workshop-box'>
          <Button variant="contained" color="success" size='large' disabled={cannotUpdateOrders}
                  onClick={() => {
                    registerItemInWorkshopBox()
                    dispatch(closeFullScreen())
                  }}>
            <Typography sx={{fontSize: '1.5em'}} >
              {`Mis dans le bac ${requestedWorkshopBox.name} !`}
            </Typography>
          </Button>
        </Box>
      }

      <CardMedia className='scan-video'>
        <video
          style={{width: '400px', height: '400px'}}
          id="qr-reader"
          autoPlay={true}
          controls={false}
          muted={true}
        />
      </CardMedia>
      <CustomButton variant="contained" size='large'
              onClick={() => {
                dispatch(closeFullScreen())
              }} >
        <Typography sx={{fontSize: '1.5em'}} >Annuler</Typography>
      </CustomButton>

      <QrReader
        scanDelay={700}
        onResult={scanResult}
        videoId="qr-reader"
        constraints={{facingMode: cameraSide}} />
    </Box>
  );

}

export const ScanQRCodeButton = (props: {
  size?: 'small' | 'medium' | 'large',
  precedingText?: string,
  targetType?: string,
  targetId?: number,
  objectId?: number}) => {

  const {size, precedingText, targetType, targetId, objectId} = props;
  const dispatch = useDispatch();

  const marginBottom = precedingText === undefined ? -3 : 0
  const marginRight = precedingText === undefined ? 1 : 0
  // const padding = precedingText === undefined ? 1 : 0

  // console.log("Rendering ScanQRCodeButton with targetType " + targetType + " and objectId " + objectId + " and targetId " + targetId)
  return (
    <Box className='scan' onClick={() => {
      // console.log("Dispatching openInFullScreen with targetType " + targetType + " and objectId " + objectId + " and targetId " + targetId);
      dispatch(openInFullScreen({name: 'ReadQrCode', targetType: targetType, targetId: targetId, displayedId: objectId}))
    }}>
      {precedingText}
      <IconButton size={size || 'large'} sx={{mb: marginBottom, mr: marginRight, p:0}} aria-label="scan">
        <QrCodeScannerIcon sx={{width: '1.5em', height: '1.5em'}} />
      </IconButton>
    </Box>
  )
}