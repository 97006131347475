import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Autocomplete, Chip, FormControl, FormHelperText, Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import {boutiquesSelector, productionStateSelector, selectBoutiques, selectOrder} from "./productionSlice";

export function SearchBoutique() {

  const productionState = useSelector(productionStateSelector);
  const dispatch = useDispatch();
  const boutiques = useSelector(boutiquesSelector);

  const onBoutiquesChange = (event: object, value: any) => {
    console.log("onBoutiquesChange: " + JSON.stringify(value));
    dispatch(selectBoutiques(value))
  }

  return (
    <Stack spacing={3} sx={{mr:1, minWidth: 200}} className='select-tool search-boutique'>
      <Autocomplete size="small"
        multiple
        id="tags-outlined"
        options={boutiques}
        getOptionLabel={(boutique) => boutique.name}
        defaultValue={productionState?.selectedBoutiques || []}
        filterSelectedOptions
        onChange={onBoutiquesChange}
        renderInput={(params) => (
          <>
            <FormHelperText sx={{ml:0}}>Boutiques</FormHelperText>
            <TextField {...params} />
          </>
        )}
      />
    </Stack>
  );

}
