import React, {useRef, useState} from 'react';
import Box from "@mui/material/Box";
import dpd from "../../../images/dpd.svg";
import colissimo from "../../../images/colissimo.svg";
import {
  COLISSIMO_URL, ColorForTrackingNumber,
  ColorsForOrderStatus,
  currentWorkshopSelector, DPD_TRACKING_URL, INextProductionStatus,
  ISelectedOrder, ISetDpdDelivery,
  IWorkshopBox, nextOrderStatusesSelector, orderWorkshopBoxSelector, PRODUCTION_DELIVERY_START,
  PRODUCTION_IN_BOX, PRODUCTION_MARKINGS_DONE, PRODUCTION_ORDER_COMPLETE, PRODUCTION_TO_START,
  productionOrdersSelector, savingSelector, selectWorkshop, setDpdDelivery, setItemsProductionStatus
} from "./productionSlice";
import ProductionItem, {ProductItemHeader} from "./ProductionItem";
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import StoreIcon from '@mui/icons-material/Store';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditNoteIcon from '@mui/icons-material/EditNote';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import {useDispatch, useSelector} from "react-redux";
import {ScanQRCodeButton} from "./ScanQRCode";
import {openConfirmDialog, openInFullScreen} from "../../global/globalSlice";
import dayjs, { Dayjs } from 'dayjs';
import {AssignStatus} from "./AssignStatus";
import {BoudaToolTip} from "../../utils/BoudaToolTip";
import DeliveryDpd from "./DeliveryDpd";
import {RootState} from "../../../app/store";

function ProductionOrder(props: {
  order?: ISelectedOrder,
  orderNumber?: number,
  prodItemId?: number,
  orderStatus?: number,
  fullScreen: boolean,
  viewFrom?: string
}) {

  const {order, prodItemId, orderStatus, fullScreen, viewFrom} = props

  const currentWorkshop = useSelector(currentWorkshopSelector);
  const productionOrders = useSelector(productionOrdersSelector);
  const currentUser = useSelector((state : RootState) => state.session.currentUser);

  const cannotUpdateOrders = !currentUser || !currentUser.update_orders

  const dispatch = useDispatch();
  const [dpdOpen, setDpdOpen] = useState(false);
  const [dpdEditedNumber, setDpdEditedNumber] = useState<string|null>(null);
  const [dpdEditedComment, setDpdEditedComment] = useState<string|null>(null);
  const [dpdEditedPrice, setDpdEditedPrice] = useState<number|null>(null);
  const saving = useSelector(savingSelector);

  let thisOrder = order;

  if (!thisOrder && productionOrders) {
    if (props.orderNumber) {
      thisOrder = productionOrders.find((order) => order.orderNumber === props.orderNumber)
    } else {
      thisOrder = productionOrders.find((order) => order
        .items.some((item) => item
          .singleItems.some((si) => si.prodItemId === prodItemId)))
    }
  }

  const assignedOrderBox: IWorkshopBox | undefined = useSelector(state => orderWorkshopBoxSelector(state, thisOrder));

  if (!thisOrder) {
    // console.log("No order found for prodItemId " + prodItemId)
    return (<div>ERREUR INATTENDUE</div>);
  }

  // console.log("Rendering ProductionItem of " + thisOrder.orderNumber + " with fullScreen " + fullScreen)

  const deliveryModeIcon = (deliveryMode: string) => {
    switch (deliveryMode) {
      case 'boutique':
        return <BusinessIcon fontSize='medium' sx={{color:'magenta'}} />;
      case 'pickup':
        // return <LocalShippingIcon fontSize='small' />;
        return <StoreIcon fontSize='medium' sx={{color:'green'}}  />;
      case 'home':
        return <HomeIcon fontSize='medium' sx={{color:'blue'}} />;
      default:
        return null;
    }
  }

  const orderNumber = props.orderNumber || thisOrder.orderNumber;
  const orderNbSingleItems = thisOrder.items.reduce((nb, item) =>
    nb + item.singleItems.length, 0)
  const orderMissingItems = thisOrder.items.reduce((nb, item) =>
    nb + item.singleItems.filter((si) => si.orderStatus < PRODUCTION_IN_BOX).length, 0)

  const updatedSelectedItems = thisOrder.items

  // number of checked items in the current view (either 1 status view or whole order view)
  const displayedItems = thisOrder.items
    .filter((item) => !orderStatus || item.singleItems.some((si) => si.orderStatus === orderStatus))
  // console.log("%cnb displayedItems = " + displayedItems.length, 'color: red')

  const checkedItems = displayedItems.filter((item) => item.checked);
  // console.log("%cnb checkedItems = " + checkedItems.length, 'color: blue')

  const nbCheckedItems = checkedItems.length;

  // updated items are all displayed or checked items if any
  const updatedProdItems = (nbCheckedItems > 0 ? checkedItems : displayedItems)
    .flatMap((item) => item.singleItems)
    .filter((si) => !orderStatus || si.orderStatus === orderStatus)

  const updatedProdItemIds = updatedProdItems.map((si) => si.prodItemId)


  const assignStatus = (newOrderStatus: number, newStatusName: string) => {

    if (saving || cannotUpdateOrders) return;

    let title : string;
    let target : string;

    if (nbCheckedItems === 0) {
      title = "Passage " + (fullScreen ? "de toute la commande" : "des articles ci-dessous")
      target = fullScreen ? "de toute la commande" : "des articles ci-dessous"
    } else {
      target = `des ${nbCheckedItems} articles sélectionnés`
      title = `Passage ${target}`
    }

    // dispatch(selectWorkshop(event.target.value))
    dispatch(openConfirmDialog({
      title: title + " au statut " + newStatusName + " ?",
      message: "Confirmez-vous le passage au statut " + newStatusName + " " + target + " ?",
      confirm: "Oui, mettre en " + newStatusName + " !",
      confirmColor: 'warning',
      action: moveToNewStatus(newOrderStatus)
    }));
  };

  // export interface IUpdateProductionStatus {
  //   newOrderStatus: number;
  //   newWorkshopBoxId: number | null;
  //   prodItemIds: number[];
  // }
  const moveToNewStatus = (newOrderStatus: number) => () => {

    const updateStatus = {
      newOrderStatus: newOrderStatus,
      newWorkshopBoxId: -1,
      prodItemIds: updatedProdItemIds
    }

    // console.log("%cmoveToNewStatus " + newOrderStatus + " => " + JSON.stringify(updateStatus), 'color: red');
    dispatch(setItemsProductionStatus(updateStatus));
  }

  // console.log("%cRendering ProductionOrder of " + orderNumber + " with orderStatus= " + orderStatus, "color: red")
  // console.log("%cRendering ProductionOrder with prodItemId=" + prodItemId + " with fullScreen= " + fullScreen, "color: orange")

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Tooltip to show other items in another status (only in non full screen mode)
  ////////////////////////////////////////////////////////////////////////////////////////////////
  const otherStatusesItems = fullScreen ? [] : thisOrder.items
      .filter((item) => item.singleItems.some((si) => si.orderStatus !== orderStatus))
  const nbOtherStatusesItems = otherStatusesItems.length
  const lowestOrderStatus = thisOrder.items
      .flatMap((item) => item.singleItems)
      .map((si) => si.orderStatus)
      .sort((a, b) => a - b)[0]

  const otherStatusesItemsCard = nbOtherStatusesItems === 0 ? null :
      <Box className='production-item in-tool-tip'>
        <Box className='production-order-items'>
          {otherStatusesItems.map((item, index) =>
            <Box className='article in-tool-tip'>
              <ProductItemHeader item={item}
                                 nbItems={item.singleItems.filter((si) => si.orderStatus !== orderStatus).length}
                                 withSupplierRef={false} fullScreen={true} currentProdItem={item.singleItems[0]} />
            </Box>
          )}
        </Box>
      </Box>

  const orderNumberColors= ColorsForOrderStatus(lowestOrderStatus)


  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Shipping by Colissimo or DPD
  ////////////////////////////////////////////////////////////////////////////////////////////////

  const nbShippableItems = updatedProdItems
        .filter((si) => si.orderStatus >= PRODUCTION_MARKINGS_DONE).length

  // list of unique shippings
  const existingShippings = thisOrder.items
    .filter((item) => !!item.shippingNumber && (!orderStatus ||
      item.singleItems.some((si) => si.orderStatus === orderStatus)))
    .flatMap((item) => ({
      mode: item.shippingMode,
      number: item.shippingNumber,
      comment: item.shippingComment,
      labelUrl: item.shippingLabelUrl,
      cost: item.shippingCost,
      manyDeliveries: item.manyDeliveries
    })) || []

  /////////////////////////////////////////////////////////////////////////////////////
  // DPD
  /////////////////////////////////////////////////////////////////////////////////////

  const handleDPDClick = () => {
    if (saving || cannotUpdateOrders) return;
    setDpdOpen(true);
  }

  const closeDpd = () => {
    setDpdEditedNumber(null)
    setDpdEditedComment(null)
    setDpdEditedPrice(null)
    setDpdOpen(false)
  }

  const saveDpd = (dpdNumber: string, dpdComment: string, dpdPrice: number) => {
    const dpdDelivery = {
      orderNumber: orderNumber,
      oldTracking: dpdEditedNumber,
      dpdTracking: dpdNumber,
      dpdComment: dpdComment,
      dpdPrice: dpdPrice,
      prodItemIds: updatedProdItemIds
    }

    dispatch(setDpdDelivery(dpdDelivery));

    closeDpd();
  }

  const dpdShippings = existingShippings
    .filter((item) => item.mode === 'DPD')
    .filter((item, index, self) =>
      index === self.findIndex((t) => (t.number === item.number)))

  /////////////////////////////////////////////////////////////////////////////////////
  // Colissimo
  /////////////////////////////////////////////////////////////////////////////////////

  const handleColissimoClick = () => {
    if (saving || cannotUpdateOrders) return;

    let concernedItems : string;

    if (nbCheckedItems === 0) {
      concernedItems = nbOtherStatusesItems > 0 ? ("seulement " + nbShippableItems + " articles") : "tous les articles"
    } else {
      concernedItems = `${nbCheckedItems} articles sélectionnés seulement`
    }

    const confirmationMessage = (nbOtherStatusesItems > 0 ? "ATTENTION : " : "") + "Confirmez-vous le passage en départ livraison par Colissimo de " + concernedItems + " ?" + (thisOrder?.partial ? " ATTENTION Commande incomplète !" : "");
    const confirmColor = thisOrder?.partial ? 'error' :
      (nbOtherStatusesItems > 0  ? 'warning' : 'primary');

    dispatch(openConfirmDialog({
      title: (nbOtherStatusesItems > 0 ? "ATTENTION : " : "") + "Passage en départ livraison par Colissimo ?",
      message: confirmationMessage,
      confirm: "Oui, mettre en départ livraison par Colissimo !",
      confirmColor: confirmColor,
      action: moveToNewStatus(PRODUCTION_DELIVERY_START)
    }));
  }

  const colissimoShippings = existingShippings
    .filter((item) => item.mode === 'Colissimo')
    .filter((item, index, self) =>
            index === self.findIndex((t) => (t.number === item.number)))

  const colorForDeliveryTracking = (deliv : {number: string, manyDeliveries: boolean}) => {
    if (deliv.manyDeliveries) {
      return `${ColorForTrackingNumber(deliv.number)} !important`
    } else {
      return 'unset'
    }
  }

  const shippingButtons = () => <Box className='shipping'>
    <Box className='shipper' sx={{opacity: saving ? 0.3 : 1}}>
      <img src={colissimo} className='logo' onClick={handleColissimoClick} />
      {colissimoShippings.length > 0 && <Box className='colissimo-shippings'>
        {colissimoShippings.map((colissimoShipping) =>
          <Box className='links'>
            <a href={colissimoShipping.labelUrl} target='_blank' rel='noreferrer'>
              <PictureAsPdfIcon sx={{color: colorForDeliveryTracking(colissimoShipping) }} />
            </a>
            <a href={COLISSIMO_URL + colissimoShipping.number} target='_blank' rel='noreferrer'>
              <OpenInNewIcon sx={{color: colorForDeliveryTracking(colissimoShipping) }} />
            </a>
          </Box>)
        }
      </Box>}
    </Box>
    {thisOrder?.delivery.mode !== 'pickup' && <Box className='shipper'>
      <img src={dpd} className='logo' onClick={handleDPDClick} />
      {dpdShippings.length > 0 && <Box className='colissimo-shippings'>
        {dpdShippings.map((dpdShipping) =>
          <Box className='links'>
            <a href={DPD_TRACKING_URL + dpdShipping.number.replace(/\s/g, "")}
               target='_blank' rel='noreferrer'>
              <OpenInNewIcon sx={{color: colorForDeliveryTracking(dpdShipping) }} />
            </a>
            <EditNoteIcon sx={{color: colorForDeliveryTracking(dpdShipping) }} onClick={() => {
              if (saving || cannotUpdateOrders) return;
              setDpdEditedNumber(dpdShipping.number)
              setDpdEditedComment(dpdShipping.comment)
              setDpdEditedPrice(dpdShipping.cost)
              setDpdOpen(true)
            }} />
          </Box>)
        }
      </Box>}
    </Box>}
  </Box>

  return (
    <>
      <Box className='production-item' key={`pi-${orderNumber}-${orderStatus}`} >
        <Box className={`production-order ${fullScreen ? 'full-screen' : ''}`}>
          <Box className='order-number'>
            <Box className='order-number-date'>
              <BoudaToolTip title={otherStatusesItemsCard} placement="right-start" backgroundColor='#404040'>
                <Box className='number' sx={{backgroundColor: orderNumberColors.bgColor, color: orderNumberColors.fgColor}}>
                  {orderNumber}
                </Box>
              </BoudaToolTip>
              <Box className='date'>{dayjs(thisOrder.orderDate, { utc: true}).format("DD/MM/YYYY")}</Box>
            </Box>
            {assignedOrderBox &&
                <Box className='order-box-name' onClick={() =>
                    dispatch(openInFullScreen({name: 'WorkshopBoxDetails', displayedId: assignedOrderBox?.id}))
                }>
                  {assignedOrderBox.name}
                </Box>
            }
            <Box className='nb-items'>
              ({orderNbSingleItems}{orderMissingItems > 0 && <span className='missing-items'>-{orderMissingItems}</span>})
            </Box>
            {thisOrder.partial && ((orderStatus === undefined) || orderStatus > PRODUCTION_TO_START) &&
              <BoudaToolTip title="Commande incomplète" placement="top" backgroundColor='#9975C0'>
                <StarHalfIcon className='partial' />
              </BoudaToolTip>
            }
          </Box>
          <Box className='order-recipient'>
            <Box className='order-boutique'>
              {thisOrder.boutique.name} ({thisOrder.boutique.id})
            </Box>
            <Box className='order-address'>
              <Box>{deliveryModeIcon(thisOrder.delivery.mode)}</Box>
              <Box>{thisOrder.delivery.address}</Box>
            </Box>
          </Box>
          {fullScreen && <ScanQRCodeButton size='small' precedingText='' />}
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            {nbShippableItems > 0 && (fullScreen || (orderStatus && orderStatus >= PRODUCTION_MARKINGS_DONE)) && <>
              {(thisOrder.delivery.onHold &&
                      <Box className='delivery-on-hold'>Livraison<br/>bloquée</Box>)
                  || shippingButtons()}
            </>}
            {!cannotUpdateOrders && <AssignStatus setNewStatus={assignStatus} key={`ast-${orderNumber}-${orderStatus}`} />}
          </Box>
        </Box>
        {fullScreen && currentWorkshop && currentWorkshop.statuses.length > 0 &&
            (<Box className='production-order-items full-screen'>
              {currentWorkshop.statuses.flatMap((status) => {

                const thisStatusSingleItems = thisOrder?.items.map(
                    (item) => ({
                      item: item,
                      statusSingleItems: item.singleItems
                          .filter((si) => si.orderStatus === status.orderStatus)
                    })).map((richItem) => ({
                  ...richItem,
                  nbSingleItems: richItem.statusSingleItems.length,
                  selected: richItem.statusSingleItems.some((si) => prodItemId && si.prodItemId === prodItemId)
                })).filter((richItem) => richItem.nbSingleItems > 0)

                return(
                    thisStatusSingleItems?.map((richItem, index) => {
                      return(
                          <ProductionItem key={`${orderNumber}-${status.orderStatus}-${index}-${richItem.nbSingleItems}-${richItem.selected}`}
                                          orderNumber={orderNumber}
                                          item={richItem.item}
                                          orderStatus={status.orderStatus}
                                          selected={richItem.selected} nbItems={richItem.nbSingleItems}
                                          first={index === 0}
                                          prodItemId={prodItemId} fullScreen={fullScreen} viewFrom={viewFrom}
                                          assignedWorkshopBox={assignedOrderBox} user={currentUser} />

                      )
                    })
                )
              })}
            </Box>) ||
            <Box className={`production-order-items ${fullScreen ? 'full-screen' : ''}`}>
              {thisOrder.items
                  .filter((item) => item.singleItems
                      .some((si) => si.orderStatus === orderStatus))
                  .map((item, index) =>
                      <ProductionItem key={`${orderNumber}-${index}`}
                                      orderNumber={orderNumber}
                                      item={item} orderStatus={orderStatus}
                                      selected={item.singleItems.some((si) =>
                                          (orderStatus && si.orderStatus === orderStatus) ||
                                          (prodItemId && si.prodItemId === prodItemId))}
                                      nbItems={
                                        item.singleItems
                                            .filter((si) => si.orderStatus === orderStatus).length
                                      } first={index === 0}
                                      prodItemId={prodItemId} fullScreen={fullScreen} viewFrom={viewFrom}
                                      assignedWorkshopBox={assignedOrderBox} user={currentUser} />)
              }
            </Box>
        }
      </Box>
      <DeliveryDpd trackingNumber={dpdEditedNumber} trackingComment={dpdEditedComment} price={dpdEditedPrice}
                   open={dpdOpen} handleClose={closeDpd} handleSave={saveDpd} nbProducts={updatedProdItemIds.length} />
    </>
  )
}

export default ProductionOrder;