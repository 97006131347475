import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from './sessionSlice';
import {useIntl} from "react-intl";
import {getAccessToken} from "../../app/sessionAPI";
import {clearSelection} from "../selection/selectionSlice";
import {clearBoutique, getBoutiques} from "../dashboard/boutiqueSlice";
import {clearLogos, saveLogo} from "../logos/logosSlice";
import {openSnackBar} from "../global/globalSlice";

function Logout() {
  const intl = useIntl();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    doLogout();
    navigate('/');
  }, []);

  async function doLogout() {

    const response = await dispatch(logoutUser()) as any;

    console.log("After logout, first clearSelection....")
    dispatch(clearSelection());
    console.log("Then clearBoutique....")
    dispatch(clearBoutique());
    console.log("Then clearLogos....")
    dispatch(clearLogos());
  }



  return (
    <div>{intl.formatMessage({id: "session.logout"})}</div>
  )
}

export default Logout