import React, {lazy, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {
  GridColDef,
  frFR,
} from '@mui/x-data-grid';
import {useAppSelector} from "../../../app/hooks";
import {StripedDataGrid} from "../../utils/StrippedDataGrid";
import {getLogs, logsLoadedSelector, logsSelector} from "./logsSlice";

import './logs.scss';

import {
  PRODUCTION_DELIVERED, PRODUCTION_DELIVERY_ERROR, PRODUCTION_DELIVERY_FINAL_RETRIEVE, PRODUCTION_DELIVERY_IN_PROGRESS,
  PRODUCTION_DELIVERY_START, PRODUCTION_DELIVERY_WAIT_RETRIEVE,
  PRODUCTION_IN_BOX, PRODUCTION_IS_BACK_TO_SENDER,
  PRODUCTION_MARKINGS_DONE,
  PRODUCTION_ORDER_COMPLETE,
  PRODUCTION_ORDER_ERROR, PRODUCTION_OVER,
  PRODUCTION_PROBLEM, PRODUCTION_RETURNING_TO_SENDER,
  PRODUCTION_STARTED,
  PRODUCTION_TO_START,
  PRODUCTION_WITH_LOGOS
} from "../production/productionSlice";
import {getAccessToken} from "../../../app/sessionAPI";
import {RootState} from "../../../app/store";
import dayjs from "dayjs";


export default function LogsTable() {

  const dispatch = useDispatch();

  const accessToken = getAccessToken();
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const userLogs = currentUser && currentUser.superAdmin
  const logs = useAppSelector(logsSelector);
  const logsLoaded = useSelector(logsLoadedSelector);

  useEffect(() => {
    if (accessToken && accessToken !== 'undefined' && userLogs) {
      if (!logsLoaded) {
        dispatch(getLogs())
      }
    }
  }, [dispatch, accessToken, currentUser, logsLoaded]);


  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      headerClassName: 'log-header',
      align: 'center',
      width: 120,
      sortable: false,
      renderCell: (params) => dayjs(params.value, { utc: true, locale: 'fr'})
        .format("ddd D MMM HH:mm")
    },
    {
      field: 'doer',
      headerName: 'Doer',
      headerClassName: 'log-header',
      align: 'center',
      sortable: true,
      width: 80,
    },
    {
      field: 'orderNumber',
      headerName: 'Order',
      headerClassName: 'log-header',
      align: 'center',
      width: 60,
    },
    {
      field: 'article',
      headerName: 'Article',
      headerClassName: 'log-header',
      flex: 2,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerClassName: 'log-header',
      flex: 1,
    },
    {
      field: 'orderStatus',
      headerName: 'Status',
      headerClassName: 'log-header',
      align: 'center',
      width: 60,
    },
    // {
    //   field: 'verb',
    //   headerName: 'Verb',
    //   headerClassName: 'log-header',
    // },
    {
      field: 'details',
      headerName: 'Détails',
      headerClassName: 'log-header',
      flex: 3,
    },
    {
      field: 'vendor',
      headerName: 'Boutique',
      headerClassName: 'log-header',
      flex: 2,
    },
    // If we need to make a virtual value calculated
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  const rows = () => logs.map((log, index) => (
    {
      id: log.id,
      date: log.date,
      doer: log.doer,
      action: log.action,
      verb: log.verb,
      boudist: log.boudist,
      orderNumber: log.orderNumber,
      article: log.article,
      orderStatus: log.orderStatus,
      boutique: log.boutique,
      vendor: log.vendor,
      workshop: log.workshop,
      workshopBox: log.workshopBox,
      details: log.details,
    }));

  return (
    <Box className='logs-table'
      sx={{
        height: 'calc(100vh - 90px)',
        width: '100%',
        '& .log-header': {
          backgroundColor: '#262626',
          color: 'white',
          fontWeight: 'bold',
          border: '1px solid #FFFFFF',
        },
      }}
    >
      <StripedDataGrid
        rows={rows()} columns={columns} loading={!logsLoaded}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        getRowClassName={(params) => {
          const oddClass = params.indexRelativeToCurrentPage % 2 === 0 ? 'evenl' : 'oddl';
          const doerClass = params.row.doer.toLowerCase();
          return `${oddClass} ${doerClass}`;
          }}
      />
    </Box>
  );
}


