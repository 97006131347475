import React from 'react';
import Box from "@mui/material/Box";
import ProductionStatuses from "./ProductionStatuses";
import {
  CHECKED_ITEM_COLOR, ColorsForOrderStatus,
  currentOrderStatusSelector,
  currentWorkshopSelector,
  ISelectedOrder, PRODUCTION_STARTED, PRODUCTION_TO_START,
  productionOrdersSelector, savingSelector, sendForIntegration, setItemsProductionStatus
} from "./productionSlice";
import ProductionOrder from "./ProductionOrder";
import {useDispatch, useSelector} from "react-redux";
import {CustomButton} from "../../utils/CustomButton";
import {Button, CircularProgress} from "@mui/material";
import {AppDispatch, RootState} from "../../../app/store";

export function ProductionList() {

  const productionOrders = useSelector(productionOrdersSelector);
  const currentOrderStatus = useSelector(currentOrderStatusSelector);
  const currentWorkshop = useSelector(currentWorkshopSelector);
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const cannotUpdateOrders = !currentUser || !currentUser.update_orders
  const saving = useSelector(savingSelector);
  const dispatch = useDispatch<AppDispatch>();

  // array of checked items
  const checkedItems = productionOrders? productionOrders
    .flatMap((order: ISelectedOrder) => order.items
      .filter((item) => item.checked)) : [];

  const nbCheckedItems = checkedItems.length;
  const integrationTitle = `Lancer l'intégraton de ${nbCheckedItems} article${nbCheckedItems > 1 ? 's' : ''}`;
  const prodStartedTitle = `Passer ${nbCheckedItems} article${nbCheckedItems > 1 ? 's' : ''} en Attente de support`;

  document.title = "Commandes à produire";

  if (!currentWorkshop) {
    return <Box>Chargement...</Box>
  }

  const nbStatuses = currentWorkshop.statuses.length;
  const height = (70 * nbStatuses) - 10;

  const handleIntegration = () => {
    if (saving || cannotUpdateOrders || nbCheckedItems === 0) return;
    dispatch(sendForIntegration(checkedItems.map((item) => item.id)))
  }

  const colorProductionStarted = ColorsForOrderStatus(PRODUCTION_STARTED)
  const handleProductionStarted = () => {
    if (saving || cannotUpdateOrders || nbCheckedItems === 0) return;
    // dispatch(sendForIntegration(checkedItems.map((item) => item.id)))

    const updateStatus = {
      newOrderStatus: PRODUCTION_STARTED,
      newWorkshopBoxId: -1,
      prodItemIds: checkedItems.flatMap((item) => item.singleItems.map((si) => si.prodItemId))
    }

    // console.log("%cmoveToNewStatus " + newOrderStatus + " => " + JSON.stringify(updateStatus), 'color: red');
    dispatch(setItemsProductionStatus(updateStatus));
  }

  return(
    <Box className='production-orders'>
      <ProductionStatuses />
      <Box sx={{display: 'flex', flexDirection: 'column', pl: 1, pr:0, m:0, width:'100%',
                height: `${height}px`, overflowY: 'auto'}}>
        {productionOrders && productionOrders.length > 0 &&
          <>
          {currentOrderStatus === PRODUCTION_TO_START && nbCheckedItems > 0 && <Box className='integration'>
            <CustomButton variant="contained" backgroundcolor={CHECKED_ITEM_COLOR} textcolor='black'
                          size="large" sx={{m: 1}} onClick={handleIntegration}
                          disabled={saving || cannotUpdateOrders}>
              {integrationTitle} {saving && <CircularProgress color="inherit" sx={{ml:1}} size={20}/>}
            </CustomButton>
	          <CustomButton variant="contained" backgroundcolor={colorProductionStarted.bgColor}
                          textcolor={colorProductionStarted.fgColor}
	                        size="large" sx={{m: 1}} onClick={handleProductionStarted}
	                        disabled={saving || cannotUpdateOrders}>
              {prodStartedTitle} {saving && <CircularProgress color="inherit" sx={{ml:1}} size={20}/>}
	          </CustomButton>
          </Box>}
          {productionOrders
	          .filter((order: ISelectedOrder) => order.items
	          .filter((item) => item.singleItems
	          .some((si) => si.orderStatus === currentOrderStatus)).length > 0)
	          .map((order: ISelectedOrder, index) =>
	          <ProductionOrder key={`production-${index}`} order={order} orderStatus={currentOrderStatus}
	                           fullScreen={false} />
	          )}
          </>
        }
      </Box>
    </Box>
  )
}
