import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {
  ColorsForOrderStatus, currentOrderStatusSelector,
  currentWorkshopSelector,
  IWorkshopStatus,
  productionOrdersSelector, selectStatus
} from "./productionSlice";

const ProductionStatuses = () => {
  const currentWorkshop = useSelector(currentWorkshopSelector);
  const currentOrderStatus = useSelector(currentOrderStatusSelector);
  const productionOrders = useSelector(productionOrdersSelector);
  const dispatch = useDispatch();

  const statusCard = (workshopStatus: IWorkshopStatus) => {
    if (productionOrders) {
      const nbArticles = workshopStatus.nbSingleItems
      const borderColor = currentOrderStatus === workshopStatus.orderStatus ? '#A0A0A0' : 'white'
      const statusColors = ColorsForOrderStatus(workshopStatus.orderStatus)

      if (nbArticles === 0) return null

      return (
        <Box key={`prod-status-${workshopStatus.orderStatus}`} className='status'
            style={{
              backgroundColor: statusColors.bgColor,
              color: statusColors.fgColor,
              border: `5px solid ${borderColor}`,
              opacity: nbArticles > -1 ? 1 : 0.6
            }}
            onClick={() => dispatch(selectStatus(workshopStatus.orderStatus))}>
          { nbArticles > -1 && <Box>{nbArticles}</Box>}
          <Box className='title'>
            {workshopStatus.statusName}
          </Box>
        </Box>
      )
    }
  }

  return (
    <Box>
      {currentWorkshop && currentWorkshop.statuses.length > 0 &&
        <Box className="statuses">
          {currentWorkshop.statuses.map((status) => statusCard(status))}
        </Box>
      }
    </Box>
  );
}

export default ProductionStatuses;

