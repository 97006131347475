import axios from "./axios";
import {IProductDefinition, IProductSearch} from "./catalog";
import {CLIENT_ID, CLIENT_SECRET} from "./global";
import {
  IProdExportParameters, ISetDpdDelivery,
  IUpdateDeliveryOrder,
  IUpdateMarkingDone,
  IUpdateProductionDelivery,
  IUpdateProductionStatus, IUpdateStockItem
} from "../features/admin/production/productionSlice";

const PRODUCTION_ITEMS_URL = "/production/items";
const PRODUCTION_MARKINGS_URL = "/production/markings";
const PRODUCTION_MARKING_SERIES_URL = "/production/marking_series";

const UPDATE_WORKSHOP_DELIVERY_URL = "/production/update_delivery";
const UPDATE_DELIVERY_ORDER_URL = "/production/set_delivery_order";

const UPDATE_MARKING_URL = "/production/update_marking";
const PRODUCTION_EXPORT_URL = "/production/export";
const UPDATE_PRODUCTION_STATUS_URL = "/production/update_status";
const REQUEST_INTEGRATION_URL = "/production/request_integration";
const UPDATE_DPD_DELIVERY_URL = "/production/update_dpd_delivery";
const PRODUCTION_STOCKS_URL = "/production/stocks";
const ADD_STOCK_ITEM_URL = "/production/add_stock_item";
const DELETE_STOCK_ITEM_URL = "/production/delete_stock_item";
const UPDATE_STOCK_ITEM_URL = "/production/update_stock_item";
const PRODUCTION_PRODUCTS_URL = "/production/products";

export async function getProductionItems() {
  return axios
      .get(PRODUCTION_ITEMS_URL)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return error.response.data;
      });
}
export async function getProductionMarkings() {
  return axios
    .get(PRODUCTION_MARKINGS_URL)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function getProductionMarkingSeries() {
  return axios
    .get(PRODUCTION_MARKING_SERIES_URL)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function updateWorkshopDelivered (
  deliveryUpdate: IUpdateProductionDelivery
) {
  const data = {
    update: deliveryUpdate,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(UPDATE_WORKSHOP_DELIVERY_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}


export async function updateDeliveryOrder (
    deliveryUpdate: IUpdateDeliveryOrder
) {
  const data = {
    update: deliveryUpdate,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
      .patch(UPDATE_DELIVERY_ORDER_URL, data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return error.response.data;
      });
}

export async function updateMarkingDone (
  markingUpdate: IUpdateMarkingDone
) {
  const data = {
    update: markingUpdate,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(UPDATE_MARKING_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}


export async function requestExport(params: IProdExportParameters) {

  console.log("requestExport");

  const data = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    view: params.view,
    start_date: params.startDate,
    end_date: params.endDate,
    seller_id: params.sellerId,
    order_number: params.orderNumber,
    marking_type: params.markingType,
  };

  return axios
      .patch(PRODUCTION_EXPORT_URL, data)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return error.response.data;
      });
}

export async function updateProductionStatus (
  statusChanges: IUpdateProductionStatus
) {
  const data = {
    changes: statusChanges,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(UPDATE_PRODUCTION_STATUS_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function requestItemsIntegration (
  itemIds: number[]
) {
  const data = {
    integrate_items: itemIds,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(REQUEST_INTEGRATION_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function saveDpdDelivery (
  dpdDelivery: ISetDpdDelivery
) {
  const data = {
    dpd_delivery: dpdDelivery,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(UPDATE_DPD_DELIVERY_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function getWorkshopStocks() {
  return axios
    .get(PRODUCTION_STOCKS_URL)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function requestAddStock (
  workshop: string,
  variantId: number
) {
  const data = {
    workshop: workshop,
    variant_id: variantId,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(ADD_STOCK_ITEM_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function requestDeleteStock (
  workshop: string,
  stockItemId: number
) {
  const data = {
    workshop: workshop,
    stock_item_id: stockItemId,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(DELETE_STOCK_ITEM_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function updateStockItem (
  changes: IUpdateStockItem
) {
  const data = {
    changes: changes,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(UPDATE_STOCK_ITEM_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}


export async function getDBProducts(brand: string, search: string) {
  const data = {
    brand: brand,
    search: search,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(PRODUCTION_PRODUCTS_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}
