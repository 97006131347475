import React from 'react';
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import {LogoCard} from "./LogoCard";
import {editedLogoHasChangesSelector, hasAutomaticLogoSelector, ILogoInfos} from "./logosSlice";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";

export const LogosList = (props: {
  logos: ILogoInfos[],
  draggable: boolean
}) => {

  const {logos, draggable} = props;

  const logoHasChanges = useSelector(editedLogoHasChangesSelector);
  const hasAutomaticLogo = useSelector(hasAutomaticLogoSelector);

  return(
    <Box sx={{width: '180px'}}>
      <Grid container spacing={1} className='logos'>
          { hasAutomaticLogo &&
              <div className='temporary'>
                <FormattedMessage id="logos.temporary-logos" />
              </div>
          }

          {logos && logos.map(logo =>
            <LogoCard logo={logo} key={`logo-card-${logo.id}`} editable={logoHasChanges === false} draggable={draggable} />
          )}

      </Grid>
    </Box>
  )
}
