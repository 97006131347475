import React from 'react';

import eldera from "../../images/brands/eldera.png";
import erima from "../../images/brands/erima.png";
import errea from "../../images/brands/errea.png";
import mizuno from "../../images/brands/mizuno.png";
import joma from "../../images/brands/joma.png";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {ProductBrands} from "../../app/catalog";
import {BrandMenuItem} from "./SelectBrand";
import {Button, Popover} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {FormattedMessage} from "react-intl";


export function BrandIcons() {

  // JOMA
  const [anchorElJoma, setanchorElJoma] = React.useState<HTMLButtonElement | null>(null);
  const handleClickJoma = (event: React.MouseEvent<HTMLButtonElement>) => setanchorElJoma(event.currentTarget);
  const handleCloseJoma = () => setanchorElJoma(null);
  const openJoma = Boolean(anchorElJoma);
  const jomaId = openJoma ? 'simple-popover' : undefined;


  // JOMA
  const [anchorElMizuno, setanchorElMizuno] = React.useState<HTMLButtonElement | null>(null);
  const handleClickMizuno = (event: React.MouseEvent<HTMLButtonElement>) => setanchorElMizuno(event.currentTarget);
  const handleCloseMizuno = () => setanchorElMizuno(null);
  const openMizuno = Boolean(anchorElMizuno);
  const mizunoId = openMizuno ? 'simple-popover' : undefined;



  return (
    <div className='brand-icons'>
      {/*<BoudaToolTip title='Catalogue Eldera' placement="bottom-end" arrow>*/}
      {/*  <a href='https://www.calameo.com/eldera/read/003341138b996f899890a'*/}
      {/*     target='_blank' rel="noreferrer" className='brand-link'>*/}
      {/*    <div className='brand-logo'>*/}
      {/*      <img src={eldera} />*/}
      {/*    </div>*/}
      {/*  </a>*/}
      {/*</BoudaToolTip>*/}

      <Button sx={{minWidth: 'unset'}} className='brand-logo' aria-describedby={jomaId} variant="contained" onClick={handleClickJoma} >
        <img src={joma}/>
      </Button>

      <Popover
        id={jomaId}
        open={openJoma}
        anchorEl={anchorElJoma}
        onClose={handleCloseJoma}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            style: {marginTop: '5px'}, // Apply the custom styles to the popover content
          },
        }}
      >
        <div className='brand-icons-popover'>
          <a href='https://www.dagoba.app/docs/catalogue-joma-teamwear' target='_blank' rel="noreferrer" className='brand-link'>
            <Typography sx={{ p: 2, color:'white' }}>
              <FormattedMessage id="global.teamwear" />
            </Typography>
          </a>
          <a href='https://www.dagoba.app/docs/catalogue-joma-running' target='_blank' rel="noreferrer" className='brand-link'>
            <Typography sx={{ p: 2, color:'white' }}>
              <FormattedMessage id="global.athle" />
            </Typography>
          </a>
          <a href='https://www.dagoba.app/docs/catalogue-joma-sports-de-raquette' target='_blank' rel="noreferrer" className='brand-link'>
            <Typography sx={{ p: 2, color:'white' }}>
              <FormattedMessage id="global.racket" />
            </Typography>
          </a>
        </div>
      </Popover>


      <Button sx={{minWidth: 'unset'}} className='brand-logo' aria-describedby={mizunoId} variant="contained" onClick={handleClickMizuno} >
        <img src={mizuno}/>
      </Button>

      <Popover
        id={mizunoId}
        open={openMizuno}
        anchorEl={anchorElMizuno}
        onClose={handleCloseMizuno}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            style: {marginTop: '5px'}, // Apply the custom styles to the popover content
          },
        }}
      >
        <div className='brand-icons-popover'>
          <a href='https://www.dagoba.app/docs/catalogue-mizuno' target='_blank' rel="noreferrer" className='brand-link'>
            <Typography sx={{ p: 2, color:'white' }}>
              <FormattedMessage id="global.teamwear" />
            </Typography>
          </a>
          <a href='https://www.dagoba.app/docs/mizuno-arts-martiaux' target='_blank' rel="noreferrer" className='brand-link'>
            <Typography sx={{ p: 2, color:'white' }}>
              <FormattedMessage id="global.martial" />
            </Typography>
          </a>
        </div>
      </Popover>

      <BoudaToolTip title='Catalogue Erima' placement="bottom-end" arrow>
        <a href='https://katalog.erima.de/erima-teamsport-2024-fr/68567108'
           target='_blank' rel="noreferrer" className='brand-link'>
          <div className='brand-logo'>
            <img src={erima}/>
          </div>
        </a>
      </BoudaToolTip>

      <BoudaToolTip title='Catalogue Erreà' placement="bottom-end" arrow>
        <a href='https://www.dagoba.app/docs/catalogue-errea'
           target='_blank' rel="noreferrer" className='brand-link'>
          <div className='brand-logo'>
            <img src={errea}/>
          </div>
        </a>
      </BoudaToolTip>

    </div>
  )

}
