import React from 'react';
import {useDispatch} from "react-redux";
import {FormControl, FormHelperText} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
  currentSellerSelector, selectSeller, SELLER_NAMES,
} from "./productionSlice";
import {useAppSelector} from "../../../app/hooks";
import TextField from "@mui/material/TextField";

export function SelectSeller() {

  const currentSeller = useAppSelector(currentSellerSelector);
  const dispatch = useDispatch();

  const changeSeller = (event: any) => {
    dispatch(selectSeller(event.target.value))
  };

  return (
    <FormControl sx={{ mr: 1, minWidth: 150}} size="small" className='select-tool search-seller'>
      <FormHelperText sx={{ml:0}}>Vendeur</FormHelperText>
      <TextField id="select-boxes-order"  size="small" select value={currentSeller} onChange={changeSeller}>
        {SELLER_NAMES.map((seller) => (
          <MenuItem key={`seller-${seller.id}`} value={seller.id}>{seller.name}</MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
}
