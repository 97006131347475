import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import {useIntl} from "react-intl";
dayjs.extend(utc);

interface TimeProgressBarProps {
  startingTime: string; // starting time in full date UTC format
  duration: number; // duration in seconds
  startingLabel: string; // label to display before the progress starts
  labels: string[]; // array of labels for different steps
  height: number; // height of the progress bar
  backgroundColor: string; // background color of the progress bar
  barColor: string; // color of the progress bar
  borderColor: string; // border color of the progress bar
  completeProgress: boolean; // boolean to determine if the bar should go to 100%
}

const PROGRESS_LIMIT = 97;

const TimeProgressBar: React.FC<TimeProgressBarProps> = (
  { startingTime, duration, startingLabel, labels, height, backgroundColor, barColor, borderColor, completeProgress }
) => {
  const intl = useIntl();
  const [progress, setProgress] = useState(0);
  const [timeLeft, setTimeLeft] = useState('');
  const [currentLabel, setCurrentLabel] = useState(labels[0]);
  const [beforeStart, setBeforeStart] = useState(true);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    // for debugging purposes, display the starting time and duration in human-readable format
    // console.log("%cTimeProgressBar with starting time: " + dayjs.utc(startingTime).format('LTS') + " with currentTime: " + dayjs().utc().format('LTS') + " and duration " + duration + " secs", "color: red");

    const startTimeMs = dayjs.utc(startingTime).valueOf();
    const endTimeMs = startTimeMs + duration * 1000;
    const labelInterval = duration / labels.length;

    // add a log to show the actual end time in human-readable format
    // console.log("%cTimeProgressBar with endTime: " + dayjs.utc(endTimeMs).format('LTS'), "color: red");

    const updateProgress = () => {
      const currentTimeMs = dayjs().valueOf();
      if (currentTimeMs < startTimeMs) {
        setBeforeStart(true);
        return;
      }
      setBeforeStart(false);

      const elapsedMs = currentTimeMs - startTimeMs;
      let progressPercentage = (elapsedMs / (duration * 1000)) * 100;
      if (!completeProgress) {
        progressPercentage = Math.min(progressPercentage, PROGRESS_LIMIT);
      }
      setProgress(Math.min(progressPercentage, 100));

      if (progressPercentage >= 100) {
        setIsFinished(true);
      }

      const remainingMs = endTimeMs - currentTimeMs;
      const remainingSeconds = Math.max(Math.ceil(remainingMs / 1000), 0);
      const minutes = Math.floor(remainingSeconds / 60);
      const seconds = remainingSeconds % 60;
      setTimeLeft(`${intl.formatMessage({id: "global.time-left"})} ${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);

      const elapsedSeconds = Math.floor(elapsedMs / 1000);
      const labelIndex = Math.min(Math.floor(elapsedSeconds / labelInterval), labels.length - 1);
      setCurrentLabel(labels[labelIndex]);
    };

    const timer = setInterval(updateProgress, 100);
    updateProgress(); // Initial call to set progress immediately

    return () => clearInterval(timer);
  }, [startingTime, duration, labels, completeProgress]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 1, textAlign: 'center' }}>
        {beforeStart ? startingLabel : (progress < PROGRESS_LIMIT && !isFinished && timeLeft)}
      </Box>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: height, // Custom height
          backgroundColor: backgroundColor, // Custom background color
          border: `1px solid ${borderColor}`, // Custom border color
          '& .MuiLinearProgress-bar': {
            backgroundColor: barColor // Custom bar color
          }
        }}
      />
      <Box sx={{ mt: 1, textAlign: 'center' }}>
        {currentLabel}
      </Box>
    </Box>
  );
};

export default TimeProgressBar;