import React, {FormEvent, useCallback, useState} from 'react';
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {FormattedMessage} from "react-intl";
import { styled } from '@mui/material/styles';
import {HumanFileSize} from "../../app/utils";
import './utils.scss';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DOMPurify from "dompurify";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const FileUpload = (props:{
  inviteId: string,
  extension: string | null,
  file: File | undefined,
  currentFileName: string,
  saveFile: (file: File|null) => void,
  removeFile: () => void
}) => {
  const { inviteId, extension, file, currentFileName, saveFile, removeFile } = props;
  // const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('%cSelected file for type', 'color: green', extension);
    if (e.target.files) {
      // setFile(e.target.files[0]);
      saveFile(e.target.files[0]);
    }
  };

  const clearFile = ( e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = e.target as HTMLInputElement
    element.value = ''
  }

  //
  // const handleRemoveFile = () => {
  //   console.log('%cRemove file of type', 'color: red', extension);
  //   // setFile(null);
  //   removeFile();
  // }

  // console.log('%cRender file upload', 'color: blue', extension, file ? file.name : 'no file');

  return(
    <Box className='utils-upload-files'>
      <Button key={`select-file-${extension}`} component="label" variant="contained"
              startIcon={<CloudUploadIcon />} className='upload-button'>
        <FormattedMessage id={inviteId} />
        <VisuallyHiddenInput key={`input-file-${extension}`} type="file" accept={`.${extension}`}
                             onInput={handleFileChange} onClick={clearFile} />

      </Button>

      <Box className='utils-upload-file-names'>
        <Box className='current'>
          {currentFileName}
        </Box>
        <Box className='selected'>
          {file && <>
            {file.name} ({HumanFileSize(file.size)})
	          <IconButton key={`remove-file-${extension}`} onClick={removeFile} sx={{size: 'small'}}>
		          <DeleteIcon/>
	          </IconButton>
          </>}
        </Box>

      </Box>
    </Box>
  )
}

export default FileUpload;
