import React, {useEffect, useState} from 'react';
import ColorTag from "./ColorTag";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GenderDisplayHash, IProduct, ProductPricesString, IVariantInfos} from "../../app/product";
import {openProduct} from "../productDetails/productDetailsSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {BoudaToolTip} from "../utils/BoudaToolTip";


function GenderVersion(props: {
  product: IProduct,
  hoverCallback?: (product: IProduct, hovering: boolean) => void}) {

  const {product, hoverCallback} = props
  const dispatch = useDispatch<AppDispatch>();

  if (product.gender !== null && product.gender > -1) {
    return(
      <BoudaToolTip key={`ov${product.id}`} placement="left" title={product.title} arrow>
        <FontAwesomeIcon icon={GenderDisplayHash[product.gender]} size='1x' className='adult gender'
                         onMouseEnter={() => hoverCallback && hoverCallback(product, true)}
                          onMouseLeave={() => hoverCallback && hoverCallback(product, false)}
                         onClick={(e) => {
                           e.stopPropagation();
                           dispatch(openProduct(product));
                         }} />
      </BoudaToolTip>
    )
  }
  
  return null;
}

export default GenderVersion;