import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import "@fontsource/roboto-condensed/300.css";
import "@fontsource/roboto-condensed/400.css";
import "@fontsource/roboto-condensed/700.css";
import './styles/index.scss';
import './styles/app.scss';
import './styles/fonts.css';
import './styles/catalog.scss';
import './styles/product.scss';
import './styles/selection.scss';
import './styles/inline_edit.scss';
import LocaleWrapper from "./features/locale/LocaleWrapper";

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  // <React.StrictMode> QR code not working in strict mode
    <Provider store={store}>
      <LocaleWrapper>
        <App />
      </LocaleWrapper>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
