import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
  filterShippedMarkingsSelector,
  MARKINGS_TYPES, markingsTypeSelector, selectMarkingsProductionDay, selectMarkingType, toggleFilterShippedMarkings,
} from "./productionSlice";
import {useAppSelector} from "../../../app/hooks";
import TextField from "@mui/material/TextField";
import {RootState} from "../../../app/store";

export function SelectMarkingType() {

  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const canSelectType = currentUser && currentUser.view_transfers && currentUser.view_embroidery && currentUser.view_stiching;

  const dispatch = useDispatch();
  const currentMarkingsType = useAppSelector(markingsTypeSelector);
  const filterShippedMarkings = useAppSelector(filterShippedMarkingsSelector);


  const changeMarkingType = (event: any) => {
    dispatch(selectMarkingType(event.target.value))
  };

  const changeFilter = (event: any) => {
    dispatch(toggleFilterShippedMarkings())
  };

  return (<>
    {canSelectType &&
      <FormControl sx={{ mr: 1, minWidth: 150}} size="small" className='select-tool search-marking'>
        <FormHelperText sx={{ml:0}}>Type</FormHelperText>
        <TextField id="select-marking-type"  size="small" select
                   value={currentMarkingsType || 0} onChange={changeMarkingType}>
          {MARKINGS_TYPES.map((m_type, i) => (
            <MenuItem key={`sel-m-type${i+1}`} value={m_type.code}>{m_type.name}</MenuItem>
          ))}
        </TextField>
      </FormControl>}

    <FormControl sx={{ mr: 1, minWidth: 150}} size="small" className='select-tool search-marking'>
      <FormHelperText sx={{ml:0}}>Filtrer</FormHelperText>
      <FormControlLabel control={
        <Checkbox checked={filterShippedMarkings} onChange={changeFilter}
                  inputProps={{ 'aria-label': 'controlled' }} />
      } label="En cours" />
    </FormControl>
  </>
  )

}
