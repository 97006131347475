import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {faPerson} from "@fortawesome/free-solid-svg-icons/faPerson";
import {faPersonDress} from "@fortawesome/free-solid-svg-icons/faPersonDress";
import {faChildReaching} from "@fortawesome/free-solid-svg-icons/faChildReaching";
import {faChildren} from "@fortawesome/free-solid-svg-icons/faChildren";


export interface IVariantInfos {
  id: string;
  color: string;
  friendlyColor: string;
  color1: string;
  color2: string;
  hexColor1: string;
  hexColor2: string;
  colorMatch: number;
  sizes: string[];
  images: string[];
}


export interface IProduct {
  id: number;
  sku: string;
  title: string;
  description:string;
  brand: string;
  bestSeller: boolean;
  category: string;
  subCategory: string;
  style: string;
  fabric: string;
  grammage: string;
  imgScaleDown: number;
  gender: number,
  connection: string,
  connectedProductIds: number[];
  otherVersions: IProduct[];
  prices: number[];
  variants: IVariantInfos[];
  boutiques: string[];
  colorMatch: number;
}

export const VariantForColor = (product: IProduct, color: string) => {
  return(product.variants.find(variant => variant.color === color))
}


export const PriceFormatted = (number: number | null | undefined, no_currency?: boolean | undefined) => {
  const addCurrency = no_currency === undefined || !no_currency;

  if (number) {
    return Number(number / 100).toFixed(2).toString().replace(/\./, ',')
      + (addCurrency ? " €" : '');
  } else {
    return '';
  }
}

export const ProductPricesString = (product: IProduct, margin?: number | undefined, addonsPrice?: number | undefined) =>
  product.prices.map(price => PriceFormatted(price + (margin || 0) + (addonsPrice || 0))).join(' / ');

export const GenderDisplayHash: {[key: number]: IconDefinition} = {
  0: faChildren,
  1: faPerson,
  2: faPersonDress,
  3: faChildReaching
};



