import axios from "./axios";
import {CLIENT_ID, CLIENT_SECRET} from "./global";
import {ILogoInfos} from "../features/logos/logosSlice";

const ALL_LOGOS_URL = "/boudists/all_logos";
const UPDATE_LOGO_URL = "/boudists/update_logo";
const POST_LOGO_FILES_URL = "/boudists/post_logo_files";
const DELETE_LOGO_URL = "/boudists/delete_logo";

export async function getBoudistLogos(searchVendor: string) {
  return axios
    .get(ALL_LOGOS_URL + "?vendor_id=" + searchVendor)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function updateLogo(logo: ILogoInfos) {
  const data = {
    logo: logo,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(UPDATE_LOGO_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function postLogoFile(logo: ILogoInfos) {

  const formData = new FormData();
  formData.append('logo_id', logo.id.toString());
  formData.append('client_id', CLIENT_ID || '');
  formData.append('client_secret', CLIENT_SECRET || '');
  ['pdfFile','embFile', 'dstFile'].forEach((fileType) => {
    const file = logo[fileType as keyof ILogoInfos];
    // test if the file is a File object
    if (file instanceof File) {
      formData.append(fileType, file);
    }
  })

  return axios
    .post(POST_LOGO_FILES_URL, formData)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}


export async function deleteLogo(logoId: number) {
  const data = {
    logo_id: logoId,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(DELETE_LOGO_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}