import React, {useEffect, useRef, useState} from 'react';
import Box from "@mui/material/Box";
import {
  CHECKED_ITEM_COLOR,
  COLISSIMO_URL, ColorForTrackingNumber,
  ColorsForOrderStatus, DPD_TRACKING_URL, EMBROIDERY,
  IArticleLogo,
  IArticlePerso,
  INextProductionStatus,
  IPrintInfos,
  ISelectedItem,
  ISingleItem,
  IUpdateProductionStatus,
  IWorkshopBox, nameForMarkingTechnique,
  nextOrderStatusesSelector, PRODUCTION_DELIVERED,
  PRODUCTION_PROBLEM, savingSelector, selectItem,
  setItemsProductionStatus, SOURCE_DAGOBA, SOURCE_ORIFLAM,
} from "./productionSlice";
import {CircularProgress, Collapse} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from "@mui/material/IconButton";
import {openInFullScreen, openSnackBar} from "../../global/globalSlice";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../app/store";
import {ScanQRCodeButton} from "./ScanQRCode";
import DpdSvgUnicolor from "../../../images/DpdSvgUnicolor";
import ColissimoSvgUnicolor from "../../../images/ColissimoSvgUnicolor";
import {IUser} from "../../sessions/sessionSlice";
import {lightGreen} from "@mui/material/colors";

export const ProductItemHeader = (props: {
    item: ISelectedItem,
    nbItems: number,
    withSupplierRef: boolean,
    fullScreen: boolean,
    currentProdItem: ISingleItem,
    handleExpandClick?: () => void
    }) => {

  const {item, nbItems, withSupplierRef, fullScreen, currentProdItem, handleExpandClick} = props;
  const dispatch = useDispatch<AppDispatch>();
  const colors = item.checked ? {fgColor: 'black', bgColor: CHECKED_ITEM_COLOR } : ColorsForOrderStatus(currentProdItem.orderStatus)

  const trackingNumberColor = ColorForTrackingNumber(item.shippingNumber)
  const hasEmbroidery = !!item.article.persos.find(perso => perso.type === 'logo' && perso.logoInfos?.technique === EMBROIDERY) ||
    !!item.article.logos.find(logo => logo.logoInfos?.technique === EMBROIDERY);

  return (
      <Box className={`article-title ${withSupplierRef ? '' : 'in-tool-tip'}`}>
        <Box className='left'>
          <Box className='qty' sx={{color: colors.fgColor, bgcolor: colors.bgColor}}
               onClick={() => dispatch(selectItem(item.id))}>
            {nbItems}
          </Box>
          <Box className={`title ${item.checked ? 'checked' : ''}`} onClick={handleExpandClick}>
            {item.article.variant}{withSupplierRef && <> [<span className='ref'>{item.article.ref}</span>] </>}
          </Box>
          {hasEmbroidery && <Box className='embroidery'>B</Box>}
        </Box>

        <Box sx={{display: 'flex', alignItems: 'center'}}>

          {item.manyDeliveries && <Box className='many-deliveries' sx={{color: trackingNumberColor}}>
            {item.shippingMode === 'DPD' &&
				        <a href={DPD_TRACKING_URL + item.shippingNumber.replace(/\s/g, "")} target='_blank' rel='noreferrer'>
                  {DpdSvgUnicolor(24, trackingNumberColor)}
				        </a>
            }
            {item.shippingMode === 'Colissimo' &&
				        <a href={COLISSIMO_URL + item.shippingNumber} target='_blank' rel='noreferrer'>
                  {ColissimoSvgUnicolor(24, trackingNumberColor)}
				        </a>
            }
	        </Box>}

          <Box className={`item-procure S${item.procureSource}`}>
            {item.procureSource === SOURCE_DAGOBA && 'STOCK DAGOBA' ||
                item.procureSource === SOURCE_ORIFLAM && 'ORIFLAM' ||
                <>{item.procureDate} ({item.supplier})</>
            }
          </Box>

          {!fullScreen && currentProdItem &&
              <IconButton size='large' sx={{m: 0, p: 0}} aria-label="open" onClick={() =>
                  dispatch(openInFullScreen({
                    name: 'ProductionItem',
                    displayedId: currentProdItem.prodItemId,
                    param: 'fromItem'
                  }))
              }>
                <ZoomOutMapIcon sx={{color: 'black'}}/>
              </IconButton>
          }
        </Box>
      </Box>
  )
}


const ProductionItem = (props: {
  orderNumber?: number,
  item: ISelectedItem,
  orderStatus?: number,
  prodItemId?: number,
  nbItems: number,
  selected: boolean,
  first: boolean,
  fullScreen: boolean,
  viewFrom?: string,
  assignedWorkshopBox: IWorkshopBox | undefined,
  user: IUser | undefined
}) => {

  const itemRef = useRef(null);
  useEffect(() => {
    if (itemRef.current && selected && fullScreen) {
      const element = itemRef.current as HTMLElement ;
      element.scrollIntoView(true)
    }
  }, [itemRef.current]);

  const dispatch = useDispatch<AppDispatch>();
  const {orderNumber, item, orderStatus, prodItemId, nbItems,
    selected, first, fullScreen, viewFrom, assignedWorkshopBox, user} = props

  const cannotUpdateOrders = !user || !user.update_orders

  const saving = useSelector(savingSelector);

  const [expanded, setExpanded] = useState(fullScreen && selected);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // console.log("%cRendering ProductionItem of " + item.article.ref + " with orderStatus " + orderStatus, "color:blue")
  // console.log("%cItems parts: " + item.singleItems.map((i) => i.orderStatus).join(', '), "color:green")

  const defaultNum = prodItemId ?
    (item.singleItems.findIndex(item => item.prodItemId === prodItemId) + 1 || 1) :
    (orderStatus ? (item.singleItems.findIndex(item => item.orderStatus === orderStatus) + 1 || 1)
      : 1);

  useEffect(() => {
    setSelectedNum(defaultNum);
  }, [item.singleItems]);

  const [selectedNum, setSelectedNum] = useState(defaultNum);

  const currentProdItem = item.singleItems[selectedNum - 1];

  const colors = orderStatus ? ColorsForOrderStatus(orderStatus) : {bgColor: '#F0F0F0', fgColor: 'black'}

  const disabledNums = orderStatus ? item.singleItems.filter(item => item.orderStatus !== orderStatus).map(item => item.num) : [];

  const nextOrderStatuses: INextProductionStatus[] = useSelector(state =>
      nextOrderStatusesSelector(state, {orderStatus : currentProdItem?.orderStatus || 0, procureSource: item.procureSource}));

  const justProductPhoto = viewFrom === 'fromQRCode' && prodItemId &&
    item.singleItems.find(item => item.prodItemId === prodItemId)?.orderStatus === PRODUCTION_DELIVERED;


  if (!currentProdItem) {
    return(<div>ARTICLE NON TROUVÉ</div>)
  }

  const textDetails = (perso: IArticlePerso) => {
    return(<>
      <Box className='first-line'>
        <Box className='name'>{perso.name}</Box>
        {perso.perso_png_url && <Box className='content logo' sx={{display: 'flex', flexDirection: 'column'}}>
          <Box className='perso-preview' sx={{pt:1, pb: 1}}>
            <img src={perso.perso_png_url} />
          </Box>
	        <Box>
            {perso.perso_png_url.split('~')[1].split('.png')[0].replace('-', ' x ').replace('_', '  -  ')}
          </Box>
        </Box> ||
        <Box className='content'>
          <Box className='value'>
            {perso.value}
          </Box>
          <Box className='color'>
            <Box className='color-box' sx={{backgroundColor: perso.textInfos?.fontColor || 'black'}}></Box>
            {perso.textInfos?.fontColor}
          </Box>
        </Box>}
      </Box>
      {!perso.perso_png_url &&
      <Box className='second-line'>
        <a href={perso.textInfos?.fontFile} target="_blank" rel="noreferrer">
          <FileDownloadIcon fontSize='small' />
          {perso.textInfos?.fontName}
        </a>
      </Box>}
    </>)

  }

  const printInfos = (infos: IPrintInfos) => {
    if (!infos.name) return null;

    return(<>
      <a href={infos.file} target="_blank" rel="noreferrer">
        <FileDownloadIcon fontSize='small' />
        {infos.name}
      </a>
    </>)
  }

  const logoDetails = (logo: IArticleLogo) => {
    return(<>
      <Box className='first-line'>
        <Box className='name'>
          <Box className='title'>
            {logo.name}
          </Box>
          <Box className='technique'>
            {nameForMarkingTechnique(logo.logoInfos?.technique)}
          </Box>
        </Box>
        <Box className='content logo' sx={{backgroundColor: logo.bgColor}}>
          <img src={logo.logoInfos?.image} alt={logo.name} />
        </Box>
      </Box>
      {logo.logoInfos &&
        <Box className='second-line'>
          {logo.logoInfos.description &&
            <Box className='description'>
              {logo.logoInfos.description}
            </Box>}
          {[logo.logoInfos.print,
            logo.logoInfos.print2,
            logo.logoInfos.print3,
            logo.logoInfos.print4,
            logo.logoInfos.print5,
            logo.logoInfos.print_bouda].filter((print) => !!print.file).map((print, index) =>
              <Box key={`print-${index}`} className='print'>
                {printInfos(print)}
              </Box>
          )}
        </Box>
      }
    </>)
  }

  async function saveProductionStatus(newStatus: IUpdateProductionStatus) {
    const response = await dispatch(setItemsProductionStatus(newStatus)) as any;

    if (response.type === 'production/setItemsProductionStatus/fulfilled') {
      // check if other items are already in the order status of the new status, if yes collapse the item
      const firstOtherItemNotInNewStatus = item.singleItems
          .filter(si => !newStatus.prodItemIds.includes(si.prodItemId) &&
              si.orderStatus < newStatus.newOrderStatus)?.[0];

      // In full screen mode (== order view) we open the next item to work on or the whole order with no item opened
      if (fullScreen) {
        if (firstOtherItemNotInNewStatus) {
          dispatch(openInFullScreen({name: 'ProductionItem', displayedId: firstOtherItemNotInNewStatus.prodItemId, param: 'fromItem'}))
        } else {
          dispatch(openInFullScreen({name: 'ProductionOrder', displayedId: orderNumber, param: 'fromItem'}))
        }
      }
    }
  }

  const handleActionClick = (nextStatus: INextProductionStatus) => () => {

    if (saving || cannotUpdateOrders) return;

    if (nextStatus === undefined || nextStatus.status === undefined || nextStatus.status.orderStatus === undefined) return;

    let updateStatus = {
      newOrderStatus: nextStatus.status.orderStatus,
      newWorkshopBoxId: null,
      prodItemIds: [currentProdItem.prodItemId]
    } as IUpdateProductionStatus;

    switch (nextStatus.action) {
      case 'changeStatus':
        updateStatus.newWorkshopBoxId = -1;
        break;

      case 'confirmStoreInBox':
        updateStatus.newWorkshopBoxId = assignedWorkshopBox?.id || null;
        break;

      default:
        return;
    }

    // console.log("Before saveProductionStatus")
    saveProductionStatus(updateStatus);
    setExpanded(false);
  }

  const itemClassName = 'article ' + (expanded ? 'expanded ' : '') + (first ? 'first' : '');

  const prodItemCard = (prodItem: ISingleItem, index: number) => {
    const colors = ColorsForOrderStatus(prodItem.orderStatus)
    const disabled = disabledNums.includes(prodItem.num);
    const numClassName = (disabled ? ' disabled' : '') + (prodItem.num === selectedNum ? ' selected' : '');
    return(
      <Box key={`prod-item-${index}`}
           className={`prod-item ${numClassName}`}
           onClick={() => !disabled && setSelectedNum(index + 1)}
           sx={{backgroundColor: colors.bgColor, color: colors.fgColor }}>
        N° {prodItem.num} / {item.quantity}
      </Box>
    )
  }

  // console.log("%cRendering ProductionItem of " + item.article.ref + " for " + selectedNum +  " with selected " + selected + " and expanded = " + expanded, "color:blue")

  // console.log("%cRendering ProductionItem with cannotUpdateOrders = " + cannotUpdateOrders, "color:blue")

  return (
    <Box ref={itemRef} className={itemClassName} >
      <ProductItemHeader item={item} nbItems={nbItems} withSupplierRef={true}
                         fullScreen={fullScreen} currentProdItem={currentProdItem}
                         handleExpandClick={() => setExpanded(!expanded)} />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Box className='article-details'>
            {justProductPhoto && <Box className='just-photo'>
              <img src={item.article.supplierPhoto} />
            </Box> ||
              <>
                <Box className='markings'>

                  <Box className='logos'>
                    {item.article.persos.map((perso, index) =>
                      <Box key={`perso-${index}`} className='marking'>
                        {perso.type === 'text' ? textDetails(perso) :
                          logoDetails({
                            name: perso.name,
                            logoInfos: perso.logoInfos,
                            bgColor: perso.bgColor
                          } as IArticleLogo)}
                      </Box>
                    )}
                  </Box>

                  <Box className='logos'>
                    {item.article.logos.map((logo, index) =>
                      <Box key={`logo-${index}`} className='marking'>
                        {logoDetails(logo)}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box className='preview'>
                  <img src={item.article.preview} />
                  {item.article.productionNotes &&
                    <Box className='notes'>
                      {item.article.productionNotes}
                    </Box>
                  }
                </Box>
              </>
          }
          </Box>
          {item.singleItems.length > 1 &&
            <Box className='article-prod-items'>
              {item.singleItems.map((prodItem, index) => prodItemCard(prodItem, index))}
            </Box>
          }
          <Box className='article-actions'>
            {nextOrderStatuses.map((nextProdStatus, index) => {
              const declare_problem = nextProdStatus.status?.orderStatus === PRODUCTION_PROBLEM;
              if (nextProdStatus) {

                let actionPrompt = '';

                if (assignedWorkshopBox && nextProdStatus.action === 'selectWorkshopBox') {
                  return null;
                }

                if (nextProdStatus.action === 'confirmStoreInBox') {
                  if (assignedWorkshopBox) {
                    actionPrompt = `Mettre dans le bac ${assignedWorkshopBox.name}`;
                  } else {
                    return null;
                  }
                }

                return (
                  <Box key={`item-${item.id}-status-action-${index} ${declare_problem ? 'problem' : ''}`}
                       className='item-action'
                       onClick={handleActionClick(nextProdStatus)}
                       sx={{
                         textAlign: 'center',
                         backgroundColor: nextProdStatus.status?.bgColor || 'black',
                         color: nextProdStatus.status?.fgColor || 'white',
                         opacity: (saving || cannotUpdateOrders) ? 0.3 : 1,
                       }}>
                    <Box>
                      {nextProdStatus.action === 'selectWorkshopBox' &&
                        <ScanQRCodeButton size='small' precedingText={nextProdStatus.status?.statusAction} targetType='workshop_box'
                                          targetId={assignedWorkshopBox?.id} objectId={currentProdItem.prodItemId}/>
                        || actionPrompt || nextProdStatus.status?.statusAction || nextProdStatus.status?.statusName || nextProdStatus.action
                      }
                      {saving && <CircularProgress color="inherit" sx={{marginLeft: '10px'}} size={20}/>}
                    </Box>
                  </Box>
                )
              }}
            )}
          </Box>
        </Box>
      </Collapse>
    </Box>
  )
}


export default ProductionItem;