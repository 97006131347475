import Button, {ButtonProps} from '@mui/material/Button';
import { styled } from '@mui/material/styles';

// export const CustomButton = styled(Button)({
//   backgroundColor: 'black',
//   color: 'white',
//   '&:hover': {
//     backgroundColor: 'grey',
//   },
// }) as typeof Button;

interface CustomButtonProps extends ButtonProps {
  textcolor?: string;
  backgroundcolor?: string;
  hoverbackgroundcolor?: string;
}

export const CustomButton = styled(Button)<CustomButtonProps>(
  ({ theme, textcolor, backgroundcolor, hoverbackgroundcolor }) => ({
    color: textcolor || 'white',
    backgroundColor: backgroundcolor || 'black',
    '&:hover': {
      backgroundColor: hoverbackgroundcolor || 'grey',
    },
  })
);

// export default CustomButton;