import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import produce from "immer";
import {RootState} from "../../app/store";
import {
  createJuniorVersionRequest,
  fetchProductDefinitionRequest,
  fetchProducts, IProductPropertyUpdate, updateProductDefinitionRequest, updateProductPropertyRequest,
} from "../../app/productAPI";
import {IProduct} from "../../app/product";
import {
  CatalogCategories,
  ICatalogState,
  ICategoryInfos,
  ICategoryState,
  filter_products,
  Statuses,
  IProductDefinition,
  getProductById,
  ProductDefinitionDefault,
  IProductSearch,
  updateStateProductsProperty, IProductColorDefinition, InitialColorDefinition, InitialImageDefinition
} from "../../app/catalog";
import React from "react";
import {openSnackBar} from "../global/globalSlice";
import {changedProperties, changedProperty} from "../../app/utils";
import {HexCode, ProductColors} from "../../app/color";

const allCategories = CatalogCategories.map(category => ({
    category: category.code,
    products: [] as IProduct[],
    status: Statuses.Initial
} as ICategoryState));

const initialState: ICatalogState = {
  currentCategory: 'tshirts',
  categories: allCategories,
  primaryColor: "yellow",
  primaryColorHex: "#FFFF40",
  secondaryColor: "black",
  secondaryColorHex: "#000000",
  currentGender: 0,
  currentStyle: 'all',
  currentBrand: 'all',
  filterKey: "",
  filteredProducts: [] as IProduct[],
  status: Statuses.Initial,
  initialDefinition:null,
  shownDefinition: null,
  definitionHasChanges: false,
  definitionSaving: false,
};

export const fetchProductsAsync = createAsyncThunk(
    "products/fetchProducts",
    async (category_code: string, thunkAPI) => {
      const response = await fetchProducts(category_code);
      if (response.error) {
        // The value we return becomes the `rejected` action payload
        thunkAPI.dispatch(openSnackBar({severity: 'error', message: "Could not fetch products of " + category_code}));

        return thunkAPI.rejectWithValue(response);
      }

      // The value we return becomes the `fulfilled` action payload
      return response;
    })

/////////////////////////////////////////////////////////////////////////////////////////////////
// Update many products properties like delete or withdraw
/////////////////////////////////////////////////////////////////////////////////////////////////

export const updateProductsProperty = createAsyncThunk(
  "catalog/updateProductsProperty",
  async (payload: IProductPropertyUpdate, thunkAPI) => {
    const response = await updateProductPropertyRequest(payload);

    if (response.error) {
      // The value we return becomes the `rejected` action payload
      thunkAPI.dispatch(openSnackBar({severity: 'error', message: "Product not updated"}));

      return thunkAPI.rejectWithValue(response);
    }

    thunkAPI.dispatch(openSnackBar({severity: "success",message: "Product updated successfully"}));

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

/////////////////////////////////////////////////////////////////////////////////////////////////
// Product Definition
/////////////////////////////////////////////////////////////////////////////////////////////////

export const fetchProductDefinition = createAsyncThunk(
  "products/fetchProductDefinition",
  async (payload: IProductSearch, thunkAPI) => {
    const response = await fetchProductDefinitionRequest(payload);
    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  })

export const updateProductDefinition = createAsyncThunk(
  "catalog/updateProductDefinition",
  async (payload: IProductDefinition, thunkAPI) => {
    const response = await updateProductDefinitionRequest(payload);

    // console.log("updateProduct returned => " + JSON.stringify(response));

    if (response.error) {
      // The value we return becomes the `rejected` action payload
      thunkAPI.dispatch(openSnackBar({severity: 'error', message: "Product not updated"}));

      return thunkAPI.rejectWithValue(response);
    }

    thunkAPI.dispatch(openSnackBar({severity: "success",message: "Product updated successfully"}));

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const createJuniorVersion = createAsyncThunk(
  "catalog/createJuniorVersion",
  async (payload : any, thunkAPI) => {
    const response = await createJuniorVersionRequest(payload.id, payload.juniorSize);

    if (response.error) {
      // The value we return becomes the `rejected` action payload
      thunkAPI.dispatch(openSnackBar({severity: 'error', message: "Junior version not created"}));

      return thunkAPI.rejectWithValue(response);
    }

    thunkAPI.dispatch(openSnackBar({severity: "success",message: "Junior version created successfully"}));

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

const updateProductDefinitionHasChanges = (state: ICatalogState) => {
  if (state.shownDefinition === null || state.shownDefinition[0] === null) {
    state.definitionHasChanges = false;
  } else {
    state.definitionHasChanges = (JSON.stringify(state.shownDefinition[0]) !== JSON.stringify(state.initialDefinition))
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////
// Reducers
/////////////////////////////////////////////////////////////////////////////////////////////////

export const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    selectCategory: (state, action) => {
      // console.log("selectCategory => " + action.payload);
      // state.currentCategory = action.payload === 'all' ? null : action.payload;
      state.currentCategory = action.payload;
      filter_products(state);
    },
    selectBrand: (state, action) => {
      state.currentBrand = action.payload;
      filter_products(state);
    },
    selectStyle: (state, action) => {
      state.currentStyle = action.payload;
      filter_products(state);
    },
    selectGender: (state, action) => {
      if (action.payload === null) {
        state.currentGender = 0;
      } else {
        state.currentGender = action.payload;
      }
      filter_products(state);
    },
    defineColors: (state, action) => {
      state.primaryColor = action.payload.primaryColor;
      state.primaryColorHex = HexCode(action.payload.primaryColor);
      state.secondaryColor = action.payload.secondaryColor;
      state.secondaryColorHex = HexCode(action.payload.secondaryColor);
      filter_products(state);
    },
    selectPrimaryColor: (state, action) => {
      state.primaryColor = action.payload;
      state.primaryColorHex = HexCode(action.payload);
      filter_products(state);
    },
    selectSecondaryColor: (state, action) => {
      state.secondaryColor = action.payload;
      state.secondaryColorHex = HexCode(action.payload);
      filter_products(state);
    },
    filterProducts: (state, action) => {
      state.filterKey = action.payload.toLowerCase();
      state.currentCategory = 'all';
      filter_products(state);
    },
    invertColors: (state, action) => {
      let temp = state.primaryColor;
      state.primaryColor = state.secondaryColor;
      state.secondaryColor = temp;
      filter_products(state);
    },
    openDefinition: (state, action) => {
      // console.log("openDefinition => " + JSON.stringify(action.payload));
      if (action.payload.productId === null) {
        state.shownDefinition = [ProductDefinitionDefault]
        state.initialDefinition = [ProductDefinitionDefault];
        updateProductDefinitionHasChanges(state);
      }
    },
    closeDefinition: (state) => {
      state.shownDefinition = null;
      state.initialDefinition = null;
      state.definitionHasChanges = false;
    },
    storeDefinitionInfo: (state, action) => {
      if (state.shownDefinition !== null && state.shownDefinition[0] !== undefined) {
        const propertyName = action.payload.field as keyof IProductDefinition;

        switch (propertyName) {
          case "gender":
            state.shownDefinition[0].gender = parseInt(action.payload.value);
            // console.log("storeDefinitionInfo Gender", parseInt(action.payload.value));
            break;

          case "dagobaPriceCents":
            state.shownDefinition[0].dagobaPriceCents = parseInt(action.payload.value);
            break;

          case "cannotSellAlone":
            state.shownDefinition[0].cannotSellAlone = action.payload.value === 'true';
            break;
          default:
            // console.log("storeDefinitionInfo ", propertyName, action.payload.value);
            (state.shownDefinition[0][propertyName] as any) = action.payload.value;
        }

        updateProductDefinitionHasChanges(state);
      }
    },
    storeDefinitionColor: (state, action) => {
      const newColorDefinition = action.payload;
      const supplierColor = newColorDefinition.supplierColor;
      if (state.shownDefinition !== null && state.shownDefinition[0] !== undefined) {
        let assign_all_colors = false;
        let changedProp = '';
        const moreThanOneColor = state.shownDefinition[0].colors.length > 1;

        state.shownDefinition[0].colors = state.shownDefinition[0].colors.
          map((color, color_index) => {
            if (color.supplierColor === supplierColor) {
              // console.log("Color ", color_index, color.supplierColor, changedProperties(newColorDefinition, color));

              const changedProps = changedProperties(newColorDefinition, color).filter(prop => prop !== 'images');

              // is assigning a template image on the first color, then we will assign it to all colors
              if (color_index === 0) {
                if (changedProps.length > 0 && changedProps[0].slice(-13) === 'TemplateImage') {
                  assign_all_colors = true;
                  changedProp = changedProps[0];
                }
              }

              if (changedProps.length > 0 && changedProps[0] === 'newSupplierColor') {
                const newColor = {...color};
                newColor.supplierColor = newColorDefinition.newSupplierColor;
                return newColor;
              } else {
                // check if we are removing a new color (not yet saved on the server)
                // in that case we remove it from the list
                // below is how we recognize a color added on the client side
                // One exception: don't remove the color if it's the only color of the product
                if (moreThanOneColor && newColorDefinition.remove && newColorDefinition.add &&
                  !newColorDefinition.missing && !newColorDefinition.extra && !newColorDefinition.discontinued) {
                  return null;
                }
                return newColorDefinition;
              }
            }

            if (assign_all_colors) {
              const colorCopy = {...color};
              (colorCopy[changedProp as keyof IProductColorDefinition] as any) = newColorDefinition[changedProp];
              return colorCopy;
            }

            return color;
          }). // remove the color if it was removed
          filter(color => color !== null);

        // we need to add a new blank image to the updated color if a blank image is missing
        state.shownDefinition[0].colors = state.shownDefinition[0].colors
          .map((color, color_index) => {
            if (color.supplierColor === supplierColor) {
              if (!color.images.some(image =>
                image.name === 'New' && image.url === 'New' && image.side === 'X' && image.imgBase64 === undefined)) {
                return {...color, images: [...color.images, InitialImageDefinition]};
              }
              return color;
            }
            return color;
          })

        updateProductDefinitionHasChanges(state);
      }
    },
    addDefinitionColorDagoba: (state) => {
      if (state.shownDefinition !== null && state.shownDefinition[0] !== undefined) {
        state.shownDefinition[0].colors.push(InitialColorDefinition);
        updateProductDefinitionHasChanges(state);
      }
    },
    toggleRemoveColor: (state) => {
      console.log("catalogSlice toggleRemoveColor");
      if (state.shownDefinition !== null && state.shownDefinition[0] !== undefined) {

        state.shownDefinition[0].colors = state.shownDefinition[0].colors.
          map((color, color_index) => {
            console.log("Color ", color_index, color.supplierColor, color.remove);
            return({...color, remove: !color.remove})
        });
        updateProductDefinitionHasChanges(state);
      }
    },
    storeDefinitionSetPerso: (state, action) => {
      // console.log("storeDefinitionSetPerso", action.payload);
      const persoCode = action.payload.persoCode;
      const activated = action.payload.activated;
      if (state.shownDefinition !== null && state.shownDefinition[0] !== undefined) {
        if (activated) {
          state.shownDefinition[0].personalizations.push(persoCode);
        } else {
          state.shownDefinition[0].personalizations = state.shownDefinition[0].personalizations.filter(perso => perso !== persoCode);
        }
        updateProductDefinitionHasChanges(state);
      }
    },
    storeDefinitionSizeDagoba: (state, action) => {
      // console.log("storeDefinitionInfo", action.payload);
      const supplierSize = action.payload.supplierSize;
      const dagobaSize = action.payload.dagobaSize;
      if (state.shownDefinition !== null && state.shownDefinition[0] !== undefined) {

        state.shownDefinition[0].sizes = state.shownDefinition[0].sizes.map(size => {
          if (size.supplierSize === supplierSize) {
            return {...size,
              dagobaSize: dagobaSize,
              supplierSize: size.add ? dagobaSize : size.supplierSize,
            };
          }
          return size;
        });
        updateProductDefinitionHasChanges(state);
      }
    },
    storeDefinitionSizeHeight: (state, action) => {
      // console.log("storeDefinitionSizeHeight new height " + parseFloat(action.payload.height), JSON.stringify(action.payload));
      const supplierSize = action.payload.supplierSize;
      if (state.shownDefinition !== null && state.shownDefinition[0] !== undefined) {

        state.shownDefinition[0].sizes = state.shownDefinition[0].sizes.map(size => {
          if (size.supplierSize === supplierSize) {
            return {...size, height: parseFloat(action.payload.height)};
          }
          return size;
        });
        updateProductDefinitionHasChanges(state);
      }
    },
    storeDefinitionSizePrice: (state, action) => {
      // console.log("storeDefinitionSizePrice new price " + parseInt(action.payload.priceCents), JSON.stringify(action.payload));
      const supplierSize = action.payload.supplierSize;
      if (state.shownDefinition !== null && state.shownDefinition[0] !== undefined) {

        state.shownDefinition[0].sizes = state.shownDefinition[0].sizes.map(size => {
          if (size.supplierSize === supplierSize) {
            return {...size, priceCents: parseInt(action.payload.priceCents)};
          }
          return size;
        });
        updateProductDefinitionHasChanges(state);
      }
    },
    addDefinitionSizeDagoba: (state) => {
      // console.log("storeDefinitionInfo", action.payload);
      if (state.shownDefinition !== null && state.shownDefinition[0] !== undefined) {

        state.shownDefinition[0].sizes.push({
            supplierSize: 'N',
            dagobaSize: '',
            priceCents: 0,
            height: 0,
            add: true
        });
        updateProductDefinitionHasChanges(state);
      }
    },
    removeDefinitionSizeDagoba: (state, action) => {
      if (state.shownDefinition !== null && state.shownDefinition[0] !== undefined) {
        // remove the size defined by payload
        state.shownDefinition[0].sizes = state.shownDefinition[0].sizes
          .filter(size => size.supplierSize !== action.payload);
        updateProductDefinitionHasChanges(state);
      }
    },
    toggleDefinitionPricePerSize: (state, action) => {
      if (state.shownDefinition !== null && state.shownDefinition[0] !== undefined) {
        state.shownDefinition[0].pricePerSize = !state.shownDefinition[0].pricePerSize;
      }
    },
    moveDefinitionSizes: (state, action) => {
      if (state.shownDefinition !== null && state.shownDefinition[0] !== undefined) {
        state.shownDefinition[0].sizes = action.payload.sizes;
      }
    },
    setProductsProperty: (state, infos) => {
      const updatedProperty = infos.payload.updatedProperty as IProductPropertyUpdate;
      updateStateProductsProperty(
        state,
        updatedProperty.propertyName as keyof IProduct,
        updatedProperty.propertyValue,
        updatedProperty.productIds);
    },
  },
  extraReducers: builder => {builder
    .addCase(fetchProductsAsync.pending, (state, action) => {
      state.status = Statuses.Loading;
    })
    .addCase(fetchProductsAsync.fulfilled, (state, action) => {
        const category_index = state.categories.findIndex(category => category.category === action.payload.category);
        // console.log("fetchProductsAsync.fulfilled for category " + category_index + ", found " + action.payload.products.length + " products");
        state.categories[category_index].products = action.payload.products;
        state.categories[category_index].status = Statuses.UpToDate;
        state.status = Statuses.UpToDate;
        filter_products(state);
    })
    .addCase(fetchProductsAsync.rejected, (state, action) => {
      // console.log("fetchProductsAsync.rejected => " + JSON.stringify(action.payload));
      state.status = Statuses.Error;
    })
    .addCase(fetchProductDefinition.pending, (state, action) => {
      state.status = Statuses.Loading;
      state.shownDefinition = null
      state.initialDefinition = null;
      state.definitionHasChanges = false;
    })
    .addCase(fetchProductDefinition.fulfilled, (state, action) => {
      state.status = Statuses.UpToDate;
      if (action.payload.length === 0) {
        state.shownDefinition = [ProductDefinitionDefault]
        state.initialDefinition = [ProductDefinitionDefault];
        state.definitionHasChanges = false;
      } else {
        if (action.payload[0].source !== "dagoba") {
          // create an empty definition for dagoba to create a new product
          const newDefinition = {
            ...action.payload[0],
            source: 'dagoba'
          };
          const allDefinitions = [newDefinition, ...action.payload];
          state.shownDefinition = allDefinitions;
          state.initialDefinition = allDefinitions[0];
          state.definitionHasChanges = false;
        } else {
          state.shownDefinition = action.payload;
          state.initialDefinition = action.payload[0];
          state.definitionHasChanges = false;
        }
      }
    })
    .addCase(fetchProductDefinition.rejected, (state, action) => {
      // console.log("fetchProductsAsync.rejected => " + JSON.stringify(action.payload));
      state.status = Statuses.Error;
    })
    .addCase(updateProductDefinition.pending, (state) => {
      state.definitionSaving = true;
    })
    .addCase(updateProductDefinition.fulfilled, (state, action: any) => {
      state.status = Statuses.UpToDate;
      state.shownDefinition = action.payload;
      state.initialDefinition = action.payload[0];
      state.definitionHasChanges = false;
      state.definitionSaving = false;
    })
    .addCase(updateProductDefinition.rejected, (state, action: any) => {
      state.definitionSaving = false;
    })
    .addCase(updateProductsProperty.pending, (state) => {
      console.log("updateProductsProperty.pending");
    })
    .addCase(updateProductsProperty.fulfilled, (state, action: any) => {
      console.log("updateProductsProperty.fulfilled", action.payload);
      // updateProductParameterInfos(state, action.payload);
    })
    .addCase(updateProductsProperty.rejected, (state, action: any) => {
      console.log("updateProductsProperty.rejected", action.payload);
    })
    .addCase(createJuniorVersion.pending, (state) => {
      console.log("createJuniorVersion.pending");
    })
    .addCase(createJuniorVersion.fulfilled, (state, action: any) => {
      console.log("createJuniorVersion.fulfilled", action.payload);
      // updateProductParameterInfos(state, action.payload);
    })
    .addCase(createJuniorVersion.rejected, (state, action: any) => {
      console.log("createJuniorVersion.rejected", action.payload);
    })
  }
});

export const {
  selectCategory,
  defineColors,
  selectPrimaryColor,
  selectSecondaryColor,
  selectGender,
  selectBrand,
  selectStyle,
  filterProducts,
  invertColors,
  openDefinition,
  closeDefinition,
  storeDefinitionInfo,
  storeDefinitionColor,
  toggleRemoveColor,
  addDefinitionColorDagoba,
  storeDefinitionSetPerso,
  storeDefinitionSizeDagoba,
  storeDefinitionSizeHeight,
  storeDefinitionSizePrice,
  addDefinitionSizeDagoba,
  removeDefinitionSizeDagoba,
  toggleDefinitionPricePerSize,
  moveDefinitionSizes,
  setProductsProperty,
} = catalogSlice.actions;

export const categoryStatuses = (state: RootState) => state.catalog.categories.map(category => (
  {code: category.category, status: category.status} as ICategoryInfos
));

export const currentCategory = (state: RootState) => state.catalog.currentCategory;
export const primaryColor = (state: RootState) => state.catalog.primaryColor;
export const secondaryColor = (state: RootState) => state.catalog.secondaryColor;
export const currentGender = (state: RootState) => state.catalog.currentGender;
export const currentBrand = (state: RootState) => state.catalog.currentBrand;
export const currentStyle = (state: RootState) => state.catalog.currentStyle;
export const currentFilterKey = (state: RootState) => state.catalog.filterKey;

export const CatalogStateSelector = (state: RootState) => state.catalog;

export const shownDefinitonSelector = (state: RootState) => state.catalog.shownDefinition;
export const definitionHasChangesSelector = (state: RootState) => state.catalog.definitionHasChanges;
export const definitionSavingSelector = (state: RootState) => state.catalog.definitionSaving;
export default catalogSlice.reducer;









