import React from 'react';
import {useDispatch} from "react-redux";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {FormControl, FormHelperText} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
  SelectedBrandSelector,
  selectBrand,
  StockBrandsSelector,
} from "./productionSlice";
import {useAppSelector} from "../../../app/hooks";

export function SelectStockBrand() {

  const brands = useAppSelector(StockBrandsSelector);
  const selectedBrand = useAppSelector(SelectedBrandSelector)
  const dispatch = useDispatch();

  // No need to display the select if there is only one workshop
  if (brands.length === 0) return (null);

  const handleSelectBrand = (event: SelectChangeEvent) => {
    dispatch(selectBrand(event.target.value))
  };

  return (
    <FormControl sx={{ mr: 1, minWidth: 150 }} size="small" className='select-tool search-workshop'>
      <Select value={selectedBrand || ''} onChange={handleSelectBrand}>
        <MenuItem key={`sel-brand0`} value='*'>*</MenuItem>
        {brands.map((brand, i) => (
          <MenuItem key={`sel-brand${i+1}`} value={brand.name}>{brand.name} ({brand.count})</MenuItem>
        ))}
      </Select>
    </FormControl>
  )


}
