import React from 'react';
import {CircularProgress, Grid, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {
  DagobaStockDeliveryItemsSelector,
  IWorkshopBox, orderWorkshopBoxSelector, prodItemSelector, recordStockItem,
  savingSelector,
  SelectedStockItemsSelector,
} from "./productionSlice";
import {openConfirmDialog} from "../../global/globalSlice";
import {AppDispatch} from "../../../app/store";
import {ScanQRCodeButton} from "./ScanQRCode";

const StockItemDetails = (props: {stockItemId: number}) => {
  const {stockItemId} = props;

  const stockItems = useSelector(SelectedStockItemsSelector);
  const stockItem = stockItems?.find((item) => item.id === stockItemId);
  const assignedOrderBox: IWorkshopBox | undefined = useSelector(state => orderWorkshopBoxSelector(state, stockItem?.orderNumber));
  const productionItem = useSelector(state =>
    prodItemSelector(state, {orderNumber: stockItem?.orderNumber, prodItemId: stockItem?.prodItemId}));

  // Look for delivery items in DAGOBA (stock) category that matches the stock item
  const dagobaStockDeliveryItemsSelector = useSelector(DagobaStockDeliveryItemsSelector);
  const matchingProdItems = dagobaStockDeliveryItemsSelector?.filter((delivery) => {
    return delivery.sku === stockItem?.ref;
  });

  const dispatch = useDispatch<AppDispatch>();
  const saving = useSelector(savingSelector);

  const handleTakeOut = () => () => {
    if (saving) return;

    // confimer auprès de l'utilisateur le choix de cette boite de stockage
    dispatch(openConfirmDialog({
      title: "Sortir de la boite de stockage",
      message: "Sortir l'article de la boîte ?",
      confirm: "Oui, sortir !",
      confirmColor: 'success',
      action: () => {
        const update = {
          id: stockItemId,
          stock_box_id: null
        }
        dispatch(recordStockItem(update));
      }
    }));

  }

  const handleAssignProdItem = (prodItemId: number) => {
    if (saving) return;

    // console.log("handleAssignProdItem", prodItemId);

    const update = {
      id: stockItemId,
      stock_box_id: null,
      prod_item_id: prodItemId
    }
    dispatch(recordStockItem(update));
  }

  // useEffect(() => {
  //   if (!saving) {
  //     setItemSaving(false);
  //   }
  // }, [saving]);

  if (!stockItem) {
    return (
      <Box className='stock-item-container'>
        <CircularProgress />
      </Box>
    )
  }

  const alreadyInBox = productionItem?.workshopBoxId && productionItem?.workshopBoxId === assignedOrderBox?.id;

  const putInBoxPrompt = assignedOrderBox ?
    `Ranger l'article dans le bac ${assignedOrderBox.name}` : 'Mettre dans un bac libre';

  // console.log("Render StockItemDetails", stockItem, productionItem, alreadyInBox);

  return(
    <Box className='stock-item-details'>
      <Box className='title'>
        {stockItem.supplierTitle}
      </Box>
      <Box className='color-size'>
        { stockItem.supplierSize && stockItem.supplierSize !== 'Unique' &&
			      <>{stockItem.supplierColor} - Taille {stockItem.supplierSize}</> || <>{stockItem.supplierColor}</>
        }
      </Box>
      <Box className='brand'>{stockItem.brand}</Box>
      <Box className='sku'>{stockItem.ref}</Box>
      <Box className='photo'>
        <img src={stockItem.supplierPhoto} />
      </Box>

      {saving && <CircularProgress color="inherit" size={50}/> || <>
        { (stockItem.prodItemId && stockItem.orderNumber &&
          <Box className='stock-box-name'>
            {alreadyInBox &&
                <>Dans {assignedOrderBox?.name} pour la {stockItem.orderNumber} </>
              || <>
                Assigné à la {stockItem.orderNumber}
                <ScanQRCodeButton size='small' precedingText={putInBoxPrompt} targetType='workshop_box'
                              targetId={assignedOrderBox?.id} objectId={stockItem.prodItemId}/>
              </>
            }
          </Box>) ||

          matchingProdItems?.map((prodItem) => (
            <Box className='choose-order-button' onClick={() => handleAssignProdItem(prodItem.prodItemId)}>
              Prendre pour la {prodItem.orderNumber}
            </Box>
          ))
        }


        {stockItem.stockBoxName && <>
            <Box className='stock-box-name'>
              Rangé dans {stockItem.stockBoxName}
            </Box>
            <Box className='choose-box-button' onClick={handleTakeOut()}>
              Sortir de la boite
            </Box>
          </>
          || <Box className='choose-box-button'>
          <ScanQRCodeButton size='small'
                            precedingText="Ranger dans une boite" targetType='stock_box'
                            targetId={stockItem.id} />
        </Box>}
      </>}
    </Box>
  )
}


export default StockItemDetails;