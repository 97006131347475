import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {
  getMarkings,
  IMarkingArticle,
  IMarkingPrintFile, IPrintInfos, markingBeingSavedSelector, markingSavingSelector,
  markingsLoadedSelector, markingsProductionDaySelector,
  markingsSelector, nameForMarkingTechnique, PRODUCTION_IN_BOX, setItemsProductionStatus, setMarkingDone
} from "./productionSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../app/store";
import {
  Button,
  CircularProgress,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {CustomButton} from "../../utils/CustomButton";
import Typography from "@mui/material/Typography";
import {openConfirmDialog, openInFullScreen} from "../../global/globalSlice";
import {useAppSelector} from "../../../app/hooks";

export function ProductionMarkings() {

  const dispatch = useDispatch<AppDispatch>();
  const markingsLoaded = useSelector(markingsLoadedSelector);
  const markings = useSelector(markingsSelector);
  const markingsProductionDay = useAppSelector(markingsProductionDaySelector)
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const cannotUpdateMarkings = !currentUser || !currentUser.update_markings

  interface IMarkingRow {
    id: string;
    orderNumber: number,
    orderDate: string | undefined,
    boutique: string | undefined,
    workShopBoxId: number | undefined,
    workShopBoxName: string | undefined,
    markingKey: string;
    doneAt: string | undefined;
    type: string | undefined;
    technique: number | undefined;
    name: string | undefined;
    description: string | undefined;
    image: string | undefined;
    printFiles: IMarkingPrintFile[] | undefined;
    value: string | undefined;
    fontName: string | undefined;
    fontColor: string | undefined;
    fontFile: string | undefined;
    pngFile: string | undefined;
    places: string[] | undefined;
    articles: IMarkingArticle[] | undefined;
    quantity: number;
  }

  // console.log('production markings rows based on markings', JSON.stringify(markings, null, 2))
  const rows = markings?.flatMap((orderMarkings, index) => (
    [
      {
        id: `T-${orderMarkings.orderNumber}-title-${index}-${markingsProductionDay.date}`,
        markingKey: 'none',
        orderNumber: orderMarkings.orderNumber,
        orderDate: orderMarkings.orderDate,
        boutique: orderMarkings.boutique,
        workShopBoxId: orderMarkings.workshopBoxId,
        workShopBoxName: orderMarkings.workshopBoxName,
        quantity: orderMarkings.markingsAggregrates.reduce((total, marking) =>
                total + marking.articles.reduce((total, article) => total + article.quantity, 0), 0),
      } as IMarkingRow
    ].concat(
      orderMarkings.markingsAggregrates.map((markingAggregate) => ({
        id: `M-${orderMarkings.orderNumber}-${markingAggregate.key}-${markingAggregate.articles.map((a) => a.id).join('-')}-${index}-${markingsProductionDay.date}`,
        markingKey: markingAggregate.key,
        orderNumber: orderMarkings.orderNumber,
        doneAt: markingAggregate.doneAt,
        type: markingAggregate.type,
        technique: markingAggregate.technique,
        name: markingAggregate.name,
        image: markingAggregate.image,
        description: markingAggregate.description,
        printFiles: markingAggregate.printFiles,
        value: markingAggregate.value,
        fontName: markingAggregate.fontName,
        fontColor: markingAggregate.fontColor,
        fontFile: markingAggregate.fontFile,
        pngFile: markingAggregate.pngFile,
        places: markingAggregate.places,
        articles: markingAggregate.articles,
        quantity: markingAggregate.articles.reduce((total, article) => total + article.quantity, 0),
      } as IMarkingRow)
    )))) || [];

  const sameDay = (date1: string, date2: string) => {
    return dayjs(date1).isSame(date2, 'day');
  }

  const doneAtBgColor =(doneAt: string | undefined ) => {
    if (!doneAt) return 'white';
    if (markingsProductionDay.date === 'none') return '#bbffbb';
    if (sameDay(doneAt, markingsProductionDay.date)) return '#bbffbb';
    return '#d7fdff';
  }

  const doneAtHoverColor =(doneAt: string | undefined) => {
    if (!doneAt) return 'white';
    if (markingsProductionDay.date === 'none') return '#8fb98d';
    if (sameDay(doneAt, markingsProductionDay.date)) return '#8fb98d';
    return '#bdc7da';
  }

  const MarkingRow = (props: { row: IMarkingRow }) => {
    const { row } = props;
    const markingSaving = useSelector(markingSavingSelector);
    const markingBeingSaved = useSelector(markingBeingSavedSelector);
    const [open, setOpen] = useState(false);

    const cellClassName = open ? 'marking-cell open' : 'marking-cell';

    if (row.orderDate) {
      return (
        <TableRow key={`mr-${row.orderNumber}`} sx={{ '& > *': { borderBottom: 'unset', cursor: 'pointer' } }}>

          <TableCell className="order-cell order-number" align="center">
            {row.orderNumber}
          </TableCell>

          <TableCell className="order-cell quantity" align="center">
            <Box className='qty'>
              {row.quantity}
            </Box>
          </TableCell>

          <TableCell className="order-cell workshop-box" align="center">
            {row.workShopBoxId &&
								<Box className='box-name' onClick={() =>
                  dispatch(openInFullScreen({name: 'WorkshopBoxDetails', displayedId: row.workShopBoxId}))
                }>
                  {row.workShopBoxName}
								</Box>
            }
          </TableCell>

          <TableCell className="order-cell btque" align="center" colSpan={6}>
            {row.boutique}
          </TableCell>

          <TableCell className="order-cell order-date" align="center">
            {dayjs(row.orderDate, { utc: true}).format("DD/MM/YYYY")}
          </TableCell>

        </TableRow>
      );

    } else {

      // console.log("%cDisplaying marking row for marking: " + JSON.stringify(row, null, 2), "color: red")
      return (<>
        <TableRow key={row.markingKey} sx={{'& > *': {borderBottom: 'unset', cursor: 'pointer'}}}>
          <TableCell className={`${cellClassName}`} align="center">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <RemoveCircleIcon/> : <AddCircleIcon/>}
            </IconButton>
          </TableCell>
          <TableCell className={`${cellClassName} qty`} align="center">
            <Box className={`box ${row.quantity > 1 ? 'multiple' : ''}  ${row.doneAt ? 'done' : ''}`}>
              {row.quantity}
            </Box>
          </TableCell>
          <TableCell className={`${cellClassName} image`} align="center" onClick={() => setOpen(!open)}>
            {(row.type === 'logo' || !!row.pngFile) && <Box className={`img-container ${row.type === 'logo' ? '' : 'text'}`}>
							<img src={row.type === 'logo' ? row.image : row.pngFile} height={32}/>
						</Box>}
          </TableCell>
          <TableCell className={`${cellClassName} name`} align={row.type === 'logo' ? "left" : "center"}
                     colSpan={2} onClick={() => setOpen(!open)}>
            {row.type === 'logo' && row.name || <Box className='text-value'>{row.value}</Box>}
          </TableCell>
          <TableCell className={`${cellClassName} `} align="left" colSpan={2} >
            {row.type === 'logo' && <>
              {row.printFiles?.map((prtFile, index) => (
                <Box key={`mr${row.markingKey}-pf${index}`} className='print-infos'>
                  {printFile(prtFile)}
                </Box>
              ))
              } </> ||
              <Box className='perso-infos'>
                {row.pngFile && <Box className='print-infos'>
			                <Box className='perso-infos'>
				                <a href={row.pngFile} target="_blank" rel="noreferrer">
					                <FileDownloadIcon fontSize='small'/>
                          {row.pngFile.split('~')[1].split('.png')[0].replace('-', ' x ').replace('_', '  -  ')}
				                </a>
			                </Box>
		                </Box> ||
		                <>
			                <Box className='perso-color'>
				                <Box className='color-box' sx={{backgroundColor: row.fontColor || 'black'}}></Box>
                    <Box className='color-name'>{row.fontColor}</Box>
                  </Box>
                  <Box className='perso-font'>
                    <a href={row.fontFile} target="_blank" rel="noreferrer">
                      <FileDownloadIcon fontSize='small'/>
                      {row.fontName}
                    </a>
                  </Box>
                </>}
              </Box>
            }
          </TableCell>
          <TableCell className={`${cellClassName}`} align="left" onClick={() => setOpen(!open)}>
            {row.places?.map((place, index) => (
              <Box className='place' key={`mr${row.markingKey}-place-${index}`}>
                {place}
              </Box>
            ))
            }
          </TableCell>
          <TableCell className={`${cellClassName}`} align="left" onClick={() => setOpen(!open)}>
            {row.type === 'logo' && nameForMarkingTechnique(row.technique)}
          </TableCell>
          <TableCell className={`${cellClassName} action`} align="right">
            {!row.doneAt &&
								<CustomButton variant="contained" size='small'
                              sx={{minWidth: '110px', minHeight: '33px', opacity: markingSaving ? 0.7 : 1}}
								              // disabled={markingSaving}
								              onClick={() => {
                                if (cannotUpdateMarkings || markingSaving) return;

                                dispatch(setMarkingDone({order: row.orderNumber, key: row.markingKey, done: true}))
                              }}>
                  {markingBeingSaved === row.markingKey && <CircularProgress color="inherit" className='save-marking' size={20}/>
                    || <Typography>À faire</Typography>}
								</CustomButton> ||
								<CustomButton variant="contained" size='small'
                              sx={{minWidth: '110px', minHeight: '33px', opacity: markingSaving ? 0.7 : 1}}
								              textcolor={'black'}
                              backgroundcolor={doneAtBgColor(row.doneAt)}
                              hoverbackgroundcolor={doneAtHoverColor(row.doneAt)}
								              // disabled={markingSaving}
								              onClick={() => {
                                if (cannotUpdateMarkings || markingSaving) return;

                                dispatch(openConfirmDialog({
                                  title: "Effacer que ce marquage est fait ?!",
                                  message: "Voulez-vous vraiment effacer que ce marquage a été fait ? Attention la date et l'heure actuellement enregistrées seront définitivement effacées !",
                                  confirm: "Oui, ce marquage n'a pas été fait",
                                  confirmColor: 'warning',
                                  action: () => {
                                    dispatch(setMarkingDone({order: row.orderNumber,  key: row.markingKey, done: false}))
                                  }
                                }));
                              }}>
                  {markingBeingSaved === row.markingKey && <CircularProgress color="inherit" className='save-marking' size={20}/> ||
											<Typography fontSize={'0.9em'}>
                        {dayjs(row.doneAt, {utc: true}).format("DD/MM/YY HH:mm")}
											</Typography>
                  }
								</CustomButton>
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{margin: 1}}>
                {row.articles?.map((article, index) =>
                  ArticleRow(article, row, `${row.markingKey}-${index}`))}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>);
    }
  }

  const ArticleRow = (article: IMarkingArticle, row: IMarkingRow, key: string) => {
    // console.log('ArticleRow', article, row, key)
    return(
      <Box key={key} className='marking-article'>
        <Box className='marking-infos'>
          <Box className='marking-title'>
            {article.variant}
            <Box className='ref'>
              {article.ref}
            </Box>
          </Box>
          {article.quantity > 1 && <Box className='marking-qty'>
            x {article.quantity}
          </Box>}
        </Box>
        <Box className='marking-logo'>
          {row.description &&
			        <Box className='description'>
                {row.description}
			        </Box>}
          <Box className='logo-container' sx={{backgroundColor: article.bgColor}}>
            {row.type === 'logo' &&
              <img src={row.image} /> ||
                  (row.value !== undefined && <Box sx={{
                    color: row.fontColor,
                    fontSize: row.value.length > 10 ? '1.3em' : (row.value.length < 4 ? '4em' : '2.2em')
                  }}>
                {row.value}
              </Box>
            )}
          </Box>
        </Box>
        <Box className='marking-variant'>
          <Box className='place'>
            {article.place}
          </Box>
          <Box className='preview'>
            <img src={article.preview} />
            {article.productionNotes &&
              <Box className='notes'>
                {article.productionNotes}
              </Box>
            }
          </Box>
        </Box>
      </Box>
    )
  }

  const printFile = (prtInfos: IMarkingPrintFile) => {
    if (!prtInfos.name) return null;

    return(<>
      <a href={prtInfos.file} target="_blank" rel="noreferrer">
        <FileDownloadIcon fontSize='small' />
        {prtInfos.name}
      </a>
    </>)
  }


  document.title = 'Marquages';

  return(
    <Box className='production-markings'>
      {markingsLoaded && <>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="collapsible table">
              <TableBody>
                {rows.map((row) => (
                  <MarkingRow key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        {/*</Box>*/}

      </> ||
      <Box className='loading-production'>
        <Box>
          Récupération des marquages à produire ...
        </Box>
        <CircularProgress color="inherit" size={100} />
      </Box>
      }
    </Box>
  )
}
