import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {ButtonProps} from "@mui/material/Button";

// const TooltipColor = '#404040'
const TooltipColor = 'rgba(0,0,0,0.7)'

interface CustomTooltipProps extends TooltipProps {
  textColor?: string;
  backgroundColor?: string;
}

export const BoudaToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))<CustomTooltipProps>(({ theme, textColor, backgroundColor }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    // color: theme.palette.common.black,
    color: backgroundColor || TooltipColor,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.common.black,
    maxWidth: 'none',
    fontSize: 18,
    color: textColor || 'white',
    backgroundColor: backgroundColor || TooltipColor,
    padding: '5px 10px',
    // textAlign: 'center',
    whiteSpace: 'pre-line'
    // border: '1px solid white',
  },
}));