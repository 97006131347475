import "./dashboard.scss";
import React, {useState, useEffect, useMemo} from 'react';
import {pointerWithin, DndContext} from '@dnd-kit/core';
import Box from "@mui/material/Box";
import {ProductSelection} from "../selection/ProductSelection";
import {Boutique} from "./Boutique";
import {AppDispatch, RootState} from "../../app/store";
import {useDispatch, useSelector} from "react-redux";
import {
  addProductToCollection, IBoutiqueInfos, getBoutiques, selectBoutique,
  storeBoutiqueInfo, updateBoutiques, addBoutique, ICollection, PLAN_STANDARD
} from "./boutiqueSlice";
import {Chip, CircularProgress, Divider, Tab, Tabs} from "@mui/material";
import Typography from "@mui/material/Typography";
import LogoForm from "../logos/LogoForm";
import {
  editedLogoSelector,
  editLogo, hasAutomaticLogoSelector,
  logosSelector,
  newLogo,
} from "../logos/logosSlice";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {FormattedMessage, useIntl} from "react-intl";
import {LogosList} from "../logos/LogosList";
import {impersonatingUser, ROLE_BOUTIQUE} from "../sessions/sessionSlice";
import {SearchVendor} from "../logos/SearchVendor";
import WarningIcon from "@mui/icons-material/Warning";

const MAX_BOUTIQUE_NAME_LENGTH = 30;

export function Dashboard() {

  const intl = useIntl();
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const userBoutique = currentUser && currentUser.role !== undefined &&
                          (currentUser.role >= ROLE_BOUTIQUE || impersonatingUser(currentUser))
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const dispatch = useDispatch<AppDispatch>();
  const boutiques = useSelector((state: RootState) => state.boutique.boutiques);
  const boutiquesLoaded = useSelector((state : RootState) => state.boutique.loaded);

  const currentBoutique = useSelector((state: RootState) => state.boutique.currentBoutique);
  const editMode = !!currentBoutique && currentBoutique.draftOf !== null;
  const boutiqueHasChanges = useSelector((state : RootState) => state.boutique.hasChanges);
  const boutiqueEditMode = currentBoutique && currentBoutique.draftOf !== null;

  ///////////////////////////////////////////////////////
  // Logos
  ///////////////////////////////////////////////////////
  const logos = useSelector(logosSelector);
  const hasAutomaticLogo = useSelector(hasAutomaticLogoSelector);
  const editedLogo = useSelector(editedLogoSelector);

  function handleDragEnd(event:any) {
    const {over, active} = event;
    if (over) {
      switch (active.data.current.type) {
        case "product":
          dispatch(addProductToCollection({
            product: active.data.current.product,
            colors: active.data.current.colors,
            otherVersions: active.data.current.otherVersions,
            collectionId: over.data.current.collection_id,
            logos: logos
          }));

          break;

        case "logo":
          // console.log("Dropping logo " + JSON.stringify(active.data.current) + " on drop target " + JSON.stringify(over.data.current));
          dispatch(storeBoutiqueInfo({field: 'logoId', value: active.data.current.logo.id}))
          break;


        default:
          console.log("Dropping " + JSON.stringify(active.data.current) + " on drop target " + JSON.stringify(over.data.current));
      }
    }
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  if (currentUser === undefined || currentUser.id === undefined) {
    return (<div/>);
  }

  if (!userBoutique) {
    return (
      <Typography variant='h5' sx={{textAlign: 'center', marginTop: '20px'}}>
        <FormattedMessage id="dashboard.not-opened" />
      </Typography>
    )
  }

  // console.log("%cDashboard rendering with currentBoutique id=" + currentBoutique?.id, "color: green");

  const boutiqueTag = (boutique: IBoutiqueInfos) => {
    const isDraft = boutique.draftOf !== null && boutique.draftOf !== 0;
    const isSelected = currentBoutique?.id === boutique.id;
    const hasLogosUnsynced = boutique.logosUnsynced;
    const textLength = (isDraft ? MAX_BOUTIQUE_NAME_LENGTH - 15 : MAX_BOUTIQUE_NAME_LENGTH) - (hasLogosUnsynced ? 5 : 0);

    const className = `boutique-selector ${isSelected ? 'selected' : ''} ${isDraft ? 'draft' : ''} ${(boutiqueHasChanges || editedLogo) ? 'disabled' : ''}`

    return (
      <Box key={`btq-${boutique.id}`} className={className}
           onClick={() => {
             if (!boutiqueHasChanges && !editedLogo) dispatch(selectBoutique({id: boutique.id})
           )}} >
        {isDraft ? `(${intl.formatMessage({id: "global.draft"})}) ` : ''}
        {hasLogosUnsynced && !isDraft && <WarningIcon sx={{fontSize: 24, color: 'orange', mr: 1}} />}
        {boutique.name.slice(0, textLength-1)} {boutique.name.length >= textLength && '...'}
      </Box>
    )
  }

  const addBoutiqueTag = () => {
    return (
      <Box key='btq-add' className='boutique-selector add'  onClick={() => dispatch(addBoutique({boutique: {
        id: 0, draftOf: 0, vendorId: null, logoId: null, logoUrl: '',
        name: intl.formatMessage({ id: "boutique.new-name" }),
        subtitle: intl.formatMessage({ id: "boutique.new-subtitle" }),
        subdomain: intl.formatMessage({ id: "boutique.new-subdomain" }) +  `-${currentUser.id}`,
        acronym: '', orgaType: '', orgaActivity: '', website: '', facebook: '', instagram: '',
        color1: currentUser.color1, color2: currentUser.color2,
        persos_active: true, persos_font: 'Bebas Neue', color_dark_bg: 'white', color_light_bg: 'black',
        collections: [
          {id: 0, draftOf: null, name: intl.formatMessage({ id: "boutique.lifestyle" }),
            hasSiblings: false, separate: false, products: [], groups: []
          },
        ],
        customColors: [],
        logosUnsynced: false, configApplied: false, buildingAt: '', duration: 0, draftCode: '',
        plan: PLAN_STANDARD, planAcceptedOn: '', termsException: false
      } as IBoutiqueInfos}))}>
        <AddCircleIcon />
        <FormattedMessage id="boutique.new" />
      </Box>

  )
  }

  // console.log("Dashboard rendering with editMode=" + editMode);

  const rankOfBoutique = (boutique: IBoutiqueInfos) => {
    if (boutique.draftOf !== null) {
      if (boutique.draftOf === 0) return 100000000;
      return (boutique.draftOf * 10 + 1);
    } else if (boutique.id !== null) {
      return (boutique.id * 10);
    } else {
      return 0;
    }
  }

  return(
    <DndContext collisionDetection={pointerWithin} onDragEnd={handleDragEnd}>
      <div className='dashboard'>
        <Box sx={{ display: 'flex', p: 1, borderRadius: 1 }} >
          <Box sx={{p: 1, m: 0, zIndex: 1}}>
            {logos.length > 0 && <>
              <Divider sx={{mb:1}}>
                <Chip className='section' label={intl.formatMessage({id: 'dashboard.boutiques'})} size='medium' />
              </Divider>
              <Box>
                {[...boutiques]
                  .sort((b1, b2) => rankOfBoutique(b1) - rankOfBoutique(b2))
                  .map((boutique) => boutiqueTag(boutique))}
                {!editMode && addBoutiqueTag()}
              </Box>
            </>}
            <Divider sx={{mt:2}}>
              <Chip className='section' label={intl.formatMessage({id: 'dashboard.resources'})} size='medium' />
            </Divider>
            <Box sx={{borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label={intl.formatMessage({ id: "selection.menu"})} {...a11yProps(0)} />
                <Tab label={intl.formatMessage({ id: "logos.menu"})}  {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <ProductSelection title={false} catalogView={false} draggable={!!boutiqueEditMode} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <>
                <LogosList logos={logos} draggable={!!boutiqueEditMode} />
                {!!editedLogo || ( !hasAutomaticLogo &&
                    <div className='add-new-logo'>
                      <IconButton sx={{fontSize:'1.2rem',color: '#A0A0A0'}} onClick={() => dispatch(editLogo(newLogo))}>
                        <AddCircleIcon />
                        <FormattedMessage id="logos.add-new" />
                      </IconButton>
                    </div>)
                }
                {!editedLogo && currentUser?.superAdmin && <SearchVendor />}
              </>
            </TabPanel>
          </Box>
          <Box sx={{flexGrow: 1, p: 1, mr: 1}}>
            {
              (editedLogo && <LogoForm />) || !!currentBoutique && <Boutique boutique={currentBoutique} />
              || <div className='empty-boutique'>
                {logos.length === 0 && <FormattedMessage id="logos.empty"/> || <>
                  <FormattedMessage id={boutiquesLoaded ? "boutique.none" : "boutique.loading"}/><br/><br/>
                  {!boutiquesLoaded && <CircularProgress color="inherit" size={60}/>}
                </>}
              </div>
            }
          </Box>
        </Box>
      </div>
    </DndContext>
  )
}
