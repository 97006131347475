import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress, FormControl, FormHelperText} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
  IMarkingsDayProduction,
  markingsHistorySelector, markingsLoadedSelector, markingsProductionDaySelector,
  selectMarkingsProductionDay,
} from "./productionSlice";
import {useAppSelector} from "../../../app/hooks";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

export function SelectMarkingsDay() {
  const markingsLoaded = useSelector(markingsLoadedSelector);
  const markingsHistory = useAppSelector(markingsHistorySelector)
  const markingsProductionDay = useAppSelector(markingsProductionDaySelector)
  const dispatch = useDispatch();

  const dayLabel = (dayProduction: IMarkingsDayProduction) =>
      dayProduction.date === 'none' ? `${dayProduction.nbMarkings} à faire` :
          `Faits le ${dayProduction.date} (${dayProduction.nbMarkings})`

  const handleSelectDay = (e: any) => {
    const dayProduction = markingsHistory?.
              find(day => day.date === e.target.value)

    dispatch(selectMarkingsProductionDay(dayProduction))
  }

  useEffect(() => {
    // if the selected day is not in the history, select the first day in the history
    if (markingsHistory && markingsHistory.length > 0 &&
          markingsHistory.findIndex(day => day.date === markingsProductionDay.date) === -1) {
      console.log("%cSelecting first day in history", "color: red")
      dispatch(selectMarkingsProductionDay(markingsHistory[0]))
    }
  }, [markingsHistory, markingsProductionDay, dispatch])


  return (
    <FormControl sx={{ mr: 1, minWidth: 120, marginRight: '10px' }} size="small" className='select-tool select-marking-day'>
      <FormHelperText sx={{ml:0}}>Marquages à faire</FormHelperText>

      {markingsLoaded &&
        <TextField id="select-day"  size="small" select value={markingsProductionDay.date} onChange={handleSelectDay}>
          {markingsHistory && markingsHistory.map((dayProduction, i) => (
                <MenuItem key={`sel-mark-day${i + 1}`} value={dayProduction.date}>
                  {dayLabel(dayProduction)}
                </MenuItem>
            )
          )}
        </TextField> ||
        <Box sx={{height: '40px', display: 'flex', alignItems: 'center'}}>
          <CircularProgress color="inherit" size={20} sx={{mr:1}} /> Chargement...
        </Box>
      }
    </FormControl>
  )


}
