import React, {useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FormControl, FormHelperText, InputAdornment, OutlinedInput} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {getlogos, searchedVendorSelector} from "./logosSlice";

export function SearchVendor() {

  const searchedVendor = useSelector(searchedVendorSelector);

  const dispatch = useDispatch();
  const searchRef = useRef();

  const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const search = event.target.value;

    // console.log("search: " + search);

    if (search !== null && (search.length >= 4 || search.length === 0)) {
      dispatch(getlogos(search))
    }
  };

  const handleClearSearch = (e:any) => {
    if (searchRef && searchRef.current) {
      const input: HTMLInputElement = searchRef.current as HTMLInputElement;
      input.value = ''
    }

    dispatch(getlogos(''))
  }


  return (
    <FormControl sx={{ ml: 1, mr: 3, minWidth: 50, display: 'flex' }} size="small" className='select-tool search-order'>
      <FormHelperText sx={{ml:0}}>Vendor</FormHelperText>
      <OutlinedInput size="small" sx={{width: '115px'}}
                 defaultValue={searchedVendor || ''}
                 onChange={handleSearch} inputProps={{ maxLength: 5 }}
                 inputRef={searchRef}
                 endAdornment={
                   <InputAdornment position="end">
                     <IconButton onClick={handleClearSearch} edge="end">
                       <CloseIcon fontSize='small' />
                     </IconButton>
                   </InputAdornment>
                 } />
    </FormControl>
  )
}
