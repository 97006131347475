import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {
  currentWorkshopSelector,
  DeliveryColours,
  selectedOrdersDeliveriesSelector,
  IDeliveryItem,
  IWorkshopDelivery,
  PRODUCTION_DELIVERED,
  PRODUCTION_PROBLEM,
  PRODUCTION_IN_BOX,
  PRODUCTION_STARTED,
  setItemsProductionStatus,
  setWorkshopDelivered,
  currentWorkshopBoxesSelector,
  savingSelector,
  ColorsForOrderStatus,
  NO_DATE_LIMIT,
  setDeliveryOrder, IWorkshop,AllStockItemsSelector,
} from "./productionSlice";
import {Grid} from "@mui/material";
import {openConfirmDialog, openSnackBar} from "../../global/globalSlice";
import {AppDispatch, RootState} from "../../../app/store";
import {CircularProgress} from "@mui/material";
import InlineEdit from "../../utils/InlineEdit";


const SUPPLIERS_WITH_PROCURE_ORDER = [
    'Ralawise',
    // 'L-Shop-Team',
]

const nextOrderStatus = (status: number) => {
  if (status === PRODUCTION_DELIVERED) {
    return PRODUCTION_PROBLEM;
  } else {
    return PRODUCTION_DELIVERED;
  }
}

const itemsDeliveryColors = (items: IDeliveryItem[]) =>
    ColorsForOrderStatus(Math.min(...items.map((item) => item.orderStatus)))


const DeliveryItem = (props: {
  item: IDeliveryItem,
  workshop: IWorkshop | undefined,
  cannotUpdateDeliveries: boolean,
  foundInStock: boolean,
  locatedBoxName: string
}) => {
  const {item, workshop, cannotUpdateDeliveries, foundInStock, locatedBoxName} = props;
  const dispatch = useDispatch<AppDispatch>();
  const workShopBoxes = useSelector(currentWorkshopBoxesSelector);
  const saving = useSelector(savingSelector);
  const [itemSaving, setItemSaving] = useState(false);

  let boxName = '';

  if (item.workshopBoxId && workShopBoxes) {
    boxName = workShopBoxes.find((box) => box.id === item.workshopBoxId)?.name || '';
  }

  const handleClick = () => () => {
    if (saving || cannotUpdateDeliveries) return;

    if (item.orderStatus <= PRODUCTION_STARTED) {
      dispatch(openSnackBar({severity: 'error', message: "L'article n'est pas encore livré"}));
    } else if (item.orderStatus >= PRODUCTION_IN_BOX) {
      dispatch(openSnackBar({severity: 'error', message: "L'article est déjà dans un bac"}));
    } else {
      const updateStatus = {
        newOrderStatus: nextOrderStatus(item.orderStatus),
        newWorkshopBoxId: null,
        prodItemIds: [item.prodItemId]
      }
      setItemSaving(true)
      dispatch(setItemsProductionStatus(updateStatus));
    }
  }

  useEffect(() => {
    if (!saving) {
      setItemSaving(false);
    }
  }, [saving]);

  const deliveryColours = DeliveryColours(
    foundInStock ? PRODUCTION_DELIVERED : item.orderStatus);

  const deliveryStatusName = foundInStock ? 'RÉCUPÉRÉ' : (locatedBoxName || workshop?.statuses
      .find((status) => status.orderStatus === item.orderStatus)?.statusName || '');

  return(
    <Grid item xs={12} sm={6} className='delivery-item-container'>
      <Box className='delivery-item-status' sx={{color: deliveryColours.fgColor}}>
        {deliveryStatusName}
      </Box>
      <Box className='delivery-item' sx={{backgroundColor: deliveryColours.bgColor, color: deliveryColours.fgColor}}>
        {itemSaving && <CircularProgress color="inherit" className='save-progress' size={40}/>}
        <Box className='infos' onClick={handleClick()} sx={{opacity: saving && itemSaving ? 0.2 : 1}}>
          <Box className='title' sx={{textWrap:'nowrap'}}>
            {item.title}
          </Box>
          <Box className='size'>Taille: <strong>{item.size}</strong> </Box>
          <Box className='color'>{item.color}</Box>
          <Box className='brand'>{item.brand}
            <Box sx={{display: 'inline-block', color: 'red', ml:1, fontWeight:'bold'}}>
              {item.replacementBrand && `REMPLACÉ PAR ${item.replacementBrand}`}
            </Box>
          </Box>
          <Box className='sku'>{item.sku}
            <Box sx={{display: 'inline-block', color: 'red', ml:1, fontWeight:'bold'}}>
              {item.replacementRef && `REMPLACÉ PAR ${item.replacementRef}`}
            </Box>
          </Box>
        </Box>
        <Box className='photo'>
          <img src={item.photo} />
          <Box className='under-photo' sx={{display: 'flex', justifyContent: 'center'}}>
            <Box className='order'>
              {item.orderNumber}
            </Box>
            {boxName &&
              <Box className='box'>
                &nbsp;({boxName})
              </Box>
            }
          </Box>
        </Box>
        <Box className='qr-code'>
          <img src={item.qrCode} />
          <Box className='order'>
            {item.orderNumber}
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

const ProductionDeliveries = () => {
  const dispatch = useDispatch<AppDispatch>();

  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const cannotUpdateDeliveries = !currentUser || !currentUser.update_deliveries

  const currentWorkshop = useSelector(currentWorkshopSelector);

  const selectedOrdersDeliveries = useSelector(selectedOrdersDeliveriesSelector);

  // so we can display that an stock item was found
  const stockItems = useSelector(AllStockItemsSelector);

  const [currentDeliveryCode, setCurrentDeliveryCode] = useState<string | undefined>(undefined);

  const currentDelivery = currentWorkshop?.deliveries.find((delivery) => delivery.deliveryCode === currentDeliveryCode)
  const displayedDeliveryItems = selectedOrdersDeliveries?.find((delivery) => delivery.code === currentDeliveryCode)
  const selectedDeliveryFromCode = (code: string) => selectedOrdersDeliveries
      .find((deliveryWithItems) => deliveryWithItems.code === code);


  useEffect(() => {
    // console.log("ProductionDeliveries: useEffect currentWorkshop = " + currentWorkshop + ", currentDeliveryCode = " + currentDeliveryCode);
    if (currentWorkshop && currentWorkshop.deliveries) {

      // if there is no current delivery code, select the first one, which is the first non-empty one
      const notEmptyDeliveriesWithItems = currentWorkshop.deliveries
        .filter((delivery) =>
            (selectedDeliveryFromCode(delivery.deliveryCode)?.nbItems || 0) > 0)

      // if the current choice of delivery is not empty, keep it
      if (notEmptyDeliveriesWithItems.some((delivery) => delivery.deliveryCode === currentDeliveryCode)) {
        return;
      } else {
        setCurrentDeliveryCode(notEmptyDeliveriesWithItems[0]?.deliveryCode)
      }
    }
  }, [currentWorkshop, selectedOrdersDeliveries]);

  const deliveryCard = (delivery: IWorkshopDelivery) => {

    const selectedDelivery = selectedDeliveryFromCode(delivery.deliveryCode);

    const nbItems = selectedDelivery?.nbItems || 0;

    if (nbItems === 0) return null;

    const borderColor = currentDeliveryCode === delivery.deliveryCode ? '#A0A0A0' : 'white'

    const deliveryColors = itemsDeliveryColors(selectedDelivery?.items || [])
    const displayDate = new Date(delivery.procureDate) < NO_DATE_LIMIT

    const displayProcureOrder = SUPPLIERS_WITH_PROCURE_ORDER.includes(delivery.supplier) && delivery.procureOrder !== '-'

    return (
      <Box key={`delivery-${delivery.deliveryCode}`} className={`delivery ${displayProcureOrder ? 'with-order' : ''} `}
          style={{
            // backgroundColor: colorForDelivery(delivery, selectedDelivery?.items || []),
            backgroundColor: deliveryColors.bgColor,
            color: deliveryColors.fgColor,
            border: `5px solid ${borderColor}`
          }}
          onClick={() => setCurrentDeliveryCode(delivery.deliveryCode)}
      >
        <Box>{nbItems}</Box>
        <Box className='supplier'>
          {delivery.supplier}
        </Box>
        {displayDate &&
        <Box className='date'>
          {new Date(delivery.procureDate).toLocaleString('fr', {
            month: "short", day: "numeric",
            hour: "numeric", minute: "numeric"
          })}
        </Box>}

        {displayProcureOrder && <Box className='procure-order'>{delivery.procureOrder}</Box>}
      </Box>
    )
  }

  let currentProcureDate = '';
  if (currentDeliveryCode && currentDelivery && displayedDeliveryItems) {
    currentProcureDate = new Date(currentDelivery.procureDate).toLocaleString('fr', {
      day: "numeric", month: "long", year: "numeric"
    });
    let deliveryTitle = "LIVRAISON " + currentDelivery.supplier.toUpperCase() + " (COMMANDE DU " + currentProcureDate + ")";

    if (currentDelivery.deliveredAt && currentDelivery.status === "DELIVERED") {
      deliveryTitle += " - REÇU LE " + new Date(currentDelivery.deliveredAt).toLocaleString('fr', {
        month: "short", day: "numeric",
        hour: "numeric", minute: "numeric"
      });
    }
    document.title = deliveryTitle;
  }

  const currentDeliveryHeader = () => {
    if (!currentDelivery || !displayedDeliveryItems) {
      return null;
    }

    const deliveryColors = itemsDeliveryColors(displayedDeliveryItems.items || [])
    const displayDate = new Date(currentDelivery.procureDate) < NO_DATE_LIMIT

    // console.log("Displaying delivery header for " + currentDeliveryCode + " with currentUser?.superAdmin = " + currentUser?.superAdmin)

    return(
      <Box className='delivery-title'
           // style={{backgroundColor: colorForDelivery(currentDelivery, displayedDeliveryItems.items)}}>
           style={{backgroundColor: deliveryColors.bgColor, color: deliveryColors.fgColor}}>
        { displayDate && <Box className='procure-date'>
          {new Date(currentDelivery.procureDate).toLocaleString('fr', {
            month: "short", day: "numeric",
            hour: "numeric", minute: "numeric"
          })}
          {SUPPLIERS_WITH_PROCURE_ORDER.includes(currentDelivery.supplier) &&
            <Box className='procure-order'>
              {currentUser?.superAdmin &&
                  <InlineEdit key={`ED${currentDeliveryCode}`} text={currentDelivery.procureOrder}
                    onSetText={(value: string) => dispatch(setDeliveryOrder({
                      deliveryCode: currentDeliveryCode || '',
                      deliveryOrder: value}))} />
              || currentDelivery.procureOrder}
            </Box>
          }
        </Box>}
        <Box className='supplier'>
          {currentDelivery.supplier}
        </Box>
        {displayDate && <Box className='procure-status'>
          <Box className='status'>
            {currentDelivery.status || 'Non livré'}
          </Box>
          <Box className='procure-date'>
            {currentDelivery.deliveredAt && (new Date(currentDelivery.deliveredAt).toLocaleString('fr', {
              month: "short", day: "numeric",
              hour: "numeric", minute: "numeric"
            }))}
            {!currentDelivery.deliveredAt &&
              setDeliveredButton()}
          </Box>
        </Box>}
      </Box>
    )
  }

  const setDeliveredButton = () => {
    return(
      <Box className='set-delivered' onClick={() => handleSetDelivered ()}>
        Marquer comme livré
      </Box>
    )
  }

  const handleSetDelivered = () => {

    if (!currentDeliveryCode || !currentDelivery || cannotUpdateDeliveries) return;

    const supplierName = currentDelivery?.supplier?.toUpperCase() || '';
    dispatch(openConfirmDialog({
      title: "Marquer le(s) colis comme livré(s)",
      message: "Confirmez-vous que le ou les colis " + supplierName + " ont bien été livrés ?",
      confirm: "Oui, bien livré(s) !",
      confirmColor: 'success',
      action: () => {

        const deliveryUpdate = {
            deliveryCode: currentDeliveryCode,
            deliveredAt: new Date()
        }

        dispatch(setWorkshopDelivered(deliveryUpdate))
      }

    }));
  }

  // For delivery items that must be taken from DAGOBA stock,
  // display if they were found in the stock or not
  const itemFoundInStock = (deliveryItem: IDeliveryItem) => (currentDeliveryCode === 'DAGOBA'
    && stockItems?.find((stockItem) => stockItem.prodItemId === deliveryItem.prodItemId) !== undefined);

  const itemLocatedInStockBox = (deliveryItem: IDeliveryItem) => {
    console.log("Looking for box name for delivery item " + deliveryItem.sku);

    const matchingStockItem = stockItems
      ?.find((stockItem) => stockItem.ref === deliveryItem.sku);

    console.log("Matching stock item: ", matchingStockItem);

    return ((currentDeliveryCode === 'DAGOBA'
      && stockItems?.find((stockItem) => stockItem.ref === deliveryItem.sku)?.stockBoxName) || '')
  };

  return(
      <Box className='production-deliveries'>
        { currentWorkshop &&
          <Box className="deliveries" sx={{height: 'calc(100vh - 160px)', overflowY: 'auto'}}>
            {currentWorkshop.deliveries.map((delivery) => deliveryCard(delivery))}
          </Box>
        }
        {currentDeliveryCode && currentDelivery && displayedDeliveryItems &&
          <Box className='delivery-items'>
            <Grid container spacing={2}>
              <Grid item xs={12} className='delivery-title-container'>
                {currentDeliveryHeader()}
              </Grid>
              {displayedDeliveryItems.items
                .map((item, i) =>
                  <DeliveryItem item={item} key={`delivery-item-${i}`} workshop={currentWorkshop}
                                cannotUpdateDeliveries={cannotUpdateDeliveries}
                                foundInStock={itemFoundInStock(item)}
                                locatedBoxName={itemLocatedInStockBox(item)} />)
              }
            </Grid>
          </Box>
        }
      </Box>
    )
}

export default ProductionDeliveries;

