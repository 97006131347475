import React, {useState, useEffect } from 'react';
import {CircularProgress, Grid, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {
  deleteStockItem, getProducts, IStockItem, prodItemSelector,
  savingSelector,
  SelectedStockItemsSelector,
} from "./productionSlice";
import {openConfirmDialog, openInFullScreen, openSnackBar} from "../../global/globalSlice";
import {AppDispatch, RootState} from "../../../app/store";
import {SelectStockBrand} from "./SelectStockBrand";
import {SearchStockProduct} from "./SearchStockProduct";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from "@mui/material/IconButton";
import {FiltertStockArticles} from "./FiltertStockArticles";
import {SelectStockVariant} from "./SelectStockVariant";
import DeleteIcon from "@mui/icons-material/Delete";

const StockItemCard = (props: {item: IStockItem}) => {
  const {item} = props;
  const dispatch = useDispatch<AppDispatch>();
  const saving = useSelector(savingSelector);
  const [itemSaving, setItemSaving] = useState(false);

  const handleClick = () => () => {
    if (saving) return;
    dispatch(openInFullScreen({name: 'StockItemDetails', displayedId: item.id}))
  }

  const handleDelete = () => {

    let message = "";

    if (item.stockBoxName) {
      message = `Voulez-vous retirer cet article de la boîte ${item.stockBoxName} et le supprimer du stock ?`;
    } else {
      message = `Voulez-vous supprimer cet article du stock ?`;
    }

    dispatch(openConfirmDialog({
      title: "Suppression d'un article du stock", message: message, confirm: "Oui, supprimer",
      action: () => {
        dispatch(deleteStockItem(item.id));
      }
    }));
  }

  useEffect(() => {
    if (!saving) {
      setItemSaving(false);
    }
  }, [saving]);

  const bgColor = item.stockBoxName ? 'lightgrey' : 'white';

  return(
    <Grid item xs={6} md={6} className='stock-item-container'>
      <Box className='stock-item' sx={{backgroundColor: bgColor}}>
        <IconButton className='delete' onClick={() => handleDelete()}>
          <DeleteIcon sx={{width: '0.8em', height: '0.8em'}} />
        </IconButton>
        <Box className='infos' onClick={handleClick()} sx={{opacity: saving && itemSaving ? 0.2 : 1}}>
          {item.stockBoxName &&
              <Box className='stock-item-box-name'>
                {item.stockBoxName}
              </Box>
          }
          {item.orderNumber !== 0 &&
              <Box className='stock-item-box-name'>
                {item.orderNumber}
              </Box>
          }
          <Box className='title' sx={{textWrap:'nowrap'}}>
            {item.supplierTitle}
          </Box>
          { item.supplierSize && item.supplierSize !== 'Unique' &&
            <Box className='size'>Taille: <strong>{item.supplierSize}</strong> </Box>
          }
          <Box className='color'>{item.supplierColor}</Box>
          <Box className='brand'>{item.brand}</Box>
          <Box className='sku'>{item.ref}</Box>
        </Box>
        <Box className='photo'>
          <img src={item.supplierPhoto} />
        </Box>
        <Box className='qr-code'>
          <img src={item.qrCode} />
        </Box>
      </Box>
    </Grid>
  )
}

const AddStockItem = () => {
  const dispatch = useDispatch<AppDispatch>();

  const searchButton = () => <Box>
    <IconButton size='large' onClick={() => dispatch(getProducts())}>
      <SearchIcon fontSize='medium' />
    </IconButton>
  </Box>

  return(
    <Box>
      {searchButton()}
    </Box>
  )
}


const StockItems = () => {

  const stockItems = useSelector(SelectedStockItemsSelector);

  return(
    <Box className='stocks'>
      <Grid container>
        <Grid item>
          <Box className='stock-select'>
            <FiltertStockArticles />
            <SelectStockBrand />
            <SearchStockProduct />
            <AddStockItem />
            <SelectStockVariant />
          </Box>
        </Grid>

        <Grid item className='stock-items'>
          <Grid container spacing={2}>
            {stockItems?.
            map((stockItem, i) =>
              <StockItemCard item={stockItem} key={`stock-item-${i}`} />)
            }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}


export default StockItems;