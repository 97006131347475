import React, {useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../app/store";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  CatalogCategories,
  ProductBrands,
  IProductDefinition, ProductGenders, ProductPersonalizations,
  IProductSizeDefinition, ProductStyles, ISizeGuideSnippet
} from "../../../app/catalog";
import MenuItem from "@mui/material/MenuItem";
import {
  addDefinitionColorDagoba,
  addDefinitionSizeDagoba,
  closeDefinition, createJuniorVersion, definitionHasChangesSelector, definitionSavingSelector,
  fetchProductDefinition,
  moveDefinitionSizes, removeDefinitionSizeDagoba,
  shownDefinitonSelector,
  storeDefinitionInfo,
  storeDefinitionSetPerso,
  storeDefinitionSizeDagoba,
  storeDefinitionSizeHeight,
  storeDefinitionSizePrice,
  toggleDefinitionPricePerSize,
  toggleRemoveColor,
  updateProductDefinition,
} from "../../catalog/catalogSlice";
import {FormattedMessage, useIntl} from "react-intl";
import {CustomButton} from "../../utils/CustomButton";
import {ProductColorInfos} from "./ProductColorInfos";
import {PriceFormatted} from "../../../app/product";
import {ReactSortable} from "react-sortablejs";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {useAppSelector} from "../../../app/hooks";
import {BrandMenuItem} from "../../catalog/SelectBrand";
import {openConfirmDialog} from "../../global/globalSlice";
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import {PAGODE_URL} from "../../../app/global";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import admin.scss
import './../admin.scss';

export function ProductForm() {

  const intl = useIntl();

  const [showBackDrop, setShowBackDrop] = useState(false);
  const productDefinitions: IProductDefinition[] | null = useSelector(shownDefinitonSelector);
  const hasChanges = useAppSelector(definitionHasChangesSelector);
  const saving = useAppSelector(definitionSavingSelector);

  const [activeDefinition, setActiveDefinition] = useState<IProductDefinition | null>(null);


  useEffect(() => {
    if (productDefinitions && productDefinitions.length > 0) {
      setActiveDefinition(productDefinitions[0]);
      setupSizes(productDefinitions[0]);
    }
  }, [productDefinitions]);

  useEffect(() => {
    if (!saving) {
      setShowBackDrop(false);
    }
  }, [saving]);

  const editable = (activeDefinition && activeDefinition.source === 'dagoba') || false;

  const [activeColorIndex, setActiveColorIndex] = useState<number>(0);

  const dispatch = useDispatch<AppDispatch>();

  //########################################################################################
  // Sizes
  //########################################################################################

  interface sortableSize {
    id: string,
    sizeDefinition: IProductSizeDefinition
  }

  const [sizeList, setSizeList] = useState<sortableSize[]>([]);

  const setupSizes = (definition: IProductDefinition) => {
    setSizeList(definition.sizes.map((size) => {
      return {id: `srt${size.supplierSize}`, sizeDefinition: size}
    }))
  }

  const handleSortSizes = (newList: sortableSize[]) => {

    let newOrder = newList.map((sortSize) => sortSize.id).join('-');
    let oldOrder = sizeList.map((sortSize) => sortSize.id).join('-');

    if (newOrder !== oldOrder) {
      dispatch(moveDefinitionSizes({sizes: newList.map((sortSize) => sortSize.sizeDefinition)}));
    }
  }

  const setSizeName = (supplierSize: string, value:string) => {
    // console.log("Set size: " + supplierSize + " - " + value)
    dispatch(storeDefinitionSizeDagoba({supplierSize: supplierSize, dagobaSize: value}))
  }

  const setSizeHeight = (supplierSize: string, value:string) => {
    // console.log("Set size: " + supplierSize + " - " + value)
    dispatch(storeDefinitionSizeHeight({supplierSize: supplierSize, height: value}))
  }

  const setSizePrice = (supplierSize: string, value:string) => {
    dispatch(storeDefinitionSizePrice({
      supplierSize: supplierSize,
      priceCents: Math.round(parseFloat(value.replace(',', '.')) * 100)
    }))
  }

  const sizeDefinition = (sortSize: sortableSize, maxWidth: number) => {
    const size = sortSize.sizeDefinition;
    const textFieldWidth = Math.max(7, maxWidth);
    return(
      <div id={sortSize.id} className={`size ${size.warning ? 'warning' : ''}`} key={`siz-${size.supplierSize}`}>
        <IconButton aria-label="close" className='close' onClick={() => removeSize(size.supplierSize)}>
          <CloseIcon />
        </IconButton>
        <TextField size="small" sx={{width: `${textFieldWidth}ch`}}
                    label={size.supplierSize}
                    defaultValue={size.dagobaSize || ''}
                   onBlur={(e) => setSizeName(size.supplierSize, e.target.value)}
        />
        <TextField size="small" sx={{width: `${textFieldWidth}ch`}}
                   defaultValue={size.height || ''}
                   onBlur={(e) => setSizeHeight(size.supplierSize, e.target.value)}
        />
        {activeDefinition && activeDefinition.pricePerSize &&
          <TextField size="small" sx={{width: `${textFieldWidth}ch`}}
                     defaultValue={PriceFormatted(size.priceCents, true) || ''}
                     onBlur={(e) => setSizePrice(size.supplierSize, e.target.value)}
          />
        }
      </div>
    )
  }

  const sizeMaxWidth = () => Math.max(...sizeList.map((sortSize: sortableSize) => sortSize.sizeDefinition.supplierSize.length));

  const togglePricePerSize = () => {
    // console.log("Toggle price per size");
    dispatch(toggleDefinitionPricePerSize({toggle: true}))
  }

  const addSize = () => {
    dispatch(addDefinitionSizeDagoba())
  }

  const removeSize = (size:string) => {
    dispatch(removeDefinitionSizeDagoba(size))
  }

  const allSizes = () => {
    // if (sizeList.length === 0) {
    //   return ''
    // } else {
      const maxWidth = sizeMaxWidth();
      return(
        <div className='size-container'>
          {editable && <>
              <MonetizationOnIcon className={`price ${activeDefinition && activeDefinition.pricePerSize && 'active'}`}
                                  onClick={togglePricePerSize} />
              <AddBoxIcon className='add-size' onClick={addSize} />
            </>
          }
          <ReactSortable group='sizes' filter='input' preventOnFilter={false}
                         className='all-sizes' animation={400} delay={3}
                         easing='cubic-bezier(0.65, 0, 0.35, 1)' dragoverBubble={false}
                         list={sizeList} setList={handleSortSizes}>
            {sizeList.map((sortSize, idx) =>
              sizeDefinition(sortSize, maxWidth))}
          </ReactSortable>
        </div>
      );
    // }
  }

  const juniorSizeCut = () => <>
    <TextField label="Junior" className="junior-cut" size="small" select fullWidth defaultValue={''}
               onChange={(e) => {
                 const juniorCut = e.target.value;

                 dispatch(openConfirmDialog({
                   title: intl.formatMessage({id: "admin.cut-junior-title"}),
                   message: intl.formatMessage({id: "admin.cut-junior-question"}, {juniorCut: juniorCut}),
                   confirm: intl.formatMessage({id: "admin.cut-junior-confirm"}),
                   action: () => {
                     if (activeDefinition?.id === undefined || activeDefinition?.id === null) {
                       setInfos('createJunior', juniorCut === 'No' ? null : juniorCut)
                     } else {
                       if (juniorCut !== 'No') {
                          dispatch(createJuniorVersion({id: activeDefinition?.id, juniorSize: juniorCut }));
                       }
                     }
                   }
                 }));
               }}>
      <MenuItem key='no-junior' value='No'>No</MenuItem>
      {sizeList.map((sortSize  ) => (
        <MenuItem key={sortSize.id} value={sortSize.sizeDefinition.supplierSize}>
          {sortSize.sizeDefinition.supplierSize}
        </MenuItem>
      ))}
    </TextField>
  </>

  //########################################################################################
  // Colors
  //########################################################################################

  const toggleColorRemove = () => {
    dispatch(toggleRemoveColor());
  }

  const addColor = () => {
    dispatch(addDefinitionColorDagoba())
  }

  const allColors = () => {

    if (activeDefinition && activeDefinition.colors.length > 0) {
      const canAddColor = !activeDefinition.colors.some((color) => color.supplierColor === 'New color');

      return(
        <div className='color-container'>
          {editable && <FlipCameraAndroidIcon className='flip' onClick={toggleColorRemove} />}
          {activeDefinition.colors.map((color, index) =>
            <ProductColorInfos key={`${color.supplierColor}-${index}`} colorDefinition={color}
                               gender={activeDefinition.gender}
                               editable={editable || false}
                               colorIndex={index}
                               setActiveColorIndex={setActiveColorIndex}
            />)}
          {editable && canAddColor && <AddBoxIcon className='add-color' onClick={addColor} />}
        </div>
      )
    }
  }

  //########################################################################################
  // Pesonnalizations
  //########################################################################################

  const persoActivated = (persoCode: string) => (productDefinitions && productDefinitions[0] && productDefinitions[0].personalizations.includes(persoCode))

  const allPersos = () => {
    return(
      <div className='all-persos'>
        {ProductPersonalizations.map((persoCode, index: number) => {
          if (persoCode === 'separator') {
            return (<hr key={`pr-hr-${index}`} />)
          } else {
          return (
            <div className='perso' key={persoCode}>
              <FormControlLabel label={<FormattedMessage id={`addon.${persoCode}`}/>}
                                control={ <Checkbox sx={{'&.MuiCheckbox-root': {color: '#808080'}}} size='small'
                                            disabled={false} checked={persoActivated(persoCode) || false}
                                            onChange={(e) => {
                                              dispatch(storeDefinitionSetPerso({persoCode: persoCode, activated:e.target.checked}))
                                            }} /> }
              />
            </div>
          )}
        })}
      </div>
    )
  }

  //########################################################################################
  // Sources of the definition
  //########################################################################################

  const sourceDefinition = (source: string) => productDefinitions?.find((def) => def.source === source);

  const activateDefinition = (source: string) => {
    const definition = sourceDefinition(source)
    if (definition !== undefined) {
      console.log('Before setActiveDefinition');
      setActiveDefinition(definition);
      console.log('Before setupSizes');
      setupSizes(definition);
      console.log('Before setActiveColorIndex');
      setActiveColorIndex(0);
    }
  }

  const sourceClassName = (source:string) => {
    const active = activeDefinition?.source === source ? 'active' : ''
    return `source ${active}`
  }

  const dagobaInfos = () => {
    const definition = sourceDefinition('dagoba')
    if (definition !== undefined) {
      return(
        <Box className={sourceClassName('dagoba')} onClick={() => {activateDefinition('dagoba')}}>
          DAGOBA: {definition.sku}<br/>{definition.id || 'create'}
        </Box>
      )
    }
  }
  const brandInfos = () => {
    const definition = sourceDefinition('brand')
    if (definition !== undefined) {
      return(
        <Box className={sourceClassName('brand')} onClick={() => {activateDefinition('brand')}}>
          {definition.brand}: {definition.brandReference}<br/>{PriceFormatted(definition.brandPriceCents)}
        </Box>
      )
    }
  }
  const lstInfos = () => {
    const definition = sourceDefinition('lst')
    if (definition !== undefined) {
      return(
        <Box className={sourceClassName('lst')} onClick={() => {activateDefinition('lst')}}>
          LST: {definition.lstReference}<br/>{PriceFormatted(definition.lstPriceCents)}
        </Box>
      )
    }
  }
  const ralawiseInfos = () => {
    const definition = sourceDefinition('ralawise')
    if (definition !== undefined) {
      return(
        <Box className={sourceClassName('ralawise')} onClick={() => {activateDefinition('ralawise')}}>
          Ralawise: {definition.ralawiseReference}<br/>{PriceFormatted(definition.ralawisePriceCents)}
        </Box>
      )
    }
  }
  const toptexInfos = () => {
    const definition = sourceDefinition('toptex')
    if (definition !== undefined) {
      return (
        <Box className={sourceClassName('toptex')} onClick={() => {activateDefinition('toptex')}}>
          Toptex: {definition.topTexReference}<br/>{PriceFormatted(definition.topTexPriceCents)}
        </Box>
      )
    }
  }
  const imbretexInfos = () => {
    const definition = sourceDefinition('imbretex')
    if (definition !== undefined) {
      return(
        <Box className={sourceClassName('imbretex')} onClick={() => {activateDefinition('imbretex')}}>
          Imbretex: {definition.imbretexReference}<br/>{PriceFormatted(definition.imbretexPriceCents)}
        </Box>
      )
    }
  }

  //########################################################################################
  // Update current definition
  //########################################################################################

  const setInfos = (field: string, value:string|null) => {
    dispatch(storeDefinitionInfo({field: field, value: value}))
  }

  const setPrice = (value:string) => {
    dispatch(storeDefinitionInfo({field: 'dagobaPriceCents',
      value: Math.round(parseFloat(value.replace(',', '.')) * 100)}))
  }

  const parseLines = (value: string) => value.replaceAll(/(\\n)/g, "\n");

  //########################################################################################
  // Form actions
  //########################################################################################

  const closeForm = () => {
    dispatch(closeDefinition())
  }

  const saveActions = () => {
    return (
      <div className="definition-actions">
        <Button variant="contained" color="primary"
                onClick={() => {
                  dispatch(fetchProductDefinition({
                    sku: activeDefinition?.sku || '',
                    brand: activeDefinition?.brand || '',
                    title: activeDefinition?.dagobaTitle || '',
                    gender: activeDefinition?.gender
                    }));
                }} >
          <FormattedMessage id="global.search" />
        </Button>
        {hasChanges && editable && productDefinitions && productDefinitions[0] &&
          <Button variant="contained" color="success"
                  onClick={() => {
                    setShowBackDrop(true);
                    dispatch(updateProductDefinition(productDefinitions[0]))
                  }
                  }>
            <FormattedMessage id="global.save" />
          </Button>
        }
        <CustomButton onClick={closeForm}>
          <FormattedMessage id={`global.${hasChanges ? 'cancel' : 'close'}`} />
        </CustomButton>
      </div>
    )
  }

  // console.log("Render product definition form");

  const possibleQuantities = Array(99).fill(0).map((_, i) => i + 1).concat([100, 200, 300, 400, 500]);

  const sizeGuideLink = (sizeGuideId: number, sizeGuides: ISizeGuideSnippet[]) => {
    const sizeGuide = sizeGuides.find((sg) => sg.id === sizeGuideId);
    if (sizeGuide) {
      return (
        <div className='size-guide-link'>
          <a href={`${PAGODE_URL}/pages/${sizeGuide.handle}`} target="_blank" rel="noreferrer">{sizeGuide.title}</a>
        </div>
      )
    }
  }

  return(
    <>
      {productDefinitions && productDefinitions[0] && activeDefinition &&
      <Box className='product-definition'
           sx={{pt: 6, pb:1, pl:4, pr: 4, position:'relative'}}>
        <Grid container spacing={2} sx={
          {p: 1, pt:0, border: '1px solid #A0A0A0', borderRadius: 1, height: 'calc(100vh - 190px)', overflowY: 'auto'}
        }>
          <Grid className='product-images' item xs={12} sm={5} sx={{p: 1,border: '1px solid blsue'}}>
            {activeDefinition.colors[activeColorIndex] && activeDefinition.colors[activeColorIndex].images.
            map((image, i) => {
              if (image.url !== 'New') {
                return (
                  <div key={`image${i+1}`} className='product-img'>
                    <img src={image.url} />
                  </div>
                );
              }}
            )}
          </Grid>
          <Grid item xs={12} sm={7} sx={{p: 1,pt:0, border: '1px solid orasnge', overflowY: 'auto'}}>
            {/*<Box component="form" noValidate autoComplete="off" sx={{'& .MuiTextField-root': { mt: 1, mb: 1 }}}>*/}
            <Box sx={{'& .MuiTextField-root': { mt: 1, mb: 1 }}}>
              <Box className='product-references'>
                <>{dagobaInfos()}{brandInfos()}{lstInfos()}{ralawiseInfos()}{toptexInfos()}{imbretexInfos()}</>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField id="brand" label="Brand" size="small" select fullWidth defaultValue={activeDefinition.brand || ''}
                             error={editable && !activeDefinition.brand}
                             onChange={(e) => setInfos('brand', e.target.value)}>
                  {ProductBrands.map((brand,i) => BrandMenuItem(brand, i))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField id="sku" label="SKU" size="small" fullWidth defaultValue={activeDefinition.sku || ''}
                             error={editable && !activeDefinition.sku}
                             onChange={(e) => setInfos('sku', e.target.value)} />
                </Grid>
	              <Grid item xs={12} sm={2}>
                  {activeDefinition.pricePerSize ||
  		              <TextField id="dagoba-price" label="Price" size="small" fullWidth
		                         InputProps={{endAdornment: <InputAdornment position="end">€</InputAdornment>}}
                             defaultValue={PriceFormatted(activeDefinition?.dagobaPriceCents, true)}
                              onChange={(e) => setPrice(e.target.value)} />
                  }
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField id="category" label="Category" size="small" select fullWidth defaultValue={activeDefinition.category || ''}
                             error={editable && !activeDefinition.category}
                             onChange={(e) => setInfos('category', e.target.value)} >
                    {CatalogCategories.map((category  ) => (
                      <MenuItem key={category.code} value={category.code}>
                        <FormattedMessage id={`category.${category.code}`} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
	            <Grid container spacing={2}>
                  <Grid item xs={12} sm={7}>
	                <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <TextField id="dagoba-title" label="DAGOBA Title" size="small" fullWidth defaultValue={activeDefinition.dagobaTitle || ''}
                                   error={editable && !activeDefinition.dagobaTitle}
                                   onChange={(e) => setInfos('dagobaTitle', e.target.value)} />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField id="supplier-title" label="Supplier Title" size="small" fullWidth defaultValue={activeDefinition.supplierTitle || ''}
                                   error={editable && !activeDefinition.supplierTitle}
                                   onChange={(e) => setInfos('supplierTitle', e.target.value)} />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField id="description" label="Description" size="small" fullWidth multiline
                                   error={editable && !activeDefinition.description}
                                   defaultValue={parseLines(activeDefinition.description || '')}
                                   onChange={(e) => setInfos('description', e.target.value)} />
                      </Grid>
                    {editable &&
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            {activeDefinition.sizeGuides && activeDefinition.sizeGuides.length > 0 &&
                            <TextField id="sizeGuide" label="Size guide" size="small" select fullWidth defaultValue={activeDefinition.sizeGuideId || ''}
                                       error={editable && !activeDefinition.sizeGuideId}
                                       onChange={(e) => setInfos('sizeGuideId', e.target.value)} >
                              {activeDefinition.sizeGuides.map((sizeGuide  ) => (
                                <MenuItem key={sizeGuide.id} value={sizeGuide.id}>
                                  {sizeGuide.title}
                                </MenuItem>
                              ))}
                            </TextField>
                            }
                          </Grid>
                          <Grid item xs={3} sx={{display: 'flex', alignItems: 'center'}}>
                            {activeDefinition.sizeGuides &&
                              sizeGuideLink(activeDefinition.sizeGuideId || 0, activeDefinition.sizeGuides)
                            }
                          </Grid>
                          <Grid item xs={3} sx={{display: 'flex'}}>
                            {juniorSizeCut()}
                          </Grid>
                        </Grid>
                      </Grid>
                    }
	                </Grid>
                </Grid>
		            <Grid item xs={12} sm={5}>
                  <Grid container spacing={0}>

	                  <Grid item xs={12}>
		                  <Grid container spacing={1}>
			                  <Grid item xs={6}>
				                  <TextField id="style" label="Style" size="small" select fullWidth
                                     defaultValue={activeDefinition.style || ''}
				                             error={editable && !activeDefinition.style}
				                             onChange={(e) => setInfos('style', e.target.value)}>
                            {ProductStyles.map((style,i) => (
                              <MenuItem key={`style${i+1}`} value={style}>{style}</MenuItem>
                            ))}
				                  </TextField>
                        </Grid>
			                  <Grid item xs={6}>
				                  <TextField id="gender" label="Gender" size="small" select fullWidth
                                     defaultValue={activeDefinition.gender}
				                             onChange={(e) => setInfos('gender', e.target.value)} >
                            {ProductGenders.map((gender  ) => (
                              <MenuItem key={`g${gender.code}`} value={gender.code}>{gender.name}</MenuItem>
                            ))}
				                  </TextField>
			                  </Grid>
		                  </Grid>
	                  </Grid>

                    <Grid item xs={12}>
                      <TextField id="fabric" label="Fabric" size="small" fullWidth defaultValue={activeDefinition.fabric || ''}
                                 onChange={(e) => setInfos('fabric', e.target.value)}/>
                    </Grid>

	                  <Grid item xs={12}>
		                  <Grid container spacing={1}>
			                  <Grid item xs={6}>
				                  <TextField id="grammage" label="Grammage" size="small" fullWidth defaultValue={activeDefinition.grammage || ''}
				                             onChange={(e) => setInfos('grammage', e.target.value)} />
			                  </Grid>
			                  <Grid item xs={6}>
				                  <TextField id="weight" label="Weight" size="small" fullWidth
				                             InputProps={{endAdornment: <InputAdornment position="end">kg</InputAdornment>}}
				                             defaultValue={activeDefinition.weight || ''}
				                             onChange={(e) => setInfos('weight', e.target.value)} />
			                  </Grid>
		                  </Grid>
	                  </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
	                        <FormControlLabel label="Cannot sell alone" control={
                            <Checkbox sx={{'&.MuiCheckbox-root': {color: '#808080'}}} size='small'
                                      disabled={false} checked={activeDefinition.cannotSellAlone || false}
                                      onChange={(e) =>
                                        setInfos('cannotSellAlone', e.target.checked ? 'true' : 'false')
                            } />
                          } />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField id="minQuantity" label="Mininum quantity" size="small" select fullWidth
                                     defaultValue={activeDefinition.minQuantity || 1}
                                     onChange={(e) => setInfos('minQuantity', e.target.value)}>
                            {possibleQuantities.map((qty,i) => (
                              <MenuItem key={`qty${i+1}`} value={qty}>{qty}</MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>

                    {editable &&
                      <Grid item xs={12}>
                        <TextField id="connectionCode" label="Connection code" size="small" fullWidth defaultValue={activeDefinition.connectionCode || ''}
                                   onChange={(e) => setInfos('connectionCode', e.target.value)} />
                      </Grid>
                    }
                    {activeDefinition.connections && activeDefinition.connections.length > 0 &&
			                  <Grid item xs={12}>
				                  <div className='connections'>
                            {activeDefinition.connections.map((connection, index) =>
                              <div>{connection}</div>
                            )}

                            {activeDefinition.connectionCode &&
							                  <div>
								                  <br/>{activeDefinition.connectionCode}
							                  </div>}
				                  </div>
			                  </Grid>
                    }

                  </Grid>
                </Grid>
              </Grid>
	            <Grid item xs={12}>
                  {allSizes()}
                </Grid>
	            <Grid item xs={12}>
                  {allColors()}
	            </Grid>
	            <Grid item xs={12}>
                  {allPersos()}
	            </Grid>
            </Box>
          </Grid>
        </Grid>
        {saveActions()}
      </Box>}
      <Backdrop sx={{ color: '#fff', zIndex: 1}} open={showBackDrop} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )

}
