import React, {useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../app/store";
import Box from "@mui/material/Box";
import {getAccessToken} from "../../../app/sessionAPI";
import {
  currentOrderSelector,
  currentSellerSelector, currentStockViewSelector,
  dateRangeSelector,
  exportProduction,
  getItems,
  getMarkings, getStocks,
  itemsLoadedSelector,
  markingSavingSelector,
  markingsLoadedSelector, markingsProductionDaySelector, markingsTypeSelector, stocksLoadedSelector
} from "./productionSlice";
import WorkshopBoxes from "./WorkshopBoxes";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {ProductionList} from "./ProductionList";
import {CustomButton} from "../../utils/CustomButton";
import {useRouteMatch} from "../../../app/global";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Button, CircularProgress} from "@mui/material";
import {openInFullScreen} from "../../global/globalSlice";
import {SearchOrder} from "./SearchOrder";
import {SearchBoutique} from "./SearchBoutique";
import {SelectWorkshop} from "./SelectWorkshop";
import ProductionDeliveries from "./ProductionDeliveries";
import {SelectEndDate} from "./SelectEndDate";
import {ProductionReporting} from "./ProductionReporting";
import {SelectStartDate} from "./SelectStartDate";
import {ProductionMarkings} from "./ProductionMarkings";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from '@mui/icons-material/Refresh';
import {SelectMarkingsDay} from "./SelectMarkingsDay";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {IUser} from "../../sessions/sessionSlice";
import {SelectMarkingType} from "./SelectMarkingType";
import {SelectBoxesSortBy} from "./SelectBoxesSortBy";
import {SelectSeller} from "./SelectSeller";
import {useAppSelector} from "../../../app/hooks";
import StockBoxes from "./StockBoxes";
import {SelectStocksView} from "./SelectStocksView";
import StockItems from "./StockItems";
import {ScanQRCodeButton} from "./ScanQRCode";
import {getWorkshopStocks} from "../../../app/productionAPI";
import {SelectStockBrand} from "./SelectStockBrand";
import {SearchStockProduct} from "./SearchStockProduct";

const PRODUCTION_VIEWS = [
  {
    name: 'Commandes',
    path: '/admin/production/orders',
    scope: 'orders'
  },
  {
    name: 'Bacs',
    path: '/admin/production/boxes',
    scope: 'orders'
  },
  {
    name: 'Livraisons',
    path: '/admin/production/deliveries',
    scope: 'deliveries'
  },
  {
    name: 'Stocks',
    path: '/admin/production/stocks',
    scope: 'stocks'
  },
  {
    name: 'Marquages',
    path: '/admin/production/markings',
    scope: 'markings'
  },
  {
    name: 'Exports',
    path: '/admin/production/exports',
    scope: 'exports'
  }
]

export function ProductionAdmin() {

  const dispatch = useDispatch<AppDispatch>();

  const accessToken = getAccessToken();
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const userProduction = currentUser?.production

  const itemsLoaded = useSelector(itemsLoadedSelector);
  const stocksLoaded = useSelector(stocksLoadedSelector);
  const markingsLoaded = useSelector(markingsLoadedSelector);
  const markingSaving = useSelector(markingSavingSelector);
  const selectedDateRange = useSelector(dateRangeSelector);
  const markingsProductionDay = useAppSelector(markingsProductionDaySelector)
  const currentSeller = useAppSelector(currentSellerSelector);
  const currentOrder = useAppSelector(currentOrderSelector);
  const markingType = useAppSelector(markingsTypeSelector);
  const currentStockView = useAppSelector(currentStockViewSelector);

  useEffect(() => {
    if (accessToken && accessToken !== 'undefined' && userProduction) {
      if (!markingsLoaded) {
        dispatch(getMarkings())
      }
      if (!itemsLoaded) {
        dispatch(getItems())
      }
      if (!stocksLoaded) {
        dispatch(getStocks())
      }
    }
  }, [dispatch, accessToken, currentUser, itemsLoaded, markingsLoaded]);

  const routeMatch = useRouteMatch(PRODUCTION_VIEWS.map((view) => view.path));
  // console.log("routeMatch => ", routeMatch?.pattern?.path)

  const allowedViews = PRODUCTION_VIEWS
    .filter((view) => currentUser?.[`view_${view.scope}` as keyof IUser])

  const currentView = allowedViews
    .find((view) => routeMatch?.pattern?.path === view.path)?.name
  || allowedViews
    .filter((view) => currentUser?.[`update_${view.scope}` as keyof IUser])[0]?.name
    || allowedViews[0]?.name;

  const cannotExport = currentView === 'Marquages' && markingsProductionDay.date !== 'none'

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const ProductionViewSelector = () => {
    return (<>
      <CustomButton
        id="basic-button"
        backgroundcolor='#D0D0D0'
        textcolor='#000000'
        hoverbackgroundcolor='#B0B0B0'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={'production-view-selector'}
      >
        {currentView}
      </CustomButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {allowedViews.map((view, index) => {
          return (
            <MenuItem key={`view-${index}`} onClick={() => {
              handleClose();
              navigate(view.path.replace('/*', ''))
            }}>
              {view.name}
            </MenuItem>
          )
        })}
      </Menu>
    </>);
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const box = searchParams.get('box')

  useEffect(() => {
    if (box) {
      dispatch(openInFullScreen({
        name: 'WorkshopBoxDetails',
        displayedId: parseInt(box),
        param: 'box'
      }))
      setSearchParams([])
    }
  }, [searchParams])

  const refreshButton = () => <Box className='refresh'>
    <IconButton size='large' onClick={() => window.location.reload()}>
      <RefreshIcon fontSize='large' />
    </IconButton>
  </Box>

  const ProductionStocks = () => {
    if (!stocksLoaded) {
      return (
        <Box className='loading-production'>
          <Box>
            Récupération des données de stock ...
          </Box>
          <CircularProgress color="inherit" size={100} />
        </Box>
      )
    }

    switch (currentStockView) {
      case 0:
        document.title = "Articles dans le stock";
        return <StockItems />;
      case 1:
        document.title = "Boîtes de stocks";
        return <StockBoxes />;
      default:
        return <StockItems />;
    }

  }

  return(<>
    <Box className='production-admin'>
      <Box className='production-filter' >
        <ProductionViewSelector/>
        {currentView === 'Stocks' && <SelectStocksView />}

        {currentView !== 'Stocks' && <SearchOrder />}

        {currentView === 'Bacs' && <SelectBoxesSortBy />}

        {currentView === 'Exports' && <SelectStartDate />}
        {currentView !== 'Bacs' && currentView !== 'Marquages' && currentView !== 'Stocks' && <SelectEndDate /> }

        {currentView !== 'Stocks' && <SelectSeller />}

        {currentView !== 'Exports' && currentView !== 'Marquages' && currentView !== 'Stocks' && <SearchBoutique />}
        {currentView !== 'Exports' && currentView !== 'Marquages' && <SelectWorkshop />}

        {currentView === 'Marquages'  && <SelectMarkingType />}

        {currentView !== 'Exports' && currentView !== 'Stocks' && <> <SelectMarkingsDay /> </>}

        {(currentView === 'Marquages' || currentView === 'Exports') && currentUser?.view_exports &&

            <Button className='export-button' variant="contained" color="primary"
                    onClick={() => dispatch(exportProduction({
                      view: currentView,
                      startDate: selectedDateRange?.startDate,
                      endDate: selectedDateRange?.endDate,
                      sellerId: currentSeller,
                      orderNumber: currentOrder,
                      markingType: markingType
                    }))}
                    disabled={markingSaving || cannotExport} >
              {markingSaving &&  <CircularProgress color="inherit" className='save-marking' size={20}/> ||
                  <FileDownloadIcon />}
              Exporter
            </Button>
        }

        {currentView !== 'Marquages' && currentView !== 'Exports' && refreshButton() }
        {currentView !== 'Exports' && currentView !== 'Marquages' && <ScanQRCodeButton />}
      </Box>
      {currentView === 'Exports' && <ProductionReporting /> ||
        ( !itemsLoaded &&

        <Box className='loading-production'>
          <Box>
            Récupération des données de production ...
          </Box>
          <CircularProgress color="inherit" size={100} />
        </Box>
        )
        ||
        (currentView === 'Commandes' && <ProductionList/>) ||
        (currentView === 'Bacs' && <WorkshopBoxes/>) ||
        (currentView === 'Marquages' && <ProductionMarkings />) ||
        (currentView === 'Livraisons' && <ProductionDeliveries />) ||
        (currentView === 'Stocks' && <ProductionStocks />)
      }
    </Box>
  </>)

}
