import axios from "./axios";
import {CLIENT_ID, CLIENT_SECRET, ModelInfo} from "./global";
import {IBoutiqueInfos, ICollectionProduct, RequestPreviewData} from "../features/dashboard/boutiqueSlice";
import {IProductSearch} from "./catalog";
import {IRequestedDemoPayload, IUpdateDemoPayload} from "../features/dashboard/DemoBoutique";


const BOUTIQUES_URL = "/boudists/boutiques";
const UPDATE_BOUTIQUE_URL = "/boudists/update_boutique";
const APPLY_BOUTIQUE_URL = "/boudists/apply_boutique";
const DELETE_BOUTIQUE_URL = "/boudists/delete_boutique";
const DEPLOY_BOUTIQUE_URL = "/boudists/deploy_boutique";
const DEMO_BOUTIQUE_URL = "/boudists/demo_boutique";
const CANCEL_DEMO_URL = "/boudists/cancel_demo";
const UPDATE_COLLECTION_URL = "/boudists/update_collection";
const PREVIEW_BOUTIQUE_PRODUCT_URL = "/boudists/preview_boutique_product";
const CHECK_SUBDOMAIN_AVAILABILITY_URL = "/boudists/check_subdomain_availability";

export async function fetchBoutiques() {
  return axios
    .get(BOUTIQUES_URL)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function getSubdomainAvailability(payload:{ id: number | null, subdomain:string }) {

  // console.log("Calling boutiqueAPI getSubdomainAvailability");
  const data = {
    boutique_id: payload.id,
    subdomain: payload.subdomain,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .put(CHECK_SUBDOMAIN_AVAILABILITY_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function requestApplyBoutiqueChanges(boutiqueId: number) {
  const data = {
    boutique_id: boutiqueId,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(APPLY_BOUTIQUE_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function requestDeleteBoutique(boutiqueId: number) {
  const data = {
    boutique_id: boutiqueId,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(DELETE_BOUTIQUE_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}
export async function updateBoutique(boutique: IBoutiqueInfos) {
  const data = {
    boutique: boutique,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(UPDATE_BOUTIQUE_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function requestDeployBoutique(boutique: IBoutiqueInfos) {
  const data = {
    boutique: boutique,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(DEPLOY_BOUTIQUE_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}


export async function requestDemoBoutique(demo: IUpdateDemoPayload) {
  const data = {
    email: demo.email,
    boutique: demo.boutique,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(DEMO_BOUTIQUE_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function requestCancelDemo() {
  const data = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(CANCEL_DEMO_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}


export async function patchCollectionInfo(accessToken: string, collection_id:number|null, info: ModelInfo) {
  const data = {
    id: collection_id,
    field: info.field,
    value: info.value,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return axios
    .patch(UPDATE_COLLECTION_URL, data, config)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}


export async function previewBoutiqueProduct(requestInfos: RequestPreviewData) {
  const data = {
    infos: requestInfos,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(PREVIEW_BOUTIQUE_PRODUCT_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}
