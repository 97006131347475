import React from 'react';
import {useDispatch} from "react-redux";
import {FormControl, FormHelperText} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {currentStockViewSelector, selectStocksView} from "./productionSlice";
import {useAppSelector} from "../../../app/hooks";
import TextField from "@mui/material/TextField";

const STOCK_VIEWS = [
  {
    code: 0,
    name: 'Articles'
  },
  {
    code: 1,
    name: 'Boîtes'
  },
];
export function SelectStocksView() {

  const currentStockView = useAppSelector(currentStockViewSelector);
  const dispatch = useDispatch();

  const changeView = (event: any) => {
    dispatch(selectStocksView(event.target.value))
  };

  return (
    <FormControl sx={{ mr: 1, minWidth: 40}} size="small" className='select-tool search-stock'>
      <FormHelperText sx={{ml:0}}>Voir les</FormHelperText>
      <TextField id="select-boxes-order"  size="small" select value={currentStockView} onChange={changeView}>
        {STOCK_VIEWS.map((stockView) => (
          <MenuItem key={`stockView-${stockView.code}`} value={stockView.code}>{stockView.name}</MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
}
