import axios from "./axios";
import {CLIENT_ID, CLIENT_SECRET} from "./global";
import {ISelectionPayloadItem} from "../features/selection/selectionSlice";

const SELECTED_PRODUCTS_URL = "/boudists/selected_products";
const ADD_PRODUCT_URL = "/boudists/add_product";
const REMOVE_PRODUCT_URL = "/boudists/remove_product";
const ERASE_SELECTION_URL: string = "/boudists/erase_selection";


export async function getSelectedProducts() {
  // console.log("getSelectedProducts")
  return axios
    .get(SELECTED_PRODUCTS_URL)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function addProductToSelection(selectedProduct: ISelectionPayloadItem) {
  const data = {
    product: selectedProduct,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(ADD_PRODUCT_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}


export async function removeProductFromSelection(productId: number, otherVersions: boolean) {
  const data = {
    product_id: productId,
    otherVersions: otherVersions,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(REMOVE_PRODUCT_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function requestEraseSelection() {
  const data = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(ERASE_SELECTION_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}