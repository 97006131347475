import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
  BOXES_SORT_BY,
  boxesSortBySelector,
  selectBoxesSortBy,
} from "./productionSlice";
import {useAppSelector} from "../../../app/hooks";
import TextField from "@mui/material/TextField";

export function SelectBoxesSortBy() {

  const dispatch = useDispatch();
  const boxesSortBy = useAppSelector(boxesSortBySelector);

  const changeBoxesSortBy = (event: any) => {
    dispatch(selectBoxesSortBy(event.target.value))
  };

  return (
    <FormControl sx={{ mr: 1, minWidth: 150}} size="small" className='select-tool search-boxes'>
      <FormHelperText sx={{ml:0}}>Trier par</FormHelperText>
      <TextField id="select-boxes-order"  size="small" select
                 value={boxesSortBy || 'priority'} onChange={changeBoxesSortBy}>
        {BOXES_SORT_BY.map((box_sort_h, i) => (
          <MenuItem key={`box-sort-${i+1}`} value={box_sort_h.code}>{box_sort_h.name}</MenuItem>
        ))}
      </TextField>
    </FormControl>
  )

}
