import React, {useState} from 'react';
import {ILogoInfos, ILogoVersion} from "../logos/logosSlice";
import LogoVersionSelect from "../logos/LogoVersionSelect";
import {DefaultLogoVersion, IAddonInfos, ICollectionProduct, IImageAddon} from "./boutiqueSlice";
import {FormattedMessage} from "react-intl";
import {ToggleButton, ToggleButtonGroup, useMediaQuery} from "@mui/material";

export const SelectAddonLogo = (props: {
  collectionProduct: ICollectionProduct,
  logos: ILogoInfos[],
  imageAddon: IImageAddon,
  addonInfo: IAddonInfos,
  selectLogoVersion: (logoId: number, logoVersionId: number) => void,
  noneAllowed: boolean,
  editMode: boolean
}) => {

  const matches = useMediaQuery("(min-width:800px)");

  const {collectionProduct, logos, imageAddon, addonInfo, selectLogoVersion, noneAllowed, editMode} = props;

  const category = `${collectionProduct.product.category}${collectionProduct.product.subCategory}`;
  const junior = collectionProduct.product.gender === 3;

  // initial value is the one already saved or the default value based on the addon name
  const [logosCurrentVersionIds, setLogosCurrentVersionIds] = useState<number[]>(logos.map(logo => {
    if (logo.id === addonInfo.logoId && addonInfo.logoVersionId !== 0) {
      return addonInfo.logoVersionId;
    } else {
      return DefaultLogoVersion(imageAddon, logo, junior, category)?.id || logo.versions[0]?.id || 0;
    }
  }));

  const handleSelectLogo = (
    event: React.MouseEvent<HTMLElement>,
    newLogoId: number | 0,
  ) => {
    const currentVersionId = logosCurrentVersionIds[logos.findIndex(logo => logo.id === newLogoId)];
    if (newLogoId !== null && newLogoId !== undefined && newLogoId !== addonInfo.logoId) {
      selectLogoVersion(newLogoId, currentVersionId);
    }
  };

  const handleSelectLogoVersion = (newLogoId: number, logoVersionId: number) => {
    const newCurrentVersions = logosCurrentVersionIds.map((currentVersionId, index) => {
      if (logos[index].id === newLogoId) {
        return logoVersionId;
      } else {
        return currentVersionId;
      }
    });
    // console.log("handleSelectLogoVersion, new logo " + newLogoId + " with new version " + logoVersionId + " and newCurrentVersions " + JSON.stringify(newCurrentVersions));
    setLogosCurrentVersionIds(newCurrentVersions);
    selectLogoVersion(newLogoId, logoVersionId);
  }

  // console.log("Render SelectAddonLogo for addon " + imageAddon.name + " with logo " + addonInfo.logoId + " and logoCurrentVersions " + JSON.stringify(logosCurrentVersionIds) + " and version " + logosCurrentVersionIds[logos.findIndex(logo => logo.id === addonInfo.logoId)]);

  return (
    <ToggleButtonGroup orientation={`${matches ? `horizontal` : `vertical`}`} size="small" disabled={!editMode}
                       value={addonInfo.logoId} exclusive onChange={handleSelectLogo}>

      <ToggleButton key={`${imageAddon.id}-0`} value={0} size="small" className='select-logo-none' disabled={!noneAllowed}>
        <span><FormattedMessage id="global.none" /></span>
      </ToggleButton>

      {logos.map((logo: ILogoInfos, version_idx: number) => (
        <ToggleButton key={`${imageAddon.id}-${version_idx}`} value={logo.id} size="small" className='select-logo-button'>
          <LogoVersionSelect collectionProduct={collectionProduct} imageAddon={imageAddon} logo={logo}
                             versionId={logosCurrentVersionIds[version_idx]} setVersionId={handleSelectLogoVersion} />
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
