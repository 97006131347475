import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {FormControl, FormHelperText} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {FormattedMessage} from "react-intl";
import {
  currentOrderStatusSelector,
  currentWorkshopSelector, operatorForOrderStatus, productionOrdersSelector,
  selectWorkshop,
  workshopListSelector
} from "./productionSlice";
import {useAppSelector} from "../../../app/hooks";
import Box from "@mui/material/Box";

export function AssignStatus(props: {
  setNewStatus: (newOrderStatus: number, newStatusName: string) => void,
}) {

  const {setNewStatus} = props
  const currentWorkshop = useSelector(currentWorkshopSelector);
  const [selectedStatus, setSelectedStatus] = React.useState('none');

  const choosableStatuses = currentWorkshop?.statuses
    .filter((status) => operatorForOrderStatus(status.orderStatus)) || []

  const handleSelect = (event: SelectChangeEvent) => {
    const newStatusName = event.target.value;
    const newOrderStatus = choosableStatuses
      .find((status) => status.statusName === newStatusName)?.orderStatus || 0

    setNewStatus(newOrderStatus, newStatusName)
    setSelectedStatus('none')
  }

  return (
    <FormControl sx={{ mr: 1, minWidth: 150 }} size="small" className='assign-status'>
      <Select value={selectedStatus} onChange={handleSelect}>
        <MenuItem key='ass-status0' value="none" className='status-choice'></MenuItem>
        {choosableStatuses.map((status, i) => (
            <MenuItem key={`ass-status${i+1}`} value={status.statusName}
              className='status-choice' sx={{backgroundColor: status.bgColor, color: status.fgColor}}>
              {status.statusName}
            </MenuItem>
        ))}
      </Select>
    </FormControl>
  )


}
