import React, {useState} from 'react';
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {IImageAddon, IPersoInfos} from "./boutiqueSlice";
import {FormattedMessage} from "react-intl";
import {SMALL_PERSO_CONTENTS, BIG_PERSO_CONTENTS} from "./PersoAddonsList";


export const SelectAddonPerso = (props: {
  imageAddon: IImageAddon,
  disabled: boolean,
  persoInfos: IPersoInfos,
  setPersoContent: (content: string) => void
  }) => {

  // console.log("Render SelectAddonPerso for " + props.imageAddon.name + " with content " + props.persoInfos.content);

  const {imageAddon, disabled, persoInfos, setPersoContent} = props;
  const persoContent = persoInfos.content;

  const availablePersoContents = (imageAddon.name.startsWith('big')) ? BIG_PERSO_CONTENTS : SMALL_PERSO_CONTENTS;

  const handleContentChange = (event: SelectChangeEvent<string>) => {
    event.stopPropagation();   // prevent the click on the content selection to trigger the activation of the addon
    // console.log("SelectAddonPerso: handleContentChange for " + imageAddon.name + " with new content " + event.target.value);
    setPersoContent(event.target.value as string);
  }

  // console.log("Render LogoVersionSelect for logo " + logo.id + " with version " + selectedVersion);

  return (
    <div className='perso-content'>
      <Select disabled={disabled} SelectDisplayProps={{
        style: { paddingTop: 2, paddingBottom: 2, paddingLeft: 4, paddingRight: 20, fontSize: '0.8em' } }}

              className={'select-content'}
              size="small" value={persoContent} onChange={handleContentChange}
              onClick={(e) => { e.stopPropagation(); }}>
        {availablePersoContents.map((content: string) => {
          return (
            <MenuItem key={`${imageAddon.id}-${content}`} value={content}>
              <FormattedMessage id={`addon.${content}`} />
            </MenuItem>
          );
        })}
      </Select>
    </div>
  )

}
