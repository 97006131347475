import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../../app/store";
import {requestLogs} from "../../../app/logsAPI";
import {
  IArticle,
  ICustomer,
  IDeliveryInfos,
  IProductionBoutique,
  IShippingInfos,
  ISingleItem
} from "../production/productionSlice";


export interface ILog {
  id: number,
  date: string,
  doer: string,
  action: string,
  verb: string,
  boudist: string,
  orderNumber: string,
  article: string,
  orderStatus: string,
  boutique: string,
  vendor: string,
  workshop: string,
  workshopBox: string,
  details: string
}

export interface LogsState {
  logs: ILog[];
  loaded: boolean;
  error: boolean;
}

const initialState: LogsState = {
  logs: [],
  loaded: false,
  error: false,
};

export const getLogs = createAsyncThunk(
  "logs/getLogs",
  async (_, {rejectWithValue}) => {

    const response = await requestLogs();
    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return rejectWithValue(response.data);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    clearLogs: (state) => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder

/////////////////////////////////////////////////////////////////////////////
// getLogs
/////////////////////////////////////////////////////////////////////////////

      .addCase(getLogs.pending, (state) => {
        state.loaded = false;
      })
      .addCase(getLogs.fulfilled, (state, action: any) => {
        state.logs = action.payload;
        state.loaded = true;
        state.error = false;
      })
      .addCase(getLogs.rejected, (state, action: any) => {
        state.loaded = false;
        state.error = true;
      })

  }
});

export const {
  clearLogs
} = logsSlice.actions;


export default logsSlice.reducer;

export const logsLoadedSelector = ((state: RootState) => state.logs.loaded);
export const logsSelector = ((state: RootState) => state.logs.logs);

