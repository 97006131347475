import React, {useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import LogsTable from "./LogsTable";

export function LogsAdmin() {

  document.title = 'Logs';

  return(
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, w:1, minHeight: '80vh', backgroundColor: 'yellows'}} >
      {/*<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems:'center', backgroundColor: 'reds'}} >*/}
      {/*  <LogsSearch />*/}
      {/*</Box>*/}
      <Box sx={{flex: 1, backgroundColor: 'bslue'}} >
        <LogsTable />
      </Box>
    </Box>
  )
}
