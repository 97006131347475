import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import React, {useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import {CircularProgress, InputAdornment} from "@mui/material";
import {grey} from "@mui/material/colors";
import Box from "@mui/material/Box";
import {ISelectedOrder, savingSelector} from "./productionSlice";
import {CustomButton} from "../../utils/CustomButton";
import {PriceFormatted} from "../../../app/product";

const DeliveryDpd = (props: {
  trackingNumber: string | null,
  trackingComment: string | null,
  price: number | null,
  nbProducts: number,
  open: boolean,
  handleClose: () => void,
  handleSave: (dpdNumber: string, dpdComment: string, dpdPrice: number) => void,
}) => {

  const {trackingNumber, trackingComment, price, nbProducts, open, handleClose, handleSave} = props;
  const trackingNumberRef = useRef<HTMLInputElement>();
  const trackingCommentRef = useRef<HTMLInputElement>();
  const priceRef = useRef<HTMLInputElement>();
  const saving = useSelector(savingSelector);
  const [trackingError, setTrackingError] = React.useState(false);
  const [priceError, setPriceError] = React.useState(false);

  const handleSaveClick = () => {

    const trackingNumber = trackingNumberRef?.current?.value || '';
    const trackingComment = trackingCommentRef?.current?.value || '';

    if (trackingNumber.length === 0) {
      setTrackingError(true);
      return;
    } else {
      setTrackingError(false);
    }

    const priceEuros = priceRef?.current?.value ?
      parseFloat(priceRef.current.value.replace(/,/, '.')) : -1;

    if (priceEuros < 0) {
      setPriceError(true);
      return;
    } else {
      setPriceError(false);
    }

    handleSave(trackingNumber, trackingComment, priceEuros*100);

  }

  const nbProductsText = nbProducts > 1 ? `${nbProducts} articles` : '1 article';
  const title = !!trackingNumber ? `Modifier l'expédition N° ${trackingNumber}` : `Expédier ${nbProductsText} par DPD`;

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Veuillez renseigner le numéro de suivi DPD et le prix de l'expédition. Si plusieurs colis, mettre le premier colis dans le numéro de suivi et tous les colis dans Informations. La commande sera automatiquement passée en Départ Livraison.
        </DialogContentText>
        <TextField variant="outlined" autoFocus margin="dense" inputRef={trackingNumberRef}
                   defaultValue={trackingNumber} label="Numéro de suivi" fullWidth
                   sx={{mt:2}} error={trackingError} />
        <TextField variant="outlined" autoFocus margin="dense" inputRef={trackingCommentRef}
                   defaultValue={trackingComment} label="Informations" fullWidth
                   sx={{mt:2}} multiline rows='5' />
        <Box sx={{display: 'flex', alignItems: 'center', mt: 2, fontSize: '1.3em'}}>
          <TextField variant="outlined" margin="dense" inputRef={priceRef}
                     defaultValue={PriceFormatted(price, true)}
                     InputProps={{endAdornment: <InputAdornment position="end">€</InputAdornment>}}
                     label="Coût" sx={{width: '120px'}} error={priceError} />
        </Box>

      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleClose}>Annuler</CustomButton>
        <Box sx={{position: 'relative', ml:'5px'}}>
          <Button variant="contained"disabled={saving} color="success" onClick={handleSaveClick}>
            Enregistrer
          </Button>
          {saving && (
            <CircularProgress
              size={24}
              sx={{
                color: grey[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default DeliveryDpd;