import ColorTag from "../catalog/ColorTag";
import React, {useEffect, useState} from "react";
import {
  GenderDisplayHash,
  IProduct,
  ProductPricesString,
  VariantForColor,
  IVariantInfos
} from "../../app/product";
import {useAppSelector} from "../../app/hooks";
import {
  ISelectedProduct,
  addProduct,
  removeProduct,
  SelectedProducts, ISelectedProductPayload,
} from "../selection/selectionSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../app/store";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Grid, FormControlLabel, Checkbox, CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {CustomButton} from "../utils/CustomButton";
import {FormattedMessage, useIntl} from "react-intl";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {closeProduct, openProduct} from "./productDetailsSlice";
import {CatalogStateSelector} from "../catalog/catalogSlice";
import {getAccessToken} from "../../app/sessionAPI";
import {openSpeedySignup} from "../sessions/sessionSlice";
import Typography from "@mui/material/Typography";
import {openSnackBar} from "../global/globalSlice";
import {VariantColorsScore} from "../../app/catalog";
import {IProductColorImages, productUsageSelector} from "../dashboard/boutiqueSlice";

const MAX_COLORS = 3;

export function ProductDetails(props: {product: IProduct}) {

  const intl = useIntl();

  const {product} = props;
  // console.log("%cRendering ProductDetails for product " + product.id + " " + product.title + " " + product.sku, 'color: #008000');

  // console.log("%cDisplaying product", 'color: #008000', product);
  const catalogState = useAppSelector(CatalogStateSelector);

  ////////////////////////////////////////////////////////////////////////////
  // INFORMATIONS ABOUT THE PRODUCT BEING PRESENTED IN THE BOUTIQUES

  // useAppSelector does not seem to be called every time in ProductDetails so replaced by useSelector below
  // const productBoutiquesAndCollections = useAppSelector((state) =>
  //     productBoutiquesAndCollectionsSelector(state, product.id));
  const productBoutiquesAndCollections = useSelector(state =>
    productUsageSelector(state, product.id));

  const colorInCollections = Array.from(new Set(
      productBoutiquesAndCollections.flatMap((pbc) => (pbc ? pbc.colors : []))
  ));

  const listOfBoutiquesOccurences = Array.from(new Set(
      productBoutiquesAndCollections.map((pbc) => (pbc ? pbc.collection : ''))
  )).join(', ');

  const listOfBoutiquesOccurencesForColor = (color:string) => {
    return(Array.from(new Set(
      productBoutiquesAndCollections.filter((pbc) => (pbc && pbc.colors.includes(color)))
          .map((pbc) => (pbc ? pbc.collection : ''))
    )).join(', '));
  }
  ////////////////////////////////////////////////////////////////////////////

  // const allProducts = useAppSelector(CatalogAllProducts);
  const allProducts = catalogState.categories.flatMap(category => category.products)
  const getProduct = (pid: number) => allProducts.find(p => p.id === pid);

  let connectedProducts: IProduct[] = [];

  if (product && product.connectedProductIds.length > 0) {
    product.connectedProductIds.forEach(id => {
      const foundProduct = getProduct(id);
      if (foundProduct !== undefined) {
        connectedProducts.push(foundProduct);
        // console.log("%cAdded connected product", 'color: #000080', foundProduct);
      }
    });
  }

  // checking if this product is already in the user's product selection
  const productSelection = useAppSelector(SelectedProducts);
  const productSelected = productSelection.find(sel_p => sel_p.product?.id === product.id);
  const productIsSelected = !(productSelected === undefined);
  const productIsObsolete = productIsSelected && productSelected.obsolete;
  const saving = useAppSelector(state => state.selection.saving);


  // const [productVariants, setProductVariants] = useState<VariantInfos[]>(product.variants);
  // if product is coming from Selection, it will not have the colorMatch score as they are
  // only dependent on the current catalog choice of primary and secondary colors, which can vary
  // after the product was added to the selection
  // so we add the colorMatch scores and sort the variants by colorMatch

  let variantsWithColorMatch: IVariantInfos[] = [];

  if (product.variants[0].colorMatch === undefined) {
    variantsWithColorMatch = product.variants.map((variant: IVariantInfos) => ({
      ...variant,
      colorMatch: VariantColorsScore(product, variant, catalogState.primaryColor, catalogState.primaryColorHex,
          catalogState.secondaryColor, catalogState.secondaryColorHex)
    } as IVariantInfos)).sort((a, b) => b.colorMatch - a.colorMatch);
  } else {
    variantsWithColorMatch = product.variants;
  }

  let closeColors = variantsWithColorMatch.filter((variant: IVariantInfos) => variant.colorMatch > 100);
  let otherColors = variantsWithColorMatch.filter((variant: IVariantInfos) => !(variant.colorMatch > 100))

  // closeColors.forEach((color:VariantInfos) => {
  //   console.log("%cClose color " + color.id, 'color: #002020');
  // });


  //selected colors are the first color (=displayed in the catalog view) if product is not in selection,
  //otherwise the chosen colors of the selected product
  const productSelectedColors = productIsSelected ? productSelected.colors.map(pColorImages => pColorImages.color) :
    [variantsWithColorMatch[0].color];

  const [selectedColors, setSelectedColors] = useState<string[]>(productSelectedColors);
  const nbSelectedColors = selectedColors.length;

  const configuredProduct = {
    product: product,
    colors: selectedColors.map(colorName => {
      return {
        color: colorName,
        friendlyColor: '',
        hexColor1: '',
        hexColor2: '',
        images: [],
      } as IProductColorImages
    })
  }


  const initialVariant = VariantForColor(product, selectedColors.slice(-1)[0]) || variantsWithColorMatch[0]
  // console.log("%cInitial variant is " + initialVariant.id + " " + initialVariant.friendlyColor, 'color: #008080');

  const [displayedVariant, setDisplayedVariant] = useState<IVariantInfos>(initialVariant);

  const [variantImage, setVariantImage] = useState<string>(initialVariant.images[0]);
  // const [hoverImage, setHoverImage] = useState<string>('');

  const [hoverVariant, setHoverVariant] = useState<IVariantInfos | null>(null);


  const [addOtherVersions, setAddOtherVersions] = useState<boolean>(true);

  const dispatch = useDispatch<AppDispatch>();

  // useEffect(() => {
  //   console.log("%cUse effect on product details product " + product.title, 'color: #800080');
  //   setSelectedColors(productSelectedColors);
  //   setDisplayedVariant(initialVariant);
  //   setVariantImage(initialVariant.images[0]);
  //   setAddOtherVersions(true);
  // }, [product]);


  useEffect(() => {
    // console.log("%cUse effect on displayedVariant " + displayedVariant.id + " " + displayedVariant.friendlyColor, 'color: #808080');
    setVariantImage(displayedVariant.images[0]);
  }, [displayedVariant]);

  useEffect(() => {
    let newDisplayedVariant = VariantForColor(product, selectedColors.slice(-1)[0])
    if (newDisplayedVariant !== undefined) {
      setDisplayedVariant(newDisplayedVariant);
    }
  }, [selectedColors]);

  const closeDialog = () => {
    dispatch(closeProduct())
  }

  const selectColor = (variant: IVariantInfos) => {

    if (productIsObsolete || saving) return;

    let newSelectedColors = [...selectedColors];

    // let's check if the requested color is already in the list
    const colorAlreadySelected = selectedColors.findIndex(color => color === variant.color) !== -1;

    // if we already have the max number of colors, we remove the oldest one (first in the array)

    // 2024-09-28: We allow to remove colors even if they are in the boutiques
    // WHICH IS NOT IN THE LIST OF COLORS IN THE BOUTIQUES !!!
    if (newSelectedColors.length >= MAX_COLORS && !colorAlreadySelected) {
      // let's find the first element of the array that is not in the list of colors in the boutiques
      // 2024-09-28: We allow to remove colors even if they are in the boutiques
      // const colorToRemoveIndex = newSelectedColors.findIndex(color => !colorInCollections.includes(color));

      // if we found one, we remove it
      // if (colorToRemoveIndex !== -1) {
      //   newSelectedColors.splice(colorToRemoveIndex, 1);
      // }
      const colorToRemoveIndex = 0;

      newSelectedColors.splice(colorToRemoveIndex, 1);
    }

    // if the color was already selected, we remove it from the list because we will add it again at the end
    if (colorAlreadySelected) {
      newSelectedColors.splice(newSelectedColors.indexOf(variant.color), 1);
    }

    // if we have space for a new color, we add it
    if (newSelectedColors.length < MAX_COLORS) {
      // add the new color at the end of the list
      newSelectedColors.push(variant.color);
    }

    setSelectedColors(newSelectedColors);
  }

  const unselectColor = (variant: IVariantInfos) => {

    if (productIsObsolete || saving) return;

    let newSelectedColors = [...selectedColors];
    newSelectedColors.splice(newSelectedColors.indexOf(variant.color), 1);
    if (newSelectedColors.length === 0) {
      newSelectedColors.push(variantsWithColorMatch[0].color);
    }
    setSelectedColors(newSelectedColors);
  }

  const variantColorTag = (variant:IVariantInfos) => {
    return(
      <div key={`var-colo-${variant.id}`} className='variant-color' style={{opacity: productIsObsolete ? 0.2 : 1}}
           onMouseEnter={() => setHoverVariant(variant)}
           onMouseLeave={() => setHoverVariant(null)}
           onClick={() => {selectColor(variant)}}
           >
        <ColorTag active={variant === displayedVariant} color_range={variant.color1}
                  color1={variant.hexColor1} color2={variant.hexColor2}
                  size='big' name={variant.friendlyColor} />
      </div>
    );
  }

  const productSizes = (sizes: string[]) => {

    if (sizes.length === 0 || sizes.length === 1) {
      return(
        <div className='sizes'>
          <FormattedMessage id="size.uniq" />
        </div>
      )
    } else {
      return(
        <>
          <div className='sizes'>
            <FormattedMessage id="size.available" />:
          </div>
          <div className='sizes-list'>
            {sizes.map(size =><div key={`var-size-${size}`}>{size}</div>)}
          </div>
        </>
      );
    }
  }

  const selectedColorTag = (color: string, i: number, size: string) => {

    const variant = VariantForColor(product, color);

    if (variant === undefined) {
      return(
        <div className='variant-color'>
          <ColorTag color1='' color2='' size='big' />
        </div>
      )
    } else {

      // If the color is presented in the boutiques, we display the information and make it non removeable
      const colorIsInCollections = colorInCollections.includes(color);

      // 2024-09-28: We allow to remove colors even if they are in the boutiques
      // const removeable = (selectedColors.length > 1) && !colorIsInCollections;
      const removeable = (selectedColors.length > 1);

      const name = colorIsInCollections ?
          (intl.formatMessage(
              {id: "selection.color-in-boutique"},
              {collections: listOfBoutiquesOccurencesForColor(color)}) + '\n\n' + variant.friendlyColor) :
          variant.friendlyColor;

      return(
        <div key={`sel-${variant.id}`} className='variant-color' style={{opacity: productIsObsolete ? 0.2 : 1}}
             onMouseEnter={() => setHoverVariant(variant)}
             onMouseLeave={() => setHoverVariant(null)}
             onClick={() => {
               if (removeable && !saving) unselectColor(variant)
             }} >
          <ColorTag color_range={variant.color1} color1={variant.hexColor1} color2={variant.hexColor2}
                    size={size} removeable={removeable} name={name} />
        </div>
      );
    }
  }

  const colorSelection = (size: string) => {
    return (

      // Version which displays empty color tags if no color is selected
      // <>
      //   {[...Array(MAX_COLORS)].map((_,i) => selectedColorTag(selectedColors[MAX_COLORS - i - 1], i))}
      // </>

      // Only dislaying selected colors to avoid enticing to select more colors
      <>
        {[...Array(nbSelectedColors)].map((_,i) => selectedColorTag(selectedColors[nbSelectedColors - i - 1], i, size))}
      </>

    );
  }

  const sameSelectedProducts = (p1:ISelectedProduct, p2:ISelectedProduct) => {
    return(p1.product?.id === p2.product?.id &&
      p1.colors.map(colorImages => colorImages.color).join('') === p2.colors.map(colorImages => colorImages.color).join(''));
  }

  async function dispatchAndCloseDialog(action: any) {
    const response = await dispatch(action) as any;
    closeDialog();


    let successMessage = '';
    let warning = false;

    if (response.type === 'selection/addProduct/fulfilled') {
      if (response.payload.addedOtherColors) {
        successMessage = intl.formatMessage({id: "selection.added-with-other-colors"})
        warning = true
      } else {
        successMessage = intl.formatMessage({id: "selection.added"})
      }
    }

    if (response.type === 'selection/removeProduct/fulfilled') {
      successMessage = intl.formatMessage({id: "selection.removed"})
    }

    if (successMessage !== '') {
      dispatch(openSnackBar({severity: warning ? "warning" : "success", message: successMessage, noAutoClose: warning}));
    }
  }

  const selectionActions = () => {

    if (productIsSelected) {
      if (sameSelectedProducts(productSelected, configuredProduct)) {
        return(['remove'])
      } else {
        return(['remove', 'update', 'cancel']);
      }
    } else {
      return(['add']);
    }
  }

  const actionOnSelection = (action:string, payload: ISelectedProductPayload) => {
    switch (action) {
      case 'add':
      case 'update':
        if (!getAccessToken()) {
          dispatch(openSpeedySignup(payload));
        } else {
          dispatchAndCloseDialog(addProduct(payload));
        }
        break;

      case 'remove':
        dispatchAndCloseDialog(removeProduct(payload));
        break;

      case 'cancel':
        closeDialog();
        break;
    }
  }

  const actionColor = (action:string) => {
    switch (action) {
      case 'add':
        return('success');

      case 'update':
        return('warning');

      case 'remove':
        return('error');

      default:
        return('primary');
    }
  }

  const addOrRemoveSelection = () => {

    const actions = selectionActions();

    return(
      <>
      {actions.map(action => {
        if (action === 'cancel') {
          return(<CustomButton onClick={closeDialog}><FormattedMessage id="global.cancel" /></CustomButton>)
        } else {
          if (action === 'remove' && productBoutiquesAndCollections.length > 0) {
            return(
              <BoudaToolTip key={`remove-${product.id}`} placement="top" sx={{textAlign: 'center'}}
                            title={intl.formatMessage(
                                {id: "selection.cannot-remove"},
                                {collections: listOfBoutiquesOccurences}
                            )} arrow>
                <span>
                  <Button key={action} disabled={true} variant="contained" color={actionColor(action)} >
                    <FormattedMessage id={`selection.${action}`} values={{count: selectedColors.length}}/>
                  </Button>
                </span>
              </BoudaToolTip>
            )
          } else  {
            return(
              <Button key={action} disabled={saving} variant="contained" color={actionColor(action)}
                      onClick={() => actionOnSelection(action, {
                        id: configuredProduct.product.id,
                        colors: configuredProduct.colors,
                        otherVersions: addOtherVersions
                      })}>
                <FormattedMessage id={`selection.${action}`} values={{count: selectedColors.length}}/><br/>
                {saving && <CircularProgress color="inherit" sx={{marginLeft: '10px'}} size={20}/>}
              </Button>
            )
          }
        }
      })}
      </>
    )
  }

  const otherVersionButton = (product: IProduct) => {
    if (product.gender !== null && product.gender > -1) {
      return(
        <BoudaToolTip key={`oth-ver-${product.id}`} placement="top" title={product.title} arrow>
          <div className='other-version'>
            <FontAwesomeIcon icon={GenderDisplayHash[product.gender]} size='1x' className='adult gender'
                             onClick={(e) => {
                               if (!saving) {
                                 e.stopPropagation();
                                 dispatch(openProduct(product));
                               }
                             }} />
          </div>
        </BoudaToolTip>
      )
    }
    return('')
  }

  const handleOtherVersions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddOtherVersions(event.target.checked);
  }

  const proposeOtherVersions = () => {

    if (connectedProducts.length === 0) return '';

    return(
      <div className='other-versions'>
        <FormControlLabel sx={{marginRight: '5px'}}
          label={<FormattedMessage id="selection.other-versions" values={{action: selectionActions()}} />}
          control={<Checkbox sx={{'&.MuiCheckbox-root': {color: '#808080'}}} size='medium'
                             checked={addOtherVersions} onChange={handleOtherVersions} />}
        />
        {connectedProducts.map(connectedProduct => otherVersionButton(connectedProduct))}
      </div>
    )
  }

  const variantForImages = hoverVariant || displayedVariant;

  // console.log("Render product details with  " + JSON.stringify(productBoutiquesAndCollections));
  // console.log("Colors " + JSON.stringify(colorInCollections));
  // console.log("Boutique occurences " + JSON.stringify(listOfBoutiquesOccurences));

  return(
    <Dialog fullWidth={false} maxWidth={'md'} open={true} onClose={closeDialog} className='product-details'>
      <DialogTitle>
        <span className='product-title'>{product.title}</span>
        {productIsSelected && <div className='product-color-selection'>
          {colorSelection('large') }
        </div>}
        <span className='price-tag'>{ProductPricesString(product)}</span>
        <IconButton aria-label="close" onClick={closeDialog} sx={{position: 'absolute', right: 8, top: 8, color: 'inherit'}}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div className='variant-main-img'>
              <img src={hoverVariant ? hoverVariant.images[0] : variantImage} />
            </div>
            <div className='variant-color-name'>
              {variantForImages.friendlyColor}
            </div>
            <div className='variant-images'>
              {
                variantForImages.images.map((image, index) => {
                  return(
                    <div key={`var-img-${index}`} onMouseEnter={() => setVariantImage(image)} className='variant-image'>
                      <img src={image} />
                    </div>
                  );
                })
              }
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className='brand'>
              <FormattedMessage id="product.brand" />: <Typography component="span" sx={{fontWeight:300}}>
              {product.brand.toUpperCase()}
            </Typography>
            </div>
            <div className='sku'>
              <FormattedMessage id="product.ref" />: <Typography component="span" sx={{fontWeight:300}}>
              {product.sku.toUpperCase()}
            </Typography>
            </div>
            <div className='composition'>
              <FormattedMessage id="product.composition" />: <Typography component="span" sx={{fontWeight:300}}>
              {product.fabric}
            </Typography>
            </div>
            {product.grammage && <div className='composition'>
              <FormattedMessage id="product.grammage" />: <Typography component="span" sx={{fontWeight:300}}>
              {product.grammage}
            </Typography>
            </div>}
            <div style={{fontWeight: 300}} dangerouslySetInnerHTML={{__html: product.description}}></div>
            {productSizes(displayedVariant.sizes)}
            <div className='main-colors'>
              <FormattedMessage id="color.close" />:
            </div>
            <div className='variant-colors'>
              {closeColors.length == 0 ? <div><FormattedMessage id="global.none_f" /></div> : closeColors.map(variantColorTag)}
            </div>
            <div className='other-colors'>
              <FormattedMessage id="color.others" />:
            </div>
            <div className='variant-colors'>
              {otherColors.length == 0 ? <div><FormattedMessage id="global.none_f" /></div> : otherColors.map(variantColorTag)}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className='product-actions'>
          {proposeOtherVersions()}
          <div className='product-buttons'>
            <div>
              <FormattedMessage id="selection.selected-colors" values={{count: nbSelectedColors}} />
            </div>
            <div className='color-selection'>
              {colorSelection('large') }
            </div>
            {addOrRemoveSelection()}
          </div>
        </div>
      </DialogActions>
    </Dialog>
  )
}
