import React, {useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useAppSelector} from "../../app/hooks";
import {Statuses} from "../../app/catalog";
import { CatalogStateSelector } from "./catalogSlice";
import {AppDispatch, RootState} from "../../app/store";
import SearchBar from "./SearchBar";
import {ProductCard} from "./ProductCard";
import Categories from "./Categories";
import {Grid, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import {ProductSelection} from "../selection/ProductSelection";
import {SelectedProducts, UniqueProducts, UniqueProductsCount} from "../selection/selectionSlice";
import {useParams} from "react-router-dom";
import {openDemoBoutique, updateBoutiques} from "../dashboard/boutiqueSlice";
import {confirmEmail} from "../sessions/sessionSlice";

function Catalog() {

  const dispatch = useDispatch<AppDispatch>();

  const catalogState = useAppSelector(CatalogStateSelector);
  const selectedProducts = useAppSelector(SelectedProducts);
  const nbUniqueProducts = useAppSelector(UniqueProductsCount);
  const currentCategory = catalogState.currentCategory || 'all';
  let products = catalogState.filteredProducts;

  // console.log("Render Catalog with currentCategory: ", catalogState.currentCategory);
  if (catalogState.currentCategory !== 'all') {
    products = products.filter((p) => p.category === catalogState.currentCategory)
  }

  const currentUser = useSelector((state : RootState) => state.session.currentUser);


  const allProducts = () => {

    let categoryStatus:Statuses = Statuses.UpToDate;

    if (currentCategory !== '') {
      const categoryInfos = catalogState.categories
        .find(categoryState => categoryState.category === currentCategory)
      if (categoryInfos !== undefined) {
        categoryStatus = categoryInfos.status;
      }
    }

    if (categoryStatus !== Statuses.UpToDate) {
      return(<div>Chargement du catalogue...</div>);
    } else {
      return (
        products && products.length > 0 && products.map((product, index) =>
          <ProductCard key={`${product.id}-${index}`} product={product} user={currentUser}
                       selected={selectedProducts?.filter((sp) => sp.product)
                         .findIndex((sp) => sp.product?.id === product.id) !== -1}  />
        )
      )
    }
  }


  ///////////////////////////////////////////////////////
  // Open a demo boutique with a demo token (from a sent email)
  ///////////////////////////////////////////////////////
  const {demo_token} = useParams();

  useEffect(() => {
    if (demo_token !== undefined) {
      dispatch(openDemoBoutique({open: true, loading: true}));
      handleConfirm(demo_token);
    }
  }, [demo_token])

  async function handleConfirm(demo_token: string) {

    const response = await dispatch(confirmEmail({token: demo_token, demo: true})) as any;

    if (response.type === 'session/confirmEmail/fulfilled') {
      if (response.payload.requestedBoutique) {
        dispatch(updateBoutiques([response.payload.requestedBoutique]));
      }
      // console.log("%cDemo boutique found with start date: " + response.payload.requestedBoutique?.buildingAt, 'color: purple');
      dispatch(openDemoBoutique({open: true, loading: false}));
    }
  }

  const productBoxHeight = Math.max(932, 60 + (nbUniqueProducts * 60))

  return (
    <Box className='catalog'>
      <Box sx={{ display: 'flex', p: 1, borderRadius: 1}} >
        <Categories catalog={catalogState} />
        <Box sx={{display: 'flex', flexDirection: 'column',  p: 1, pr:0, mr: 0}}>
          <SearchBar />
          <Box sx={{ display: 'flex', flexGrow: 1, p: 1, pr:0, borderRadius: 1}} >
            {/*<Grid container spacing={2} style={{marginTop: 0, height: 'calc(100vh)', overflowY: 'auto' }}>*/}
            <Grid container spacing={2} style={{marginTop: 0, height: `${productBoxHeight}px`, overflowY: 'auto' }}>
              {allProducts()}
            </Grid>
            <Box sx={{p: 1, pr: 0, m: 0, mt: -3}}>
              <ProductSelection title={true} catalogView={true} draggable={false} nbProducts={nbUniqueProducts} />
            </Box>

          </Box>
        </Box>
      </Box>
    </Box>
  )

}

export default Catalog;