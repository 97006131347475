import axios from "./axios";
import {IProductDefinition} from "./catalog";
import {CLIENT_ID, CLIENT_SECRET} from "./global";
import {
  IProdExportParameters, ISetDpdDelivery,
  IUpdateDeliveryOrder,
  IUpdateMarkingDone,
  IUpdateProductionDelivery,
  IUpdateProductionStatus
} from "../features/admin/production/productionSlice";

const LOGS_URL = "/logs";

export async function requestLogs() {
  return axios
      .get(LOGS_URL)
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        return error.response.data;
      });
}
